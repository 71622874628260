<nav class="subnav">
  <h2>
    <span class="icon-wrap"
      ><fa-icon [icon]="['fas', 'comment']"></fa-icon
    ></span>
    FORUM
  </h2>
</nav>
<nav class="subnav subnav-tabbed subnav-tabbed-chat">
  <a
    [routerLink]="['/chat']"
    [queryParams]="{
      category: '',
      page: 1,
      yourTopics: 0,
      yourReplies: 0
    }"
  >
    ALL TOPICS
  </a>
  <a
    [routerLink]="['/chat']"
    [queryParams]="{
      yourTopics: 1,
      category: '',
      page: 1,
      yourReplies: 0
    }"
  >
    YOUR TOPICS
  </a>
  <a
    [routerLink]="['/chat']"
    [queryParams]="{
      yourReplies: 1,
      category: '',
      page: 1,
      yourTopics: 0
    }"
  >
    YOUR REPLIES
  </a>
  <a [routerLink]="['/chat', 'forum', 'search']" class="active">
    SEARCH
  </a>
</nav>
<div *ngIf="isFetching">
  <app-loading-spinner [inline]="1"></app-loading-spinner>
</div>
<div *ngIf="!isFetching">
  <div class="container container-pad">
    <form
      class="bs-form tracker-form"
      name="search"
      method="post"
      (ngSubmit)="onSubmitForumSearch()"
      #forumSearchForm="ngForm"
    >
      <div class="input-group">
        <input
          name="query"
          type="text"
          class="form-control"
          placeholder="Search...."
          [ngModel]="query"
          #forumSearchQuery="ngModel"
        />
        <div class="input-group-btn">
          <button type="submit" class="btn btn-primary">GO</button>
        </div>
      </div>
    </form>
    <h4 class="mt-3" *ngIf="results && query">
      {{ results.length }} result<span *ngIf="results.length != 1">s</span> for
      {{ query }}...
    </h4>

    <hr class="divider" />
    <ul class="chat">
      <li
        *ngFor="let comment of results"
        class="viewed"
        id="comment-{{ comment.comment_id }}"
        (click)="visitTopic(comment)"
      >
        <span
          class="icon-wrap float-right text-primary"
          style="font-size: 1.5rem"
          ><fa-icon [icon]="['fas', 'arrow-circle-right']"></fa-icon
        ></span>
        <span class="avatar"
          ><img
            [src]="
              (comment.avatar !== null &&
                environment.assetUrl + '/assets/user/' + comment.avatar) ||
              'assets/img/default-avatar.png'
            "
            class="img-circle img-avatar"
            width="30"
            height="30"
          />
        </span>
        <div class="comment">
          <strong> {{ comment.screen_name }}</strong
          >&nbsp;
          <span
            *ngIf="comment.comment"
            [innerHtml]="comment.comment | linky"
          ></span>
        </div>
        <span class="comment-date">
          <span
            timeago
            [date]="comment.created | dateFormatter: ''"
            [live]="true"
          ></span>
        </span>
      </li>
    </ul>
  </div>
</div>
