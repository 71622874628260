<div *ngIf="isFetching">
  <app-loading-spinner [inline]="1"></app-loading-spinner>
</div>
<div *ngIf="!isFetching && currentUser">
  <nav class="subnav">
    <h2>
      <span class="icon-wrap"
        ><fa-icon [icon]="['fas', 'shoe-prints']"></fa-icon
      ></span>
      MY STEPS
    </h2>

    <div *ngIf="currentProgramme && currentUser">
      <a
        *ngIf="currentProgramme.config.buddies == 1 && currentUser.buddy"
        [routerLink]="['/friends-and-family']"
        aria-label="Go to buddies"
        class="subnav-next"
        ><span class="icon-wrap"
          ><fa-icon [icon]="['fas', 'chevron-circle-right']"></fa-icon></span
      ></a>

      <a
        *ngIf="currentProgramme.config.buddies != 1 || !currentUser.buddy"
        [routerLink]="['/challenges']"
        aria-label="Challenges"
        class="subnav-next"
        ><span class="icon-wrap"
          ><fa-icon [icon]="['fas', 'chevron-circle-right']"></fa-icon></span
      ></a>

    </div>
  </nav>
  <!-- algorithm setup -->

  <!-- -->
  <div class="masthead masthead-accent masthead-pad-top masthead-strength" *ngIf="currentActivity">
    <div *ngIf="+currentTarget > currentActivity.steps">
      <div class="masthead-figure masthead-figure-xb">
        <div class="masthead-icon">
          <img
            src="./assets/img/icon-shoe.png"
            width="81"
            height="55"
            class="icon-shoe"
          />
        </div>
        {{ currentTarget - currentActivity.steps | number }}
      </div>
      <div class="masthead-strap">STEPS REMAINING TODAY</div>
      <div class="progress">
        <div
          class="progress-bar progress-bar-accent"
          role="progressbar"
          [attr.aria-valuenow]="
            (currentActivity.steps / currentTarget) * 100 | number
          "
          aria-valuemin="0"
          aria-valuemax="100"
          style="width: {{
            (currentActivity.steps / currentTarget) * 100 | number
          }}%;"
        >
          <span class="sr-only"
            >{{ (currentActivity.steps / currentTarget) * 100 | number }}%
            Complete</span
          >
        </div>
      </div>
      <p class="masthead-subtext">
        <strong>{{ currentActivity.steps | number }}</strong> of
        <strong>{{ currentTarget | number }}</strong> daily steps target
        completed
      </p>

      <!-- cadence note-->
      <div *ngIf="currentUser.cadence">
        <hr>
        <div class="col-md-6 offset-md-3">
        <div *ngIf="+currentUser.cadence < 100">
          <p *ngIf="+currentTarget < 5000" class="masthead-subtext masthead-subtext">
            Make sure some steps are undertaken at a brisk pace. Work towards
            achieving at least 500 of your daily steps over a 5-minute brisk walk
          </p>
          <p *ngIf="+currentTarget >= 5000 && +currentTarget < 7500" class="masthead-subtext">
            Make sure some steps are undertaken at a brisk pace. Undertake at
            least 500 of your daily steps over a 5- minute brisk walk, working
            towards achieving at least 1000 steps over at least a 10-minute brisk
            walk
          </p>
          <p *ngIf="+currentTarget >= 7500" class="masthead-subtext">
            Make sure some steps are undertaken at a brisk pace. Work towards
            undertaking at least 1000 steps over at least a 10-minute brisk walk
          </p>
        </div>
  
        <div *ngIf="+currentUser.cadence >= 100">
          <p *ngIf="+currentTarget < 5000" class="masthead-subtext">
            Make sure some steps are undertaken at a brisk pace. Undertake at
            least 1000 of your daily steps over at least a 10-minute brisk walk
          </p>
          <p *ngIf="+currentTarget >= 5000 && +currentTarget < 7500" class="masthead-subtext">
            Make sure some steps are undertaken at a brisk pace. Undertake at
            least 1000 of your daily steps over at least a 10-minute brisk walk,
            working towards achieving at least 2000 steps over at least a
            20-minute brisk walk, or two 10-minute brisk walks
          </p>
          <p *ngIf="+currentTarget >= 7500" class="masthead-subtext">
            Make sure some steps are undertaken at a brisk pace. Undertake at
            least 2000 steps over at least a 20- minute brisk walk, or two
            10-minute brisk walks
          </p>
        </div>
      </div>
      </div>

    </div>
    <div *ngIf="currentTarget && currentActivity && +currentTarget <= currentActivity.steps">
      <div class="masthead-figure masthead-figure-xb">
        <span class="icon-wrap hit-goal"
          ><fa-icon [icon]="['fas', 'star']"></fa-icon></span
        >{{ currentActivity.steps | number
        }}<span class="masthead-strap"> STEPS</span>
      </div>
      <div class="masthead-strap">CONGRATULATIONS!</div>
      <p class="masthead-subtext">
        You have hit your <strong>{{ currentTarget | number }}</strong> daily
        steps target
      </p>
      
    </div>

    <div *ngIf="!currentTarget && currentProgramme.config.exerciseProgramme !=1">

      <div class="masthead-strap">PLEASE SET A TARGET</div>
      <br /><br />

      <a role="button" [routerLink]="['/target']" class="btn btn-primary">
        <span class="icon-wrap"
          ><fa-icon [icon]="['fas', 'bullseye']"></fa-icon
        ></span>
        SET TARGET</a
      >
      <br /><br />
    </div>


    <!-- Algorithm setup for EP programmes -->
    <div *ngIf="!currentTarget && currentProgramme.config.exerciseProgramme ==1">
      
      <div class="headline-flex">
        <div class="headline-icon">
          <span class="icon-wrap"
            ><fa-icon
              [icon]="['far', 'hourglass-half']"
            ></fa-icon
          ></span>
        </div>
        <div class="headline-text">
          <strong>{{ algorithmDaysRemaining }}</strong>
          <span class="headline-subtext"
            >day<span *ngIf="algorithmDaysRemaining != 1"
              >s</span
            ><br />
            Remaining</span
          >
        </div>
      </div>

      <div class="days days-activity">
        <div
          *ngFor="let day of algorithmDays"
          class="day "
        >
          <span
            class="icon-wrap fa fa-check-circle "
            *ngIf="day.status"
            ><fa-icon
              [icon]="['fas', 'check-circle']"
            ></fa-icon
          ></span>
          <span
            class="icon-wrap fa fa-circle "
            *ngIf="!day.status"
            [ngClass]="{ 'not-hit': day.status === 0 }"
            ><fa-icon
              [icon]="['fas', 'circle']"
            ></fa-icon></span
          ><br />
          {{ day.day | dateFormatter: "ddd" | uppercase }}
        </div>
      </div>

      <div *ngIf="algorithmDaysRemaining > 0">
        <p>
          3 days of activity is required before your target is generated
        </p>
      </div>

      <div *ngIf="algorithmDaysRemaining < 1">
        <p>Your personalised walking goal is now ready</p>
        <a
          role="button"
          [routerLink]="['/target-ep']"
          class="btn btn-primary"
        >
          <span class="icon-wrap"
            ><fa-icon [icon]="['fas', 'bullseye']"></fa-icon
          ></span>
          GENERATE TARGET</a
        >
        <!-- can they also set cadence? -->
        <a
          *ngIf="!currentUser.cadence"
          role="button"
          (click)="activitySetupStep = 3"
          class="btn btn-primary ml-1"
        >
          <span class="icon-wrap"
            ><fa-icon [icon]="['fas', 'bullseye']"></fa-icon
          ></span>
          SET WALKING SPEED</a
        >
      </div>
      
    </div>
    <!-- end Algorithm setup for EP programmes -->


  </div>

  <div class="toggle-container" *ngIf="currentUser.manual_steps">
    <div
      class="toggle-header toggle-header-left"
      (click)="toggleRow.update = !toggleRow.update"
    >
      <a
        href=""
        (click)="$event.preventDefault()"
        class="toggle-nav toggle-nav-expand"
      >
        <span class="icon-wrap" *ngIf="!toggleRow.update" aria-label="Down"
          ><fa-icon [icon]="['fas', 'chevron-circle-down']"></fa-icon
        ></span>
        <span class="icon-wrap" *ngIf="toggleRow.update" aria-label="Up"
          ><fa-icon [icon]="['fas', 'chevron-circle-up']"></fa-icon
        ></span>
      </a>

      <div class="toggle-header-title">UPDATE MY STEP COUNT</div>
    </div>
    <div class="toggle-content" *ngIf="toggleRow.update">
      <form
        name="stepsForm"
        class="bs-form tracker-form"
        (ngSubmit)="onSubmitStepsForm()"
        #stepsForm="ngForm"
      >
        <p class="instruction">
          You can update your step count for today or any previous days using
          the form below.
        </p>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="weight">STEP COUNT</label>
              <input
                type="number"
                name="steps"
                style="font-size: 28px"
                class="form-control input-tracker input-tracker-sm"
                placeholder=""
                id="steps"
                ng-model="currentSteps"
                min="0"
                max="1000000000"
                autocomplete="off"
                ng-required=""
                [(ngModel)]="currentSteps"
                #steps
              />
              <!--div class="alert alert-danger" *ngIf="stepsForm.steps.$touched && stepsForm.steps.$invalid">This must be a number below 1000000000.</div-->
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="number">DATE</label>
              <!--input (click)="open1()" type="text" style="font-size:28px;" class="form-control input-tracker input-tracker-sm" uib-datepicker-popup="{{format}}" ng-model="date" is-open="popup1.opened" datepicker-options="dateOptions" ng-required="true" close-text="Close" alt-input-formats="altInputFormats" /-->
              <input
                name="date"
                type="text"
                placeholder="DD/MM/YYYY"
                class="form-control input-tracker input-tracker-sm"
                bsDatepicker
                autocomplete="off"
                [bsConfig]="{
                  dateInputFormat: 'DD/MM/YYYY',
                  containerClass: 'theme-cv',
                  showWeekNumbers: false
                }"
                [(ngModel)]="date"
                required
                [bsValue]="date"
                [maxDate]="date"
                [minDate]="minDate"
                #dateInput="ngModel"
              />
            </div>
          </div>
        </div>
        <div class="tracker-submit-container">
          <button class="btn btn-primary btn-lg btn-white">UPDATE STEPS</button>
        </div>
      </form>
    </div>
  </div>

  <div class="toggle-container">
    <div class="toggle-header toggle-header-sub">
      <a
        href=""
        *ngIf="previousWeek"
        (click)="$event.preventDefault(); previous()"
        class="toggle-nav toggle-nav-prev"
        ><span class="icon-wrap"
          ><fa-icon [icon]="['fas', 'chevron-circle-left']"></fa-icon></span
      ></a>
      <a
        href=""
        *ngIf="nextWeek.startDate < now"
        (click)="$event.preventDefault(); next()"
        class="toggle-nav toggle-nav-next"
        ><span class="icon-wrap"
          ><fa-icon [icon]="['fas', 'chevron-circle-right']"></fa-icon></span
      ></a>
      <div class="toggle-header-title">WEEKLY STEPS</div>
      <div class="toggle-header-subtitle">
        {{ currentWeek[0] | dateFormatter: "DD/MM/yyyy" }} -
        {{ currentWeek[6] | dateFormatter: "DD/MM/yyyy" }}
      </div>
    </div>
    <div class="toggle-content">
      <div class="container">
        <canvas *ngIf="weekData"
          class="mt-3 mb-3 steps-chart"
          baseChart
          [datasets]="barChartData"
          [labels]="barChartLabels"
          [options]="barChartOptions"
          [legend]="barChartLegend"
          [chartType]="barChartType"
          [colors]="barChartColors"
          height="200"
        >
        </canvas>

        <!--canvas id="line" class="chart chart-bar" chart-data="data" chart-labels="labels" chart-series="series" chart-options="options"
                        chart-dataset-override="datasetOverride" chart-click="onClick" height="200"  aria-label="Bar Chart Values {{data}}"></canvas-->

        <div class="step-total">
          <span class="total">{{ weekTotal | number }}</span> steps so far
          <div class="step-total-week">
            <div *ngIf="+currentTarget * 7 - +weekTotal > 0">
              <span
                ><strong>{{
                  currentTarget * 7 - weekTotal | number
                }}</strong></span
              >
              of <strong>{{ currentTarget * 7 | number }}</strong> weekly steps
              remaining
            </div>
            <div *ngIf="+currentTarget * 7 - +weekTotal < 1">
              You hit your
              <strong>{{ currentTarget * 7 | number }}</strong> weekly steps
              target
            </div>
            <button
              class="btn btn-sm btn-primary"
              (click)="syncSteps(1)"
              style="margin: 10px 0 0 0; font-size: 12px"
            >
              <span class="icon-wrap"
                ><fa-icon [icon]="['fas', 'sync']"></fa-icon
              ></span>
              SYNC STEPS
            </button>
          </div>
        </div>

        <table class="table" *ngIf="weekData">
          <thead>
            <tr>
              <th></th>
              <th style="text-align: left">DAY</th>
              <th style="text-align: right">STEP COUNT</th>
              <th style="text-align: right">% OF DAILY TARGET</th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let dayData of currentWeek; let index = index"
              [ngClass]="{ current: weekData[index].activity_date == now }"
            >
              <!--td><i *ngIf="weekData[$index].hit_goal!=1" class="fa fa-star-o"></i><i *ngIf="weekData[$index].hit_goal==1" class="fa fa-star hit-goal"></i></td-->
              <td>
                <span
                  class="icon-wrap"
                  *ngIf="(weekData[index].steps / currentTarget) * 100 < 100"
                  ><fa-icon [icon]="['far', 'star']"></fa-icon
                ></span>
                <span
                  class="icon-wrap"
                  *ngIf="(weekData[index].steps / currentTarget) * 100 >= 100"
                  ><fa-icon [icon]="['fas', 'star']"></fa-icon
                ></span>
              </td>

              <td>{{ dayData | dateFormatter: "dddd" }}</td>
              <td class="text-right">
                {{ weekData[index].steps | number: "1.0-0" }}
              </td>
              <td class="text-right" *ngIf="!currentUser.manual_steps">
                {{ weekData[index].percent_hit | number: "1.0-0"
                }}<span *ngIf="weekData[index].percent_hit">%</span>
              </td>
              <td class="text-right" *ngIf="currentUser.manual_steps">
                <span *ngIf="currentTarget > 0"
                  >{{
                    (weekData[index].steps / currentTarget) * 100
                      | number: "1.0-0"
                  }}<span *ngIf="weekData[index].steps">%</span></span
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <div class="toggle-container">
    <div
      class="toggle-header toggle-header-left"
      (click)="toggleRow.graph = !toggleRow.graph"
    >
      <a
        href=""
        (click)="$event.preventDefault()"
        class="toggle-nav toggle-nav-expand"
      >
        <span class="icon-wrap" *ngIf="!toggleRow.graph" aria-label="Down"
          ><fa-icon [icon]="['fas', 'chevron-circle-down']"></fa-icon
        ></span>
        <span class="icon-wrap" *ngIf="toggleRow.graph" aria-label="Up"
          ><fa-icon [icon]="['fas', 'chevron-circle-up']"></fa-icon
        ></span>
      </a>

      <div class="toggle-header-title">STEPS GRAPH</div>
    </div>
    <div class="toggle-content" *ngIf="toggleRow.graph">
      <!-- Nav tabs -->
      <ul class="nav nav-tabs nav-tabs-duo" role="tablist">
        <li role="presentation" ng-class="{ active: showMonth }">
          <a
            href=""
            role="tab"
            aria-controls="showMonth"
            data-toggle="tab"
            (click)="$event.preventDefault(); showMonth = true"
            >PAST 30 DAYS</a
          >
        </li>
        <li role="presentation" ng-class="{ active: !showMonth }">
          <a
            href=""
            role="tab"
            aria-controls="allTime"
            data-toggle="tab"
            (click)="$event.preventDefault(); showMonth = false"
            >ALL TIME</a
          >
        </li>
      </ul>

      <!-- Tab panes -->
      <div class="tab-content">
        <div
          role="tabpanel"
          ng-class="tab - pane"
          id="showMonth"
          *ngIf="showMonth"
        >
          <canvas
            class="mt-3 mb-3 tracker-chart"
            baseChart
            [datasets]="lineChartData"
            [labels]="lineChartLabels"
            [options]="lineChartOptions"
            [legend]="lineChartLegend"
            [chartType]="lineChartType"
            [colors]="lineChartColors"
          >
          </canvas>

          <!--canvas id="line2" class="chart chart-line tracker-chart" chart-data="monthData" chart-labels="monthLabels" chart-series="series" chart-options="monthOptions"
                        chart-dataset-override="monthDatasetOverride" height="200"  aria-label="Bar Chart Values {{monthData}}"></canvas-->
        </div>
        <div
          role="tabpanel"
          ng-class="tab - pane"
          id="allTime"
          *ngIf="!showMonth"
        >
          <canvas
            class="mt-3 mb-3 tracker-chart"
            baseChart
            [datasets]="allTimeChartData"
            [labels]="allTimeChartLabels"
            [options]="allTimeChartOptions"
            [legend]="allTimeChartLegend"
            [chartType]="allTimeChartType"
            [colors]="allTimeChartColors"
          >
          </canvas>

          <!--canvas id="line3" class="chart chart-line tracker-chart" chart-data="allTimeData" chart-labels="allTimeLabels" chart-series="series" chart-options="allTimeOptions"
                        chart-dataset-override="allTimeDatasetOverride" height="200"  aria-label="Bar Chart Values {{allTimeData}}"></canvas-->
        </div>
      </div>
    </div>
  </div>

  <div class="toggle-container" *ngIf="currentProgramme.config.exerciseProgramme == 1 " id="setWalkingSpeed">
    <div
      class="toggle-header toggle-header-left"
      (click)="toggleRow.cadence = !toggleRow.cadence"
    >
      <a
        href=""
        (click)="$event.preventDefault()"
        class="toggle-nav toggle-nav-expand"
      >
        <span class="icon-wrap" *ngIf="!toggleRow.cadence" aria-label="Down"
          ><fa-icon [icon]="['fas', 'chevron-circle-down']"></fa-icon
        ></span>
        <span class="icon-wrap" *ngIf="toggleRow.cadence" aria-label="Up"
          ><fa-icon [icon]="['fas', 'chevron-circle-up']"></fa-icon
        ></span>
      </a>

      <div class="toggle-header-title">WALKING SPEED</div>
    </div>
    <div class="toggle-content" *ngIf="toggleRow.cadence">
        <div class="row">
          <div class="col-md-6 offset-md-3">
            <p class="text-center">
              Please enter how many steps you walk in 1 minute
            </p>
            <form
              name="speedForm"
              class="bs-form tracker-form"
              (ngSubmit)="onSubmitCadenceForm()"
              #speedForm="ngForm"
            >
            <div class="form-group">
              <label for="weight">STEPS PER MINUTE</label>
              <div class="input-group input-group-fa">
                <span class="icon-wrap fa"
                  ><fa-icon
                    [icon]="['fas', 'shoe-prints']"
                  ></fa-icon
                ></span>
                <input
                  type="number"
                  name="cadence"
                  class="form-control input-tracker input-tracker-sm input-tracker-dashboard"
                  placeholder="0"
                  id="cadence"
                  [(ngModel)]="cadence"
                  #cadenceInputMob="ngModel"
                  min="0"
                  max="1000000000"
                  autocomplete="off"
                  ng-required=""
                  style="text-align: left"
                />
              </div>
              </div>
              <hr>
              <button
                role="button"
                href="#"
                class="btn btn-primary btn-lg btn-white"
              >
                SET WALKING SPEED
              </button>
            </form>
          </div>
        </div>
    </div>
  </div>
  <app-section-nav [section]="'activity'" [active]="'steps'"></app-section-nav>
</div>
