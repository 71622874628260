<ng-template #popTemplate>
  You have been placed into one of the following groups. This can change each
  week as it is based on your average number of steps from the previous week.<br /><br />&lt;2000
  steps<br />2000-5000 steps<br />5001-10000 steps<br />10001- 15000 steps<br />&gt;15000
  steps
</ng-template>

<nav class="subnav">
  <a [routerLink]="['/steps']" aria-label="Go to p" class="subnav-prev"
    ><span class="icon-wrap"
      ><fa-icon [icon]="['fas', 'chevron-circle-left']"></fa-icon></span
  ></a>
  <h2>
    <span class="icon-wrap"
      ><fa-icon [icon]="['fas', 'trophy']"></fa-icon
    ></span>
    CHALLENGES
  </h2>
  <div *ngIf="currentProgramme && currentUser">
    <div *ngIf="currentProgramme.config.trackers.indexOf('strength') > -1">
      <a
      
        [routerLink]="['/activity', 'strength']"
        aria-label="Go to strength training"
        class="subnav-next"
        ><span class="icon-wrap"
          ><fa-icon [icon]="['fas', 'chevron-circle-right']"></fa-icon></span
      ></a>
    </div>
    <div *ngIf="currentProgramme.config.trackers.indexOf('strength') === -1">
      <a
        *ngIf="currentProgramme.config.buddies == 1"
        [routerLink]="['/friends-and-family']"
        aria-label="Go to buddies"
        class="subnav-next"
        ><span class="icon-wrap"
          ><fa-icon [icon]="['fas', 'chevron-circle-right']"></fa-icon></span
      ></a>

      <a
        *ngIf="currentProgramme.config.buddies != 1"
        [routerLink]="['/target']"
        aria-label="Go to target"
        class="subnav-next"
        ><span class="icon-wrap"
          ><fa-icon [icon]="['fas', 'chevron-circle-right']"></fa-icon></span
      ></a>
    </div>
  </div>
</nav>
<div *ngIf="isFetching">
  <app-loading-spinner [inline]="1"></app-loading-spinner>
</div>

<div *ngIf="currentProgramme && currentUser">
  <div class="masthead masthead-accent" *ngIf="currentLeader">
    <div class="masthead-challenge">
      <img src="./assets/img/icon-flag.png" width="60" class="masthead-icon" />
      <div class="masthead-figure">
        CURRENT LEADER<span *ngIf="currentLeader.length > 1">S</span>
      </div>
      <div class="masthead-strap">{{ currentChallenge.title | uppercase }}</div>
      <div *ngFor="let leader of currentLeader" class="leader-container">
        <div class="masthead-leader">
          <img
            [src]="
              (leader.avatar !== null &&
                environment.assetUrl + '/assets/user/' + leader.avatar) ||
              './assets/img/default-avatar.png'
            "
            class="img-circle img-avatar"
            width="50"
            height="50"
          />
          <span class="leader-name">{{ leader.screen_name }}</span>
        </div>
      </div>
    </div>

    <p
      class="masthead-subtext"
      *ngIf="!iLead && myActivity && currentLeader[0]"
    >
      You are
      <strong>{{
        currentLeader[0].step_count - myActivity.step_count | number
      }}</strong>
      steps from top spot
    </p>
  </div>

  <div class="toggle-container">
    <div
      class="toggle-header toggle-header-left"
      (click)="toggleRow.monFri = !toggleRow.monFri"
    >
      <a
        href=""
        class="toggle-nav toggle-nav-expand"
        [attr.aria-expanded]="toggleRow.monFri"
        aria-label=" Monday - Friday challenge"
        (click)="$event.preventDefault()"
      >
        <span *ngIf="!toggleRow.monFri" class="icon-wrap"
          ><fa-icon [icon]="['fas', 'chevron-circle-down']"></fa-icon
        ></span>
        <span *ngIf="toggleRow.monFri" class="icon-wrap"
          ><fa-icon [icon]="['fas', 'chevron-circle-up']"></fa-icon
        ></span>
      </a>
      <div class="toggle-header-title">MONDAY - FRIDAY CHALLENGE</div>
    </div>
    <div class="toggle-content" *ngIf="toggleRow.monFri">
      <div *ngIf="tableMode == 'awards'">
        <!--div class="btn-group pull-right" uib-dropdown>
          <button
            id="single-button"
            type="button"
            class="btn btn-primary btn-filter"
            uib-dropdown-toggle
          >
            FILTER <span class="caret"></span>
          </button>
          <ul
            class="dropdown-menu"
            uib-dropdown-menu
            role="menu"
            aria-labelledby="single-button"
          >
            <li role="menuitem">
              <a (click)="setFilterBand(1, 'mon-fri')">Band 1</a>
            </li>
            <li role="menuitem">
              <a (click)="setFilterBand(2, 'mon-fri')">Band 2</a>
            </li>
            <li role="menuitem">
              <a (click)="setFilterBand(3, 'mon-fri')">Band 3</a>
            </li>
            <li role="menuitem">
              <a (click)="setFilterBand(4, 'mon-fri')">Band 4</a>
            </li>
            <li role="menuitem">
              <a (click)="setFilterBand(5, 'mon-fri')">Band 5</a>
            </li>
          </ul>
        </div-->
      </div>

      <div *ngIf="monfriChallenge.endDate < now">
        <p class="text-center">This challenge has finished</p>
      </div>
      <div *ngIf="tableMode != 'awards'">
        <ul
          class="nav nav-tabs nav-tabs-duo justify-content-center"
          role="tablist"
        >
          <li role="presentation" [ngClass]="{ active: !showPercent }">
            <a
              href="#"
              aria-controls="step-count"
              role="tab"
              data-toggle="tab"
              (click)="$event.preventDefault(); showPercent = false"
              >STEP COUNT</a
            >
          </li>
          <li role="presentation" [ngClass]="{ active: showPercent }">
            <a
              href="#"
              aria-controls="percent"
              role="tab"
              data-toggle="tab"
              (click)="$event.preventDefault(); showPercent = true"
              >% OF DAILY TARGET (AVERAGE)
            </a>
          </li>
        </ul>
      </div>
      <div class="tab-content">
        <div *ngIf="tableMode != 'awards'">
          <div role="tabpanel" id="percent" *ngIf="showPercent">
            <table class="table table-leaderboard">
              <thead>
                <tr>
                  <th *ngIf="monfriChallenge.endDate < now" colspan="5">
                    LEADERBOARD (<a
                      href="#"
                      class="info-link"
                      (click)="$event.preventDefault()"
                      [popover]="popTemplate"
                    >
                      <span [ngSwitch]="currentUser.step_band">
                        <span *ngSwitchCase="1">&lt;2,000 steps</span>
                        <span *ngSwitchCase="2">2,000 - 5,000 steps</span>
                        <span *ngSwitchCase="3"> 5,001 - 10,000 steps</span>
                        <span *ngSwitchCase="4"> 10,001 - 15,000 steps</span>
                        <span *ngSwitchCase="5">&gt; 15,000 steps</span>
                      </span>
                      )
                      <span class="icon-wrap"
                        ><fa-icon [icon]="['fas', 'info']"></fa-icon></span
                    ></a>
                  </th>
                  <th *ngIf="monfriChallenge.endDate >= now" colspan="4">
                    LEADERBOARD (<a
                      href="#"
                      class="info-link"
                      (click)="$event.preventDefault()"
                      [popover]="popTemplate"
                    >
                      <span [ngSwitch]="currentUser.step_band">
                        <span *ngSwitchCase="1">&lt;2,000 steps</span>
                        <span *ngSwitchCase="2">2,000 - 5,000 steps</span>
                        <span *ngSwitchCase="3"> 5,001 - 10,000 steps</span>
                        <span *ngSwitchCase="4"> 10,001 - 15,000 steps</span>
                        <span *ngSwitchCase="5">&gt; 15,000 steps</span>
                      </span>
                      )
                      <span class="icon-wrap"
                        ><fa-icon [icon]="['fas', 'info']"></fa-icon></span
                    ></a>
                  </th>
                </tr>
              </thead>
              <tbody>
                <ng-container
                  *ngFor="
                    let teamActivityItem of sortActivity(
                      monfriActivity,
                      'goal_avg',
                      true
                    );
                    let index = index
                  "
                >
                  <tr
                    [ngClass]="{
                      me: teamActivityItem.user_id == currentUser.user_id
                    }"
                    id="{{ teamActivityItem.last_name }}"
                    *ngIf="
                      teamActivityItem.step_count > 0 &&
                      (index < 10 ||
                        teamActivityItem.user_id == currentUser.user_id ||
                        monFriShowall == true)
                    "
                  >
                    <td *ngIf="monfriChallenge.endDate < now">
                      <span *ngIf="index == 0" class="medal"
                        ><img
                          src="./assets/img/icon-aw-medal-gold.png"
                          width="16"
                          alt="gold medal"
                      /></span>
                      <span
                        *ngIf="
                          index == 1 &&
                          sortActivity(monfriActivity, 'goal_avg', true)[
                            index - 1
                          ].goal_avg != teamActivityItem.goal_avg
                        "
                        class="medal"
                        ><img
                          src="./assets/img/icon-aw-medal-silver.png"
                          width="16"
                          alt="silver-medal"
                      /></span>
                      <span
                        *ngIf="
                          index == 2 &&
                          sortActivity(monfriActivity, 'goal_avg', true)[
                            index - 1
                          ].goal_avg != teamActivityItem.goal_avg
                        "
                        class="medal"
                        ><img
                          src="./assets/img/icon-aw-medal-bronze.png"
                          width="16"
                          alt="bronze medal"
                      /></span>
                    </td>
                    <td class="num">
                      <span
                        *ngIf="
                          index == 0 ||
                          sortActivity(monfriActivity, 'goal_avg', true)[
                            index - 1
                          ].goal_avg != teamActivityItem.goal_avg
                        "
                        >{{ index + 1 }}</span
                      >
                      <span
                        *ngIf="
                          index != 0 &&
                          sortActivity(monfriActivity, 'goal_avg', true)[
                            index - 1
                          ].goal_avg == teamActivityItem.goal_avg
                        "
                      >
                        {{
                          
                          getPos(
                            sortActivity(monfriActivity, "goal_avg", true),
                            teamActivityItem,
                            "goal_avg"
                          )
                        }}
                      </span>
                    </td>
                    <td class="avi">
                      <img
                        [src]="
                          (teamActivityItem.avatar !== null &&
                            environment.assetUrl +
                              '/assets/user/' +
                              teamActivityItem.avatar) ||
                          './assets/img/default-avatar.png'
                        "
                        class="img-circle img-avatar"
                        width="30"
                        height="30"
                      />
                    </td>
                    <td width="50%">{{ teamActivityItem.screen_name }}</td>
                    <td class="text-right">
                      {{ teamActivityItem.goal_avg | number }}%
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
          
          <div role="tabpanel" id="step-count" *ngIf="!showPercent">
            <table class="table table-leaderboard">
              <thead>
                <tr>
                  <th *ngIf="monfriChallenge.endDate < now" colspan="5">
                    LEADERBOARD (<a
                      href="#"
                      class="info-link"
                      (click)="$event.preventDefault()"
                      [popover]="popTemplate"
                    >
                      <span [ngSwitch]="currentUser.step_band">
                        <span *ngSwitchCase="1">&lt;2,000 steps</span>
                        <span *ngSwitchCase="2">2,000 - 5,000 steps</span>
                        <span *ngSwitchCase="3"> 5,001 - 10,000 steps</span>
                        <span *ngSwitchCase="4"> 10,001 - 15,000 steps</span>
                        <span *ngSwitchCase="5">&gt; 15,000 steps</span>
                      </span>
                      )
                      <span class="icon-wrap"
                        ><fa-icon [icon]="['fas', 'info']"></fa-icon></span
                    ></a>
                  </th>
                  <th *ngIf="monfriChallenge.endDate >= now" colspan="4">
                    LEADERBOARD (<a
                      href="#"
                      class="info-link"
                      (click)="$event.preventDefault()"
                      [popover]="popTemplate"
                    >
                      <span [ngSwitch]="currentUser.step_band">
                        <span *ngSwitchCase="1">&lt;2,000 steps</span>
                        <span *ngSwitchCase="2">2,000 - 5,000 steps</span>
                        <span *ngSwitchCase="3"> 5,001 - 10,000 steps</span>
                        <span *ngSwitchCase="4"> 10,001 - 15,000 steps</span>
                        <span *ngSwitchCase="5">&gt; 15,000 steps</span>
                      </span>
                      )
                      <span class="icon-wrap"
                        ><fa-icon [icon]="['fas', 'info']"></fa-icon></span
                    ></a>
                  </th>
                </tr>
              </thead>
              <tbody>
                <ng-container
                  *ngFor="
                    let teamActivityItem of sortActivity(
                      monfriActivity,
                      'step_count',
                      true
                    );
                    let index = index
                  "
                >
                  <tr
                    [ngClass]="{
                      me: teamActivityItem.user_id == currentUser.user_id
                    }"
                    id="{{ teamActivityItem.last_name }}"
                    *ngIf="
                      (index < 10 ||
                        teamActivityItem.user_id == currentUser.user_id ||
                        monFriShowall == true)
                    "
                  >
                    <td *ngIf="monfriChallenge.endDate < now">
                      <span *ngIf="index == 0" class="medal"
                        ><img
                          src="./assets/img/icon-aw-medal-gold.png"
                          width="16"
                      /></span>
                      <span
                        *ngIf="
                          index == 1 &&
                          sortActivity(monfriActivity, 'step_count', true)[
                            index - 1
                          ].step_count != teamActivityItem.step_count
                        "
                        class="medal"
                        ><img
                          src="./assets/img/icon-aw-medal-silver.png"
                          width="16"
                      /></span>
                      <span
                        *ngIf="
                          index == 2 &&
                          sortActivity(monfriActivity, 'step_count', true)[
                            index - 1
                          ].step_count != teamActivityItem.step_count
                        "
                        class="medal"
                        ><img
                          src="./assets/img/icon-aw-medal-bronze.png"
                          width="16"
                      /></span>
                    </td>
                    <td class="num">
                      <span
                        *ngIf="
                          index == 0 ||
                          sortActivity(monfriActivity, 'step_count', true)[
                            index - 1
                          ].step_count != teamActivityItem.step_count
                        "
                        >{{ index + 1 }}</span
                      >
                      <span
                        *ngIf="
                          index != 0 &&
                          sortActivity(monfriActivity, 'step_count', true)[
                            index - 1
                          ].step_count == teamActivityItem.step_count
                        "
                      >
                        {{
                          getPos(
                            sortActivity(monfriActivity, "step_count", true),
                            teamActivityItem,
                            "step_count"
                          )
                        }}
                      </span>
                    </td>
                    <td class="avi">
                      <img
                        [src]="
                          (teamActivityItem.avatar !== null &&
                            environment.assetUrl +
                              '/assets/user/' +
                              teamActivityItem.avatar) ||
                          './assets/img/default-avatar.png'
                        "
                        class="img-circle img-avatar"
                        width="30"
                        height="30"
                        alt="user avatar"
                      />
                    </td>
                    <td width="50%">{{ teamActivityItem.screen_name }}</td>
                    <td class="text-right">
                      {{ teamActivityItem.step_count | number }}
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
          <div class="text-center">
            <button
              *ngIf="
                sortActivity(monfriActivity, 'step_count', true).length > 10 &&
                !monFriShowall
              "
              class="btn btn-primary"
              (click)="monFriShowall = true"
            >
              Show all
            </button>
            <button
              *ngIf="
                sortActivity(monfriActivity, 'step_count', true).length > 10 &&
                monFriShowall
              "
              class="btn btn-primary"
              (click)="monFriShowall = false"
            >
              Show fewer
            </button>
          </div>
        </div>
        <div *ngIf="tableMode == 'awards'">
          <table class="table table-leaderboard">
            <thead>
              <tr>
                <th *ngIf="monfriChallenge.endDate < now" colspan="4">
                  RESULTS  <a
                  href="#"
                  class="info-link"
                  (click)="$event.preventDefault()"
                  [popover]="popTemplate"
                >(
                  <span [ngSwitch]="currentUser.step_band">
                    <span *ngSwitchCase="1">&lt;2,000 steps</span>
                    <span *ngSwitchCase="2">2,000 - 5,000 steps</span>
                    <span *ngSwitchCase="3"> 5,001 - 10,000 steps</span>
                    <span *ngSwitchCase="4"> 10,001 - 15,000 steps</span>
                    <span *ngSwitchCase="5">&gt; 15,000 steps</span>
                  </span>
                  )
                  <span class="icon-wrap"
                    ><fa-icon [icon]="['fas', 'info']"></fa-icon></span
                ></a>
                </th>
              </tr>
            </thead>
            <tbody>
              <ng-container
                *ngFor="let cResults of monfriActivity;let index=index;"
                [ngClass]="{ me: cResults.user_id == currentUser.user_id }"
              >
              <tr *ngIf="index < 10">
                <td width="32">
                  <span *ngIf="cResults.award_rank == 1" class="medal"
                    ><img
                      src="./assets/img/icon-aw-medal-gold.png"
                      width="16"
                      alt="gold medal"
                  /></span>
                  <span *ngIf="cResults.award_rank == 2" class="medal"
                    ><img
                      src="./assets/img/icon-aw-medal-silver.png"
                      width="16"
                      alt="silver-medal"
                  /></span>
                  <span *ngIf="cResults.award_rank == 3" class="medal"
                    ><img
                      src="./assets/img/icon-aw-medal-bronze.png"
                      width="16"
                      alt="bronze medal"
                  /></span>
                </td>
                <td class="avi" width="32">
                  <img
                    [src]="
                      (cResults.avatar !== null &&
                        environment.assetUrl +
                          '/assets/user/' +
                          cResults.avatar) ||
                      './assets/img/default-avatar.png'
                    "
                    class="img-circle img-avatar"
                    width="30"
                    height="30"
                  />
                </td>
                <td>{{ cResults.screen_name }}</td>
                <td>{{ cResults.step_count | number }}</td>
              </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
      <hr class="divider" />
      <div class="challenge-nav">
        <a
          href=""
          class="cn-prev"
          (click)="$event.preventDefault(); prevData('mon-fri')"
          ><span class="icon-wrap"
            ><fa-icon [icon]="['fas', 'chevron-circle-left']"></fa-icon></span
        ></a>
        {{ monfriChallenge.startDate | dateFormatter: "DD/MM/yyyy" }} -
        {{ monfriChallenge.endDate | dateFormatter: "DD/MM/yyyy" }}
        <a
          href=""
          class="cn-next"
          *ngIf="monfriChallenge.endDate < now"
          (click)="$event.preventDefault(); nextData('mon-fri')"
          ><span class="icon-wrap"
            ><fa-icon [icon]="['fas', 'chevron-circle-right']"></fa-icon></span
        ></a>
      </div>
    </div>
  </div>

  <div class="toggle-container">
    <div
      class="toggle-header toggle-header-left"
      (click)="toggleRow.weekend = !toggleRow.weekend"
    >
      <a
        href=""
        (click)="$event.preventDefault()"
        class="toggle-nav toggle-nav-expand"
        [attr.aria-expanded]="toggleRow.weekend"
        aria-label=" Weekend challenge"
      >
        <span *ngIf="!toggleRow.weekend" class="icon-wrap"
          ><fa-icon [icon]="['fas', 'chevron-circle-down']"></fa-icon
        ></span>
        <span *ngIf="toggleRow.weekend" class="icon-wrap"
          ><fa-icon [icon]="['fas', 'chevron-circle-up']"></fa-icon
        ></span>
      </a>
      <div class="toggle-header-title">WEEKEND CHALLENGE</div>
    </div>
    <div class="toggle-content" *ngIf="toggleRow.weekend">
      <div *ngIf="tableModeWe == 'awards'">
        <!--div class="btn-group pull-right" uib-dropdown>
          <button
            id="single-button"
            type="button"
            class="btn btn-primary btn-filter"
            uib-dropdown-toggle
          >
            FILTER <span class="caret"></span>
          </button>
          <ul
            class="dropdown-menu"
            uib-dropdown-menu
            role="menu"
            aria-labelledby="single-button"
          >
            <li role="menuitem">
              <a (click)="setFilterBand(1, 'weekend')">Band 1</a>
            </li>
            <li role="menuitem">
              <a (click)="setFilterBand(2, 'weekend')">Band 2</a>
            </li>
            <li role="menuitem">
              <a (click)="setFilterBand(3, 'weekend')">Band 3</a>
            </li>
            <li role="menuitem">
              <a (click)="setFilterBand(4, 'weekend')">Band 4</a>
            </li>
            <li role="menuitem">
              <a (click)="setFilterBand(5, 'weekend')">Band 5</a>
            </li>
          </ul>
        </div-->
      </div>
      <div *ngIf="weekendChallenge.endDate < now">
        <p class="text-center">This challenge has finished</p>
      </div>
      <div *ngIf="tableModeWe != 'awards'">
        <ul
          class="nav nav-tabs nav-tabs-duo justify-content-center"
          role="tablist"
        >
          <li role="presentation" [ngClass]="{ active: !showPercentWe }">
            <a
              href=""
              aria-controls="step-count"
              role="tab"
              data-toggle="tab"
              (click)="$event.preventDefault(); showPercentWe = false"
              >STEP COUNT</a
            >
          </li>
          <li role="presentation" [ngClass]="{ active: showPercentWe }">
            <a
              href=""
              aria-controls="percent"
              role="tab"
              data-toggle="tab"
              (click)="$event.preventDefault(); showPercentWe = true"
              >% OF DAILY TARGET (AVERAGE)
            </a>
          </li>
        </ul>
      </div>
      <div class="tab-content">
        <div *ngIf="tableModeWe != 'awards'">
          <div role="tabpanel" id="percent" *ngIf="showPercentWe">
            <table class="table table-leaderboard">
              <thead>
                <tr>
                  <th colspan="5" *ngIf="weekendChallenge.endDate < now">
                    LEADERBOARD (<a
                      href="#"
                      class="info-link"
                      (click)="$event.preventDefault()"
                      [popover]="popTemplate"
                    >
                      <span [ngSwitch]="currentUser.step_band">
                        <span *ngSwitchCase="1">&lt;2,000 steps</span>
                        <span *ngSwitchCase="2">2,000 - 5,000 steps</span>
                        <span *ngSwitchCase="3"> 5,001 - 10,000 steps</span>
                        <span *ngSwitchCase="4"> 10,001 - 15,000 steps</span>
                        <span *ngSwitchCase="5">&gt; 15,000 steps</span>
                      </span>
                      )
                      <span class="icon-wrap"
                        ><fa-icon [icon]="['fas', 'info']"></fa-icon></span
                    ></a>
                  </th>
                  <th colspan="4" *ngIf="weekendChallenge.endDate >= now">
                    LEADERBOARD (<a
                      href="#"
                      class="info-link"
                      (click)="$event.preventDefault()"
                      [popover]="popTemplate"
                    >
                      <span [ngSwitch]="currentUser.step_band">
                        <span *ngSwitchCase="1">&lt;2,000 steps</span>
                        <span *ngSwitchCase="2">2,000 - 5,000 steps</span>
                        <span *ngSwitchCase="3"> 5,001 - 10,000 steps</span>
                        <span *ngSwitchCase="4"> 10,001 - 15,000 steps</span>
                        <span *ngSwitchCase="5">&gt; 15,000 steps</span>
                      </span>
                      )
                      <span class="icon-wrap"
                        ><fa-icon [icon]="['fas', 'info']"></fa-icon></span
                    ></a>
                  </th>
                </tr>
              </thead>
              <tbody>
                <ng-container
                  *ngFor="
                    let teamActivityItem of sortActivity(
                      weekendActivity,
                      'goal_avg',
                      true
                    );
                    let index = index
                  "
                >
                  <tr
                    [ngClass]="{
                      me: teamActivityItem.user_id == currentUser.user_id
                    }"
                    id="{{ teamActivityItem.last_name }}"
                    *ngIf="
                      teamActivityItem.step_count > 0 &&
                      (index < 10 ||
                        teamActivityItem.user_id == currentUser.user_id ||
                        weShowall == true)
                    "
                  >
                    <td *ngIf="weekendChallenge.endDate < now">
                      <span *ngIf="index == 0" class="medal"
                        ><img
                          src="./assets/img/icon-aw-medal-gold.png"
                          width="16"
                          alt="Gold medal"
                      /></span>
                      <span
                        *ngIf="
                          index == 1 &&
                          sortActivity(weekendActivity, 'goal_avg', true)[
                            index - 1
                          ].goal_avg != teamActivityItem.goal_avg
                        "
                        class="medal"
                        ><img
                          src="./assets/img/icon-aw-medal-silver.png"
                          width="16"
                          alt="Silver medal"
                      /></span>
                      <span
                        *ngIf="
                          index == 2 &&
                          sortActivity(weekendActivity, 'goal_avg', true)[
                            index - 1
                          ].goal_avg != teamActivityItem.goal_avg
                        "
                        class="medal"
                        ><img
                          src="./assets/img/icon-aw-medal-bronze.png"
                          width="16"
                          ald="Bronze medal"
                      /></span>
                    </td>
                    <td class="num">
                      <span
                        *ngIf="
                          index == 0 ||
                          sortActivity(weekendActivity, 'goal_avg', true)[
                            index - 1
                          ].goal_avg != teamActivityItem.goal_avg
                        "
                        >{{ index + 1 }}</span
                      >
                      <span
                        *ngIf="
                          index != 0 &&
                          sortActivity(weekendActivity, 'goal_avg', true)[
                            index - 1
                          ].goal_avg == teamActivityItem.goal_avg
                        "
                      >
                        {{
                          getPos(
                            sortActivity(weekendActivity, "goal_avg", true),
                            teamActivityItem,
                            "goal_avg"
                          )
                        }}
                      </span>
                    </td>
                    <td class="avi">
                      <img
                        [src]="
                          (teamActivityItem.avatar !== null &&
                            environment.assetUrl +
                              '/assets/user/' +
                              teamActivityItem.avatar) ||
                          './assets/img/default-avatar.png'
                        "
                        class="img-circle img-avatar"
                        width="30"
                        height="30"
                      />
                    </td>
                    <td width="50%">{{ teamActivityItem.screen_name }}</td>
                    <td class="text-right">
                      {{ teamActivityItem.goal_avg | number }}%
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
          <div role="tabpanel" id="step-count" *ngIf="!showPercentWe">
            <table class="table table-leaderboard">
              <thead>
                <tr>
                  <th colspan="5" *ngIf="weekendChallenge.endDate < now">
                    LEADERBOARD (<a
                      href="#"
                      class="info-link"
                      (click)="$event.preventDefault()"
                      [popover]="popTemplate"
                    >
                      <span [ngSwitch]="currentUser.step_band">
                        <span *ngSwitchCase="1">&lt;2,000 steps</span>
                        <span *ngSwitchCase="2">2,000 - 5,000 steps</span>
                        <span *ngSwitchCase="3"> 5,001 - 10,000 steps</span>
                        <span *ngSwitchCase="4"> 10,001 - 15,000 steps</span>
                        <span *ngSwitchCase="5">&gt; 15,000 steps</span>
                      </span>
                      )
                      <span class="icon-wrap"
                        ><fa-icon [icon]="['fas', 'info']"></fa-icon></span
                    ></a>
                  </th>
                  <th colspan="4" *ngIf="weekendChallenge.endDate >= now">
                    LEADERBOARD (<a
                      href="#"
                      class="info-link"
                      (click)="$event.preventDefault()"
                      [popover]="popTemplate"
                    >
                      <span [ngSwitch]="currentUser.step_band">
                        <span *ngSwitchCase="1">&lt;2,000 steps</span>
                        <span *ngSwitchCase="2">2,000 - 5,000 steps</span>
                        <span *ngSwitchCase="3"> 5,001 - 10,000 steps</span>
                        <span *ngSwitchCase="4"> 10,001 - 15,000 steps</span>
                        <span *ngSwitchCase="5">&gt; 15,000 steps</span>
                      </span>
                      )
                      <span class="icon-wrap"
                        ><fa-icon [icon]="['fas', 'info']"></fa-icon></span
                    ></a>
                  </th>
                </tr>
              </thead>
              <tbody>
                <ng-container
                  *ngFor="
                    let teamActivityItem of sortActivity(
                      weekendActivity,
                      'step_count',
                      true
                    );
                    let index = index
                  "
                >
                  <tr
                    [ngClass]="{
                      me: teamActivityItem.user_id == currentUser.user_id
                    }"
                    id="{{ teamActivityItem.last_name }}"
                    *ngIf="teamActivityItem.step_count > 0 && index<10"
                  >
                    <td *ngIf="weekendChallenge.endDate < now">
                      <span *ngIf="index == 0" class="medal"
                        ><img
                          src="./assets/img/icon-aw-medal-gold.png"
                          width="16"
                          alt="Gold medal"
                      /></span>
                      <span
                        *ngIf="
                          index == 1 &&
                          sortActivity(weekendActivity, 'step_count', true)[
                            index - 1
                          ].step_count != teamActivityItem.step_count
                        "
                        class="medal"
                        ><img
                          src="./assets/img/icon-aw-medal-silver.png"
                          width="16"
                          alt="Silver medal"
                      /></span>
                      <span
                        *ngIf="
                          index == 2 &&
                          sortActivity(weekendActivity, 'step_count', true)[
                            index - 1
                          ].step_count != teamActivityItem.step_count
                        "
                        class="medal"
                        ><img
                          src="./assets/img/icon-aw-medal-bronze.png"
                          width="16"
                          alt="Bronze medal"
                      /></span>
                    </td>
                    <td class="num">
                      <span
                        *ngIf="
                          index == 0 ||
                          sortActivity(weekendActivity, 'step_count', true)[
                            index - 1
                          ].step_count != teamActivityItem.step_count
                        "
                        >{{ index + 1 }}</span
                      >
                      <span
                        *ngIf="
                          index != 0 &&
                          sortActivity(weekendActivity, 'step_count', true)[
                            index - 1
                          ].step_count == teamActivityItem.step_count
                        "
                      >
                        {{
                          getPos(
                            sortActivity(weekendActivity, "step_count", true),
                            teamActivityItem,
                            "step_count"
                          )
                        }}
                      </span>
                    </td>
                    <td class="avi">
                      <img
                        [src]="
                          (teamActivityItem.avatar !== null &&
                            environment.assetUrl +
                              '/assets/user/' +
                              teamActivityItem.avatar) ||
                          './assets/img/default-avatar.png'
                        "
                        class="img-circle img-avatar"
                        width="30"
                        height="30"
                      />
                    </td>
                    <td width="50%">{{ teamActivityItem.screen_name }}</td>
                    <td class="text-right">
                      {{ teamActivityItem.step_count | number }}
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
          <div class="text-center">
            <button
              *ngIf="
                sortActivity(weekendActivity, 'step_count', true).length > 10 &&
                !weShowall
              "
              class="btn btn-primary"
              (click)="weShowall = true"
            >
              Show all
            </button>
            <button
              *ngIf="
                sortActivity(weekendActivity, 'step_count', true).length > 10 &&
                weShowall
              "
              class="btn btn-primary"
              (click)="weShowall = false"
            >
              Show fewer
            </button>
          </div>
        </div>
        <div *ngIf="tableModeWe == 'awards'">
          <table class="table table-leaderboard">
            <thead>
              <tr>
                <th *ngIf="weekendChallenge.endDate < now" colspan="4">
                  RESULTS  <a
                  href="#"
                  class="info-link"
                  (click)="$event.preventDefault()"
                  [popover]="popTemplate"
                >(
                  <span [ngSwitch]="currentUser.step_band">
                    <span *ngSwitchCase="1">&lt;2,000 steps</span>
                    <span *ngSwitchCase="2">2,000 - 5,000 steps</span>
                    <span *ngSwitchCase="3"> 5,001 - 10,000 steps</span>
                    <span *ngSwitchCase="4"> 10,001 - 15,000 steps</span>
                    <span *ngSwitchCase="5">&gt; 15,000 steps</span>
                  </span>
                  )
                  <span class="icon-wrap"
                    ><fa-icon [icon]="['fas', 'info']"></fa-icon></span
                ></a>
                </th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let cResults of weekendActivity;let index = index;">
              <tr
               *ngIf="index<11"
                [ngClass]="{ me: cResults.user_id == currentUser.user_id }"
              >
                <td width="32">
                  <span *ngIf="cResults.award_rank == 1" class="medal"
                    ><img
                      src="./assets/img/icon-aw-medal-gold.png"
                      width="16"
                      alt="gold medal"
                  /></span>
                  <span *ngIf="cResults.award_rank == 2" class="medal"
                    ><img
                      src="./assets/img/icon-aw-medal-silver.png"
                      width="16"
                      alt="silver-medal"
                  /></span>
                  <span *ngIf="cResults.award_rank == 3" class="medal"
                    ><img
                      src="./assets/img/icon-aw-medal-bronze.png"
                      width="16"
                      alt="bronze medal"
                  /></span>
                </td>
                <td class="avi" width="32">
                  <img
                    [src]="
                      (cResults.avatar !== null &&
                        environment.assetUrl +
                          '/assets/user/' +
                          cResults.avatar) ||
                      './assets/img/default-avatar.png'
                    "
                    class="img-circle img-avatar"
                    width="30"
                    height="30"
                  />
                </td>
                <td>{{ cResults.screen_name }}</td>
                <td>{{ cResults.step_count | number }}</td>
              </tr>
            </ng-container>
            </tbody>
          </table>
        </div>
      </div>
      <hr class="divider" />
      <div class="challenge-nav">
        <a
          href=""
          class="cn-prev"
          (click)="$event.preventDefault(); prevData('weekend')"
          ><span class="icon-wrap"
            ><fa-icon [icon]="['fas', 'chevron-circle-left']"></fa-icon></span
        ></a>
        {{ weekendChallenge.startDate | dateFormatter: "DD/MM/yyyy" }} -
        {{ weekendChallenge.endDate | dateFormatter: "DD/MM/yyyy" }}
        <a
          href=""
          class="cn-next"
          *ngIf="weekendChallenge.nextStartDate < now"
          (click)="$event.preventDefault(); nextData('weekend')"
          ><span class="icon-wrap"
            ><fa-icon [icon]="['fas', 'chevron-circle-right']"></fa-icon></span
        ></a>
      </div>
    </div>
  </div>

  <div class="toggle-container">
    <div
      class="toggle-header toggle-header-left"
      (click)="row.rowToggle['lb'] = !row.rowToggle['lb']"
    >
      <a
        href=""
        class="toggle-nav toggle-nav-expand"
        [attr.aria-expanded]="row.rowToggle['lb']"
        aria-label=" Global leaderboard"
        (click)="$event.preventDefault()"
      >
        <span *ngIf="!row.rowToggle['lb']" class="icon-wrap"
          ><fa-icon [icon]="['fas', 'chevron-circle-down']"></fa-icon
        ></span>
        <span *ngIf="row.rowToggle['lb']" class="icon-wrap"
          ><fa-icon [icon]="['fas', 'chevron-circle-up']"></fa-icon
        ></span>
      </a>
      <div class="toggle-header-title">GLOBAL LEADERBOARD</div>
    </div>
    <div class="toggle-content" *ngIf="row.rowToggle['lb']">
      <div *ngIf="tableModeG != 'awards'">
        <div class="text-center">
          <button
            class="btn btn-primary btn-filter"
            (click)="filters.gFilterOn = !filters.gFilterOn"
          >
            FILTER <span class="caret"></span>
          </button>
        </div>
        <div class="dropdown-filter" *ngIf="filters.gFilterOn">
          <form>
            <div class="form-group">
              <label class="header">GENDER</label>
              <label
                ><input
                  name="gender"
                  type="radio"
                  [(ngModel)]="filters.gender"
                  value="all"
                />All</label
              >
              <label
                ><input
                  name="gender"
                  type="radio"
                  [(ngModel)]="filters.gender"
                  value="m"
                />Male</label
              >
              <label
                ><input
                  name="gender"
                  type="radio"
                  [(ngModel)]="filters.gender"
                  value="f"
                />Female</label
              >
              <label
                ><input
                  name="gender"
                  type="radio"
                  [(ngModel)]="filters.gender"
                  value="x"
                />X</label
              >
            </div>
            <hr />
            <div class="form-group">
              <label class="header">AGE</label>
              <label
                ><input
                  name="ageRange"
                  type="radio"
                  [(ngModel)]="filters.ageRange"
                  value="all"
                />All</label
              >
              <label
                ><input
                  name="ageRange"
                  type="radio"
                  [(ngModel)]="filters.ageRange"
                  value=">18"
                />&lt;18</label
              >
              <label
                ><input
                  name="ageRange"
                  type="radio"
                  [(ngModel)]="filters.ageRange"
                  value="18-30"
                />18-30</label
              >
              <label
                ><input
                  name="ageRange"
                  type="radio"
                  [(ngModel)]="filters.ageRange"
                  value="31-40"
                />31-40</label
              >
              <label
                ><input
                  name="ageRange"
                  type="radio"
                  [(ngModel)]="filters.ageRange"
                  value="41-50"
                />41-50</label
              >
              <label
                ><input
                  name="ageRange"
                  type="radio"
                  [(ngModel)]="filters.ageRange"
                  value="51-60"
                />51-60</label
              >
              <label
                ><input
                  name="ageRange"
                  type="radio"
                  [(ngModel)]="filters.ageRange"
                  value="61-70"
                />61-70</label
              >
              <label
                ><input
                  name="ageRange"
                  type="radio"
                  [(ngModel)]="filters.ageRange"
                  value="71-80"
                />71-80</label
              >
              <label
                ><input
                  name="ageRange"
                  type="radio"
                  [(ngModel)]="filters.ageRange"
                  value="81+"
                />81+</label
              >
            </div>
            <hr />
            <div class="form-group text-center">
              <a
                href="#"
                (click)="$event.preventDefault(); updateGlobal()"
                class="btn btn-white btn-xs"
                >APPLY</a
              >
            </div>
          </form>
        </div>
        <ul
          class="nav nav-tabs nav-tabs-duo justify-content-center"
          role="tablist"
        >
          <li role="presentation" [ngClass]="{ active: !showPercentG }">
            <a
              href=""
              aria-controls="step-count"
              role="tab"
              data-toggle="tab"
              (click)="$event.preventDefault(); showPercentG = false"
              >STEP COUNT</a
            >
          </li>
          <li role="presentation" [ngClass]="{ active: showPercentG }">
            <a
              href=""
              aria-controls="percent"
              role="tab"
              data-toggle="tab"
              (click)="$event.preventDefault(); showPercentG = true"
              >% OF DAILY TARGET
            </a>
          </li>
        </ul>
      </div>
      <div class="tab-content">
        <div *ngIf="tableModeG != 'awards'">
          <div role="tabpanel" id="percent" *ngIf="showPercentG">
            <table class="table table-leaderboard">
              <thead>
                <tr>
                  <th colspan="4">LEADERBOARD</th>
                </tr>
              </thead>
              <tbody>
                <ng-container
                  *ngFor="
                    let teamActivityItem of sortActivity(
                      globalActivity,
                      'goal_avg',
                      true
                    );
                    let index = index
                  "
                >
                  <tr
                    [ngClass]="{
                      me: teamActivityItem.user_id == currentUser.user_id
                    }"
                    id="{{ teamActivityItem.last_name }}"
                    *ngIf="
                      teamActivityItem.step_count > 0 &&
                      (index < 10 ||
                        teamActivityItem.user_id == currentUser.user_id ||
                        globalShowall == true)
                    "
                  >
                    <td class="num">
                      <span
                        *ngIf="
                          index == 0 ||
                          globalActivity[index - 1].goal_avg !=
                            teamActivityItem.goal_avg
                        "
                        >{{ index + 1 }}</span
                      >
                      <span
                        *ngIf="
                          index != 0 &&
                          globalActivity[index - 1].goal_avg ==
                            teamActivityItem.goal_avg
                        "
                      >
                        {{
                          getPos(globalActivity, teamActivityItem, "goal_avg")
                        }}
                      </span>
                    </td>
                    <td class="avi">
                      <img
                        [src]="
                          (teamActivityItem.avatar !== null &&
                            environment.assetUrl +
                              '/assets/user/' +
                              teamActivityItem.avatar) ||
                          './assets/img/default-avatar.png'
                        "
                        class="img-circle img-avatar"
                        width="30"
                        height="30"
                      />
                    </td>
                    <td width="50%">{{ teamActivityItem.screen_name }}</td>
                    <td class="text-right">
                      {{ teamActivityItem.goal_avg | number }}%
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
          <div role="tabpanel" id="step-count" *ngIf="!showPercentG">
            <table class="table table-leaderboard">
              <thead>
                <tr>
                  <th colspan="4">LEADERBOARD</th>
                </tr>
              </thead>
              <tbody>
                <ng-container
                  *ngFor="
                    let teamActivityItem of sortActivity(
                      globalActivity,
                      'step_count',
                      true
                    );
                    let index = index
                  "
                >
                  <tr
                    [ngClass]="{
                      me: teamActivityItem.user_id == currentUser.user_id
                    }"
                    id="{{ teamActivityItem.last_name }}"
                    *ngIf="
                      teamActivityItem.step_count > 0 &&
                      (index < 10 ||
                        teamActivityItem.user_id == currentUser.user_id ||
                        globalShowall == true)
                    "
                  >
                    <td class="num">
                      <span
                        *ngIf="
                          index == 0 ||
                          globalActivity[index - 1].step_count !=
                            teamActivityItem.step_count
                        "
                        >{{ index + 1 }}</span
                      >
                      <span
                        *ngIf="
                          index != 0 &&
                          globalActivity[index - 1].step_count ==
                            teamActivityItem.step_count
                        "
                      >
                        {{
                          getPos(globalActivity, teamActivityItem, "step_count")
                        }}
                      </span>
                    </td>
                    <td class="avi">
                      <img
                        [src]="
                          (teamActivityItem.avatar !== null &&
                            environment.assetUrl +
                              '/assets/user/' +
                              teamActivityItem.avatar) ||
                          './assets/img/default-avatar.png'
                        "
                        class="img-circle img-avatar"
                        width="30"
                        height="30"
                      />
                    </td>
                    <td width="50%">{{ teamActivityItem.screen_name }}</td>
                    <td class="text-right">
                      {{ teamActivityItem.step_count | number }}
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>

          <div class="text-center">
            <button
              *ngIf="
                sortActivity(globalActivity, 'step_count', true).length > 10 &&
                !globalShowall
              "
              class="btn btn-primary"
              (click)="globalShowall = true"
            >
              Show all
            </button>
            <button
              *ngIf="
                sortActivity(globalActivity, 'step_count', true).length > 10 &&
                globalShowall
              "
              class="btn btn-primary"
              (click)="globalShowall = false"
            >
              Show fewer
            </button>
          </div>
        </div>
        <div *ngIf="tableModeG == 'awards'">
          <table class="table table-leaderboard">
            <thead>
              <tr>
                <th *ngIf="globalChallenge.endDate < now" colspan="4">
                  LEADERBOARD 
                </th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let cResults of globalActivity;let index = index;">
              <tr
               *ngIf="index<11"
                [ngClass]="{ me: cResults.user_id == currentUser.user_id }"
              >
                <td width="32">
                  <span *ngIf="cResults.award_rank == 1" class="medal"
                    ><img
                      src="./assets/img/icon-aw-medal-gold.png"
                      width="16"
                      alt="gold medal"
                  /></span>
                  <span *ngIf="cResults.award_rank == 2" class="medal"
                    ><img
                      src="./assets/img/icon-aw-medal-silver.png"
                      width="16"
                      alt="silver-medal"
                  /></span>
                  <span *ngIf="cResults.award_rank == 3" class="medal"
                    ><img
                      src="./assets/img/icon-aw-medal-bronze.png"
                      width="16"
                      alt="bronze medal"
                  /></span>
                </td>
                <td class="avi" width="32">
                  <img
                    [src]="
                      (cResults.avatar !== null &&
                        environment.assetUrl +
                          '/assets/user/' +
                          cResults.avatar) ||
                      './assets/img/default-avatar.png'
                    "
                    class="img-circle img-avatar"
                    width="30"
                    height="30"
                  />
                </td>
                <td>{{ cResults.screen_name }}</td>
                <td>{{ cResults.step_count | number }}</td>
              </tr>
            </ng-container>
            </tbody>
          </table>
        </div>
      </div>
      <hr class="divider" />
      <div class="challenge-nav">
        <a
          href=""
          class="cn-prev"
          (click)="$event.preventDefault(); prevDataGlobal()"
          ><span class="icon-wrap"
            ><fa-icon [icon]="['fas', 'chevron-circle-left']"></fa-icon></span
        ></a>
        {{ globalChallenge.startDate | dateFormatter: "DD/MM/yyyy" }} -
        {{ globalChallenge.endDate | dateFormatter: "DD/MM/yyyy" }}
        <a
          href=""
          class="cn-next"
          *ngIf="globalChallenge.nextStartDate < now"
          (click)="$event.preventDefault(); nextDataGlobal()"
          ><span class="icon-wrap"
            ><fa-icon [icon]="['fas', 'chevron-circle-right']"></fa-icon></span
        ></a>
      </div>
    </div>
  </div>

  <!--div class="toggle-container" *ngFor="let customChallenge of customChallenges">
  <div
    class="toggle-header toggle-header-left"
    (click)="
      row.rowToggle[customChallenge.challenge_id] = !row.rowToggle[
        customChallenge.challenge_id
      ]
    "
  >
    <a
      href=""
      class="toggle-nav toggle-nav-expand"
      aria-label=" Custom challenge"
      [attr.aria-expanded]="row.rowToggle[customChallenge.challenge_id]"
      ><i
        *ngIf="!row.rowToggle[customChallenge.challenge_id]"
        class="fa fa-chevron-circle-down"
      ></i
      ><i
        *ngIf="row.rowToggle[customChallenge.challenge_id]"
        class="fa fa-chevron-circle-up"
      ></i
    ></a>
    <div class="toggle-header-title">
      {{ customChallenge.title | uppercase }} ({{
        customChallenge.start_date | dateFormatter: "d MMM"
      }}
      - {{ customChallenge.end_date | dateFormatter: "d MMM" }})
    </div>
  </div>
  <div
    class="toggle-content"
    *ngIf="row.rowToggle[customChallenge.challenge_id]"
  >
    <ul class="nav nav-tabs nav-tabs-duo" role="tablist">
      <li role="presentation" [ngClass]="{ active: showPercentCu }">
        <a
          aria-controls="percent"
          role="tab"
          data-toggle="tab"
          (click)="showPercentCu = true"
          >% OF DAILY TARGET (AVERAGE)
        </a>
      </li>
      <li role="presentation" [ngClass]="{ active: !showPercentCu }">
        <a
          aria-controls="step-count"
          role="tab"
          data-toggle="tab"
          (click)="showPercentCu = false"
          >STEP COUNT</a
        >
      </li>
    </ul>

    <div class="tab-content">
      <div
        role="tabpanel"
        
        id="percent"
        *ngIf="showPercentCu"
      >
        <table class="table table-leaderboard">
          <thead>
            <tr>
              <th colspan="4">LEADERBOARD</th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let teamActivityItem of customChallenge.team_activity | sort:'-1*goal_avg'"
              [ngClass]="{
                me: teamActivityItem.user_id == currentUser.user_id
              }"
              id="{{ teamActivityItem.last_name }}"
              *ngIf="teamActivityItem.step_count > 0"
            >
              <td class="num">{{ index + 1 }}</td>
              <td class="avi">
                <img
                  [src]="
                    (teamActivityItem.avatar !== null &&
                    environment.assetUrl + '/assets/user/' + teamActivityItem.avatar) ||
                    './assets/img/default-avatar.png'
                  "
                  class="img-circle img-avatar"
                  width="30"
                  height="30"
                />
              </td>
              <td width="50%">{{ teamActivityItem.screen_name }}</td>
              <td class="text-right">
                {{ teamActivityItem.goal_avg | number }}%
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        role="tabpanel"
        
        id="step-count"
        *ngIf="!showPercentCu"
      >
        <table class="table table-leaderboard">
          <thead>
            <tr>
              <th colspan="4">LEADERBOARD</th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let teamActivityItem of customChallenge.team_activity | sort:'-1*step_count'"
              [ngClass]="{
                me: teamActivityItem.user_id == currentUser.user_id
              }"
              id="{{ teamActivityItem.last_name }}"
              *ngIf="teamActivityItem.step_count > 0"
            >
              <td class="num">{{ index + 1 }}</td>
              <td class="avi">
                <img
                  [src]="
                    (teamActivityItem.avatar !== null &&
                    environment.assetUrl + '/assets/user/' + teamActivityItem.avatar) ||
                    './assets/img/default-avatar.png'
                  "
                  class="img-circle img-avatar"
                  width="30"
                  height="30"
                />
              </td>
              <td width="50%">{{ teamActivityItem.screen_name }}</td>
              <td class="text-right">
                {{ teamActivityItem.step_count | number }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div-->
  <div
    class="toggle-container"
    ng-init="toggleRow.custom = false;"
    *ngIf="mapChallenge"
  >
    <div
      class="toggle-header toggle-header-left"
      (click)="toggleRow.custom = !toggleRow.custom"
    >
      <a
        href=""
        class="toggle-nav toggle-nav-expand"
        aria-label=" Map challenge"
        [attr.aria-expanded]="toggleRow.custom"
        (click)="$event.preventDefault()"
      >
        <span *ngIf="!toggleRow.custom" class="icon-wrap"
          ><fa-icon [icon]="['fas', 'chevron-circle-down']"></fa-icon
        ></span>
        <span *ngIf="toggleRow.custom" class="icon-wrap"
          ><fa-icon [icon]="['fas', 'chevron-circle-up']"></fa-icon
        ></span>
      </a>
      <div class="toggle-header-title">MAP CHALLENGE</div>
    </div>
    <div class="toggle-content" style="padding: 0">
      <div [ngClass]="toggleRow.custom ? 'showmap' : 'hidemap'">
        <div class="text-center">
          <div
            class="
              reading-material-page-header
              booster-session-step-header
              text-left
            "
            style="margin: 0"
          >
            {{ mapChallenge.title }}
            <div>
              <strong>{{ mapChallengeDistance / 1000 | number }} km</strong> of
              <strong
                >{{ challengeDistanceOutput.text / 1000 | number }} km</strong
              >
            </div>
            <div
              *ngIf="checkMapDate(currentMapProgress?.date_started)"
              class="map-info-msg"
            >
              <em>This challenge will start tomorrow</em>
            </div>
          </div>
        </div>
        <agm-map
          [latitude]="origin.lat"
          [longitude]="origin.lng"
          [disableDefaultUI]="true"
          *ngIf="origin"
          (mapReady)="mapReady($event)"
        >
          <agm-zoom-control></agm-zoom-control>
          <agm-fullscreen-control></agm-fullscreen-control>
          <agm-direction
            *ngIf="origin && destination"
            [origin]="origin"
            [destination]="destination"
            [waypoints]="wayPoints"
            (onResponse)="onResponse($event)"
            [renderOptions]="{ suppressMarkers: true }"
            [markerOptions]="markerOptions"
            [travelMode]="'WALKING'"
          ></agm-direction>
        </agm-map>
        <br />
        <div *ngIf="mapCompleted == 1" class="text-center">
          <a (click)="reload()" class="btn btn-primary"
            >View next map that will start tomorrow</a
          >
        </div>
      </div>
    </div>
  </div>

  <app-section-nav
    [section]="'activity'"
    [active]="'challenges'"
  ></app-section-nav>
</div>
