<div class="m3-header d-none d-sm-block" *ngIf="currentUser">
  <div class="row mt-3 mb-3 welcome">
    <div class="col-md-6">
      <div class="d-flex align-items-center">
        <div>
          <a class="db-sidenav-avatar-link" [routerLink]="['/notifications']"
            ><img
              [src]="
                (currentUser.avatar !== null &&
                  environment.assetUrl +
                    '/assets/user/' +
                    currentUser.avatar) ||
                './assets/img/default-avatar.png'
              "
              class="img-circle img-avatar"
              width="60"
              height="60"
              alt="User avatar"
            />
          </a>
        </div>
        <div>
          <h2 class="text-orange">
            {{ currentUser.first_name + " " + currentUser.last_name }}
          </h2>
          <h3>
            {{ age }} | {{currentUser.dob | dateFormatter : "DD/MM/yyyy"}} | <span *ngIf="currentUser.gender == 'f'">Female</span>
            <span *ngIf="currentUser.gender == 'm'">Male</span>
          </h3>
        </div>
      </div>
    </div>
    <div class="col-md-6 text-right" *ngIf="nextAppointment">
      <h2 class="text-pink">Appointment:</h2>
      <h3>
        {{ nextAppointment.appointment_date | dateFormatter: "DD MMM YYYY" }}
        <span>({{ nextAppointment.appointment }})</span>
      </h3>
    </div>
  </div>
</div>

<!--mobile version -->

<div class="d-block d-sm-none m3-header-mobile" *ngIf="currentUser">
  <div class="welcome">
  <div class="user">
    <a class="db-sidenav-avatar-link" [routerLink]="['/notifications']"
      ><img
        [src]="
          (currentUser.avatar !== null &&
            environment.assetUrl + '/assets/user/' + currentUser.avatar) ||
          './assets/img/default-avatar.png'
        "
        class="img-circle img-avatar"
        width="40"
        height="40"
        alt="User avatar"
      />
    </a>

    <h2 class="text-orange">
      {{ currentUser.first_name + " " + currentUser.last_name }}
    </h2>
    <div>
      {{ age }} | <span *ngIf="currentUser.gender == 'f'">Female</span>
      <span *ngIf="currentUser.gender == 'm'">Male</span>
    </div>
  </div>
  <div class="appointment" *ngIf="nextAppointment">
      <span class="text-pink"><strong>Appointment:</strong>  </span>
      <span>
        {{ nextAppointment.appointment_date | dateFormatter: "DD MMM YYYY" }}
        <span>({{ nextAppointment.appointment }})</span>
      </span>
  </div>
</div>
</div>
