<nav class="subnav">
  <div *ngIf="currentProgramme && currentUser">

    <div *ngIf="currentProgramme.config.trackers.indexOf('strength') > -1">
      <a
      
        [routerLink]="['/activity', 'strength']"
        aria-label="Go to strength training"
        class="subnav-prev"
        ><span class="icon-wrap"
          ><fa-icon [icon]="['fas', 'chevron-circle-left']"></fa-icon></span
      ></a>
    </div>
    <div *ngIf="currentProgramme.config.trackers.indexOf('strength') === -1">
    <a
      *ngIf="currentProgramme.config.buddies == 1 && currentUser.buddy"
      [routerLink]="['/steps']"
      aria-label="Go to steps"
      class="subnav-prev"
      ><span class="icon-wrap"
        ><fa-icon [icon]="['fas', 'chevron-circle-left']"></fa-icon></span
    ></a>

    <a
      *ngIf="currentProgramme.config.buddies != 1 || !currentUser.buddy"
      [routerLink]="['/challenges']"
      aria-label="Challenges"
      class="subnav-prev"
      ><span class="icon-wrap"
        ><fa-icon [icon]="['fas', 'chevron-circle-left']"></fa-icon></span
    ></a>
    </div>
  </div>


  <h2>
    <span class="icon-wrap"
      ><fa-icon [icon]="['fas', 'user-friends']"></fa-icon
    ></span>
    BUDDIES
  </h2>
  <div *ngIf="currentProgramme && currentUser">
    <a [routerLink]="['/target']" aria-label="Go to target" class="subnav-next"
      ><span class="icon-wrap"
        ><fa-icon [icon]="['fas', 'chevron-circle-right']"></fa-icon></span
    ></a>
  </div>
</nav>
<div *ngIf="isFetching">
  <app-loading-spinner [inline]="1"></app-loading-spinner>
</div>

<div *ngIf="currentProgramme && currentUser">
  <div class="masthead masthead-accent">
    <div class="masthead-challenge">
      <img
        src="./assets/img/icon-flag.png"
        width="60"
        class="masthead-icon"
        alt="Flag"
      />
      <div class="masthead-figure">
        CURRENT LEADER<span *ngIf="currentLeader.length > 1">S</span>
      </div>
      <div class="masthead-strap">{{ currentChallenge.title | uppercase }}</div>
      <div *ngFor="let leader of currentLeader">
        <div class="masthead-leader">
          <img
            [src]="
              (leader.avatar !== null &&
                environment.assetUrl + '/assets/user/' + leader.avatar) ||
              'assets/img/default-avatar.png'
            "
            class="img-circle img-avatar"
            width="50"
            height="50"
            alt="User avatar"
          />
          <span class="leader-name">{{ leader.screen_name }}</span>
        </div>
      </div>
    </div>

    <p
      class="masthead-subtext"
      *ngIf="!iLead && myActivity && currentLeader[0]"
    >
      You are
      <strong>{{
      +currentLeader[0].step_count - +myActivity.step_count | number
    }}</strong>
      steps from top spot
    </p>
  </div>

  <div class="text-center" *ngIf="!currentUser.buddy">
    <button
      [routerLink]="['/friends-and-family', 'invites']"
      class="btn btn-primary"
      style="margin: 10px 0"
    >
      <span class="icon-wrap"
        ><fa-icon [icon]="['fas', 'ticket']"></fa-icon
      ></span>
      {{ remainingInvites }} INVITE<span *ngIf="remainingInvites != 1">S</span>
      REMAINING
    </button>
  </div>

  <!-- mon - fri -->
  <div class="toggle-container">
    <div
      class="toggle-header toggle-header-left"
      (click)="toggleRow.monFri = !toggleRow.monFri"
    >
      <a
        href=""
        class="toggle-nav toggle-nav-expand"
        (click)="$event.preventDefault()"
      >
        <span class="icon-wrap" *ngIf="!toggleRow.monFri"
          ><fa-icon [icon]="['fas', 'chevron-circle-down']"></fa-icon
        ></span>
        <span class="icon-wrap" *ngIf="toggleRow.monFri"
          ><fa-icon [icon]="['fas', 'chevron-circle-up']"></fa-icon
        ></span>
      </a>
      <div class="toggle-header-title">MONDAY - FRIDAY CHALLENGE</div>
    </div>
    <div class="toggle-content" *ngIf="toggleRow.monFri">
      <div *ngIf="monfriChallenge.endDate < now">
        <p class="text-center">This challenge has finished</p>
      </div>

      <!-- Nav tabs -->
      <ul class="nav nav-tabs nav-tabs-duo" role="tablist">
        <li role="presentation" [ngClass]="{ active: !showPercent }">
          <a
            aria-controls="step-counta"
            role="tab"
            data-toggle="tab"
            (click)="$event.preventDefault(); showPercent = false"
            >STEP COUNT</a
          >
        </li>
        <li role="presentation" [ngClass]="{ active: showPercent }">
          <a
            aria-controls="percenta"
            role="tab"
            data-toggle="tab"
            (click)="$event.preventDefault(); showPercent = true"
            >% OF DAILY TARGET (AVERAGE)
          </a>
        </li>
      </ul>

      <!-- Tab panes -->
      <div class="tab-content">
        <div role="tabpanel" id="percenta" *ngIf="showPercent">
          <table class="table table-leaderboard">
            <thead>
              <tr>
                <th *ngIf="monfriChallenge.endDate < now" colspan="5">
                  LEADERBOARD
                </th>
                <th *ngIf="monfriChallenge.endDate >= now" colspan="4">
                  LEADERBOARD
                </th>
              </tr>
            </thead>
            <tbody>
              <ng-container
                *ngFor="
                  let teamActivityItem of sortActivity(
                    monFriActivity,
                    'goal_avg',
                    true
                  );
                  let index = index
                "
              >
                <tr
                  [ngClass]="{
                    me: teamActivityItem.user_id == currentUser.user_id
                  }"
                  id="{{ teamActivityItem.last_name }}"
                  *ngIf="teamActivityItem.step_count > 0"
                >
                  <td *ngIf="monfriChallenge.endDate < now">
                    <span *ngIf="index == 0" class="medal"
                      ><img
                        src="./assets/img/icon-aw-medal-gold.png"
                        width="16"
                        alt="Gold medal"
                    /></span>
                    <span
                      *ngIf="
                        index == 1 &&
                        sortActivity(monFriActivity, 'goal_avg', true)[
                          index - 1
                        ].goal_avg != teamActivityItem.goal_avg
                      "
                      class="medal"
                      ><img
                        src="./assets/img/icon-aw-medal-silver.png"
                        width="16"
                        alt="Silver medal"
                    /></span>
                    <span
                      *ngIf="
                        index == 2 &&
                        sortActivity(monFriActivity, 'goal_avg', true)[
                          index - 1
                        ].goal_avg != teamActivityItem.goal_avg
                      "
                      class="medal"
                      ><img
                        src="./assets/img/icon-aw-medal-bronze.png"
                        width="16"
                        alt="Bronze medal"
                    /></span>
                  </td>
                  <td class="num">
                    <span
                      *ngIf="
                        index == 0 ||
                        sortActivity(monFriActivity, 'goal_avg', true)[
                          index - 1
                        ].goal_avg != teamActivityItem.goal_avg
                      "
                      >{{ index + 1 }}</span
                    >
                    <span
                      *ngIf="
                        index != 0 &&
                        sortActivity(monFriActivity, 'goal_avg', true)[
                          index - 1
                        ].goal_avg == teamActivityItem.goal_avg
                      "
                    >
                      {{
                        getPos(
                          sortActivity(monFriActivity, "goal_avg", true),
                          teamActivityItem,
                          "goal_avg"
                        )
                      }}
                    </span>
                  </td>
                  <td class="avi">
                    <img
                      [src]="
                        (teamActivityItem.avatar !== null &&
                          environment.assetUrl +
                            '/assets/user/' +
                            teamActivityItem.avatar) ||
                        'assets/img/default-avatar.png'
                      "
                      class="img-circle img-avatar"
                      width="30"
                      height="30"
                      alt="User avatar"
                    />
                  </td>
                  <td width="50%">{{ teamActivityItem.screen_name }}</td>
                  <td class="text-right">
                    {{ teamActivityItem.goal_avg | number }}%
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
        <div role="tabpanel" id="step-counta" *ngIf="!showPercent">
          <table class="table table-leaderboard">
            <thead>
              <tr>
                <th *ngIf="monfriChallenge.endDate < now" colspan="5">
                  LEADERBOARD
                </th>
                <th *ngIf="monfriChallenge.endDate >= now" colspan="4">
                  LEADERBOARD
                </th>
              </tr>
            </thead>
            <tbody>
              <ng-container
                *ngFor="
                  let teamActivityItem of sortActivity(
                    monFriActivity,
                    'step_count',
                    true
                  );
                  let index = index
                "
              >
                <tr
                  [ngClass]="{
                    me: teamActivityItem.user_id == currentUser.user_id
                  }"
                  id="{{ teamActivityItem.last_name }}"
                  *ngIf="teamActivityItem.step_count > 0"
                >
                  <td *ngIf="monfriChallenge.endDate < now">
                    <span *ngIf="index == 0" class="medal"
                      ><img
                        src="./assets/img/icon-aw-medal-gold.png"
                        width="16"
                        alt="Gold medal"
                    /></span>
                    <span
                      *ngIf="
                        index == 1 &&
                        sortActivity(monFriActivity, 'step_count', true)[
                          index - 1
                        ].step_count != teamActivityItem.step_count
                      "
                      class="medal"
                      ><img
                        src="./assets/img/icon-aw-medal-silver.png"
                        width="16"
                        alt="Silver medal"
                    /></span>
                    <span
                      *ngIf="
                        index == 2 &&
                        sortActivity(monFriActivity, 'step_count', true)[
                          index - 1
                        ].step_count != teamActivityItem.step_count
                      "
                      class="medal"
                      ><img
                        src="./assets/img/icon-aw-medal-bronze.png"
                        width="16"
                        alt="Bronze medal"
                    /></span>
                  </td>
                  <td class="num">
                    <span
                      *ngIf="
                        index == 0 ||
                        sortActivity(monFriActivity, 'step_count', true)[
                          index - 1
                        ].step_count != teamActivityItem.step_count
                      "
                      >{{ index + 1 }}</span
                    >
                    <span
                      *ngIf="
                        index != 0 &&
                        sortActivity(monFriActivity, 'step_count', true)[
                          index - 1
                        ].step_count == teamActivityItem.step_count
                      "
                    >
                      {{
                        getPos(
                          sortActivity(monFriActivity, "goal_avg", true),
                          teamActivityItem,
                          "step_count"
                        )
                      }}
                    </span>
                  </td>

                  <td class="avi">
                    <img
                      [src]="
                        (teamActivityItem.avatar !== null &&
                          environment.assetUrl +
                            '/assets/user/' +
                            teamActivityItem.avatar) ||
                        'assets/img/default-avatar.png'
                      "
                      class="img-circle img-avatar"
                      width="30"
                      height="30"
                      alt="User avatar"
                    />
                  </td>
                  <td width="50%">{{ teamActivityItem.screen_name }}</td>
                  <td class="text-right">
                    {{ teamActivityItem.step_count | number }}
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
      <a
        [routerLink]="[
          '/friends-and-family-comments',
          monfriChallenge.type,
          monfriChallenge.startDate,
          monfriChallenge.endDate
        ]"
        role="button"
        class="btn-inv"
        ><i class="fa fa-commenting"></i>
        {{ monfriComments.length }} comment<span
          *ngIf="monfriComments.length != 1"
          >s</span
        >
      </a>
      <hr class="divider" />
      <div class="challenge-nav">
        <a
          class="cn-prev"
          (click)="$event.preventDefault(); prevData('mon-fri')"
          ><span class="icon-wrap"
            ><fa-icon [icon]="['fas', 'chevron-circle-left']"></fa-icon></span
        ></a>
        {{ monfriChallenge.startDate | dateFormatter: "DD/MM/yyyy" }} -
        {{ monfriChallenge.endDate | dateFormatter: "DD/MM/yyyy" }}
        <a
          class="cn-next"
          *ngIf="monfriChallenge.endDate < now"
          (click)="$event.preventDefault(); nextData('mon-fri')"
          ><span class="icon-wrap"
            ><fa-icon [icon]="['fas', 'chevron-circle-right']"></fa-icon></span
        ></a>
      </div>
    </div>
  </div>

  <div class="toggle-container">
    <div
      class="toggle-header toggle-header-left"
      (click)="toggleRow.weekend = !toggleRow.weekend"
    >
      <a
        href=""
        class="toggle-nav toggle-nav-expand"
        (click)="$event.preventDefault()"
      >
        <span class="icon-wrap" *ngIf="!toggleRow.weekend"
          ><fa-icon [icon]="['fas', 'chevron-circle-down']"></fa-icon
        ></span>
        <span class="icon-wrap" *ngIf="toggleRow.weekend"
          ><fa-icon [icon]="['fas', 'chevron-circle-up']"></fa-icon
        ></span>
      </a>
      <div class="toggle-header-title">WEEKEND CHALLENGE</div>
    </div>
    <div class="toggle-content" *ngIf="toggleRow.weekend">
      <div *ngIf="weekendChallenge.endDate < now">
        <p class="text-center">This challenge has finished</p>
      </div>
      <!-- Nav tabs -->
      <ul class="nav nav-tabs nav-tabs-duo" role="tablist">
        <li role="presentation" [ngClass]="{ active: !showPercent }">
          <a
            aria-controls="step-count"
            role="tab"
            data-toggle="tab"
            (click)="showPercent = false"
            >STEP COUNT</a
          >
        </li>
        <li role="presentation" [ngClass]="{ active: showPercent }">
          <a
            aria-controls="percent"
            role="tab"
            data-toggle="tab"
            (click)="showPercent = true"
            >% OF DAILY TARGET (AVERAGE)
          </a>
        </li>
      </ul>

      <!-- Tab panes -->
      <div class="tab-content">
        <div
          role="tabpanel"
          id="percent"
          *ngIf="showPercent"
          aria-labelledby="percent"
        >
          <table class="table table-leaderboard">
            <thead>
              <tr>
                <th colspan="5" *ngIf="weekendChallenge.endDate < now">
                  LEADERBOARD
                </th>
                <th colspan="4" *ngIf="weekendChallenge.endDate >= now">
                  LEADERBOARD
                </th>
              </tr>
            </thead>
            <tbody>
              <ng-container
                *ngFor="
                  let teamActivityItem of sortActivity(
                    weekendActivity,
                    'goal_avg',
                    true
                  );
                  let index = index
                "
              >
                <tr
                  [ngClass]="{
                    me: teamActivityItem.user_id == currentUser.user_id
                  }"
                  id="{{ teamActivityItem.last_name }}"
                  *ngIf="teamActivityItem.step_count > 0"
                >
                  <td *ngIf="weekendChallenge.endDate < now">
                    <span *ngIf="index == 0" class="medal"
                      ><img
                        src="./assets/img/icon-aw-medal-gold.png"
                        width="16"
                    /></span>
                    <span
                      *ngIf="
                        index == 1 &&
                        sortActivity(weekendActivity, 'goal_avg', true)[
                          index - 1
                        ].goal_avg != teamActivityItem.goal_avg
                      "
                      class="medal"
                      ><img
                        src="./assets/img/icon-aw-medal-silver.png"
                        width="16"
                    /></span>
                    <span
                      *ngIf="
                        index == 2 &&
                        sortActivity(weekendActivity, 'goal_avg', true)[
                          index - 1
                        ].goal_avg != teamActivityItem.goal_avg
                      "
                      class="medal"
                      ><img
                        src="./assets/img/icon-aw-medal-bronze.png"
                        width="16"
                    /></span>
                  </td>
                  <td class="num">
                    <span
                      *ngIf="
                        index == 0 ||
                        sortActivity(weekendActivity, 'goal_avg', true)[
                          index - 1
                        ].goal_avg != teamActivityItem.goal_avg
                      "
                      >{{ index + 1 }}</span
                    >
                  </td>
                  <td class="avi">
                    <img
                      [src]="
                        (teamActivityItem.avatar !== null && 
                        environment.assetUrl +
                          '/assets/user/' + teamActivityItem.avatar) ||
                        'assets/img/default-avatar.png'
                      "
                      class="img-circle img-avatar"
                      width="30"
                      height="30"
                    />
                  </td>
                  <td width="50%">{{ teamActivityItem.screen_name }}</td>
                  <td class="text-right">
                    {{ teamActivityItem.goal_avg | number }}%
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
        <div
          role="tabpanel"
          id="step-count"
          *ngIf="!showPercent"
          aria-labelledBy="step-count"
        >
          <table class="table table-leaderboard">
            <thead>
              <tr>
                <th colspan="5" *ngIf="weekendChallenge.endDate < now">
                  LEADERBOARD
                </th>
                <th colspan="4" *ngIf="weekendChallenge.endDate >= now">
                  LEADERBOARD
                </th>
              </tr>
            </thead>
            <tbody>
              <ng-container
                *ngFor="
                  let teamActivityItem of sortActivity(
                    weekendActivity,
                    'step_count',
                    true
                  );
                  let index = index
                "
              >
                <tr
                  [ngClass]="{
                    me: teamActivityItem.user_id == currentUser.user_id
                  }"
                  id="{{ teamActivityItem.last_name }}"
                  *ngIf="teamActivityItem.step_count > 0"
                >
                  <td *ngIf="weekendChallenge.endDate < now">
                    <span *ngIf="index == 0" class="medal"
                      ><img
                        src="./assets/img/icon-aw-medal-gold.png"
                        width="16"
                    /></span>
                    <span
                      *ngIf="
                        index == 1 &&
                        sortActivity(weekendActivity, 'step_count', true)[
                          index - 1
                        ].step_count != teamActivityItem.step_count
                      "
                      class="medal"
                      ><img
                        src="./assets/img/icon-aw-medal-silver.png"
                        width="16"
                    /></span>
                    <span
                      *ngIf="
                        index == 2 &&
                        sortActivity(weekendActivity, 'step_count', true)[
                          index - 1
                        ].step_count != teamActivityItem.step_count
                      "
                      class="medal"
                      ><img
                        src="./assets/img/icon-aw-medal-bronze.png"
                        width="16"
                    /></span>
                  </td>
                  <td class="num">
                    <span
                      *ngIf="
                        index == 0 ||
                        sortActivity(weekendActivity, 'step_count', true)[
                          index - 1
                        ].step_count != teamActivityItem.step_count
                      "
                      >{{ index + 1 }}</span
                    >
                  </td>
                  <td class="avi">
                    <img
                      [src]="
                        (teamActivityItem.avatar !== null &&
                        environment.assetUrl +
                          '/assets/user/' + teamActivityItem.avatar) ||
                        'assets/img/default-avatar.png'
                      "
                      class="img-circle img-avatar"
                      width="30"
                      height="30"
                    />
                  </td>
                  <td width="50%">{{ teamActivityItem.screen_name }}</td>
                  <td class="text-right">
                    {{ teamActivityItem.step_count | number }}
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
      <a
        [routerLink]="[
          '/friends-and-family-comments',
          weekendChallenge.type,
          weekendChallenge.startDate,
          weekendChallenge.endDate
        ]"
        role="button"
        class="btn-inv"
        ><i class="fa fa-commenting"></i>
        {{ weekendComments.length }} comment<span
          *ngIf="weekendComments.length != 1"
          >s</span
        ></a
      >
      <hr class="divider" />
      <div class="challenge-nav">
        <a
          class="cn-prev"
          (click)="$event.preventDefault(); prevData('weekend')"
          ><span class="icon-wrap"
            ><fa-icon [icon]="['fas', 'chevron-circle-left']"></fa-icon></span
        ></a>
        {{ weekendChallenge.startDate | dateFormatter: "DD/MM/yyyy" }} -
        {{ weekendChallenge.endDate | dateFormatter: "DD/MM/yyyy" }}
        <a
          class="cn-next"
          *ngIf="weekendChallenge.nextStartDate < now"
          (click)="$event.preventDefault(); nextData('weekend')"
          ><span class="icon-wrap"
            ><fa-icon [icon]="['fas', 'chevron-circle-right']"></fa-icon></span
        ></a>
      </div>
    </div>
  </div>

  <app-section-nav
    [section]="'activity'"
    [active]="'buddies'"
  ></app-section-nav>
</div>
