<nav class="navbar navbar-default navbar-bs navbar-fixed-top">
    <button
      class="btn-navbar btn-side-menu visible-sm visible-xs"
      aria-label="Application Navigation"
      (click)="onToggleMobileMenu()"
      data-animation="am-slide-left"
    >
    <span class="icon-wrap"><fa-icon [icon]="['fas', 'bars']"></fa-icon></span>
    </button>
    <a
      ui-sref="dashboard"
      *ngIf="pageTitle == null"
      class="visible-sm visible-xs"
    >
      <span class="logo-text" *ngIf="!currentProgramme"
        ><img
          src="./assets/img/logo-my-desmond.png"
          height="50"
          alt="Programme Logo"
      /></span>
      <span class="logo-text" *ngIf="currentProgramme">
        <span *ngIf="currentProgramme.logo"
          ><!--img
            ng-src="{{ '/assets/' + currentProgramme.logo }}"
            height="50"
            alt="Programme Logo"
        /--></span>
        <!--span *ngIf="!currentProgramme.logo">{{
          currentProgramme.title
        }}</span-->
      </span>
    </a>
    <h1 *ngIf="pageTitle != null" class="mx-auto" >

      <span *ngIf="pageTitle ==='Home'">
        <img *ngIf="!currentProgramme"
          src="./assets/img/logo-my-desmond.png"
          height="50"
          alt="Programme Logo"
          class="d-inline-block d-sm-none"
      />
      <img  *ngIf="currentProgramme"
          [src]="environment.assetUrl + '/assets/' + currentProgramme.logo"
          
          style="max-height:50px;max-width:200px"
          alt="{{currentProgramme.title}} Logo"
          class="d-inline-block d-sm-none"
      />
      <span class="d-none d-sm-inline-block"> HOME</span>
      </span>
      <span *ngIf="pageTitle !=='Home'">
        {{ pageTitle | uppercase}}
      </span>
    </h1>
    <h1
      *ngIf="pageTitle == null"
      
      class="hidden-sm hidden-xs mx-auto"
    >
      HOME
      
    </h1>
    <button
      *ngIf="notifications.length > 0"
      class="btn-user-menu visible-sm visible-xs"
      (click)="onToggleUserMenu()"
      ui-sref="notifications"
      [routerLink]="['/notifications']"
      aria-label="User Menu"
    >
      <img
        [src]="
          (currentUser.avatar !== null &&
            environment.assetUrl + '/assets/user/' + currentUser.avatar) ||
            'assets/img/default-avatar.png'
        "
        class="img-circle img-avatar"
        width="44"
        height="44"
        alt="User avatar"
      />
      <span *ngIf="notifications.length > 0" class="notification">{{
        notifications.length
      }}</span>
    </button>
    <button
      *ngIf="notifications.length == 0"
      class="btn-user-menu visible-sm visible-xs"
      (click)="onToggleUserMenu()"
      aria-label="User Menu"
    >
      <img
        [src]="
          (currentUser.avatar !== null &&
          environment.assetUrl + '/assets/user/' + currentUser.avatar) ||
            'assets/img/default-avatar.png'
        "
        class="img-circle img-avatar"
        width="44"
        height="44"
        alt="User avatar"
      />
    </button>
  </nav>

  <app-user-menu [hidden]="!showUserMenu" (closeMenu)="onCloseMenu($event)" (showMobileMenu)="onShowMobileMenu($event)"></app-user-menu>
  <app-mobile-nav [hidden]="!showMobileMenu" (closeMobileMenu)="onCloseMobileMenu($event)" (showUserMenu)="onShowUserMenu($event)"></app-mobile-nav>