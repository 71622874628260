<nav class="subnav">
  <a
    *ngIf="currentProgramme && currentProgramme.config.m3 != 1"
    [routerLink]="['/achievements']"
    aria-label="Go to achievements"
    class="subnav-prev"
    ><span class="icon-wrap"
      ><fa-icon [icon]="['fas', 'chevron-circle-left']"></fa-icon></span
  ></a>
  <h2>
    <span class="icon-wrap"
      ><fa-icon [icon]="['fas', 'watch-fitness']"></fa-icon
    ></span>
    SETUP DEVICE
  </h2>
</nav>
<div *ngIf="isFetching">
  <app-loading-spinner [inline]="1"></app-loading-spinner>
</div>
<div *ngIf="currentUser && currentProgramme && responseData">
  <div class="toggle-container">
    <div class="toggle-content">
      <div *ngIf="currentUser.manual_steps != 1">
        <div *ngIf="errorCode">
          <p class="text-danger">
            <strong
              >There was an error connecting your account, please try
              again.</strong
            >
          </p>
        </div>
        <div *ngIf="responseData.status == 'failed'">
          <p>
            You do not have a device connected to your account or the connection
            needs refreshing. Please choose a button below to continue.
          </p>
          <div class="text-center">
            <a
              href="{{ responseData.fitbitAuthUrl }}"
              role="button"
              class="btn btn-primary"
              >CONNECT YOUR FITBIT
              <span class="icon-wrap"
                ><fa-icon
                  [icon]="['far', 'arrow-circle-right']"
                ></fa-icon></span
            ></a>
          </div>
          <br />
          <!--div class="text-center">
                          <a href="{{responseData.movesAuthUrl}}" role="button" class="btn btn-primary">CONNECT TO THE MOVES APP <i class="fa fa-arrow-circle-o-right"></i></a>
                      </div><br-->
          <div class="text-center">
            <a
              href="{{ responseData.googleAuthUrl }}"
              role="button"
              class="btn btn-primary"
              >CONNECT TO GOOGLE FIT
              <span class="icon-wrap"
                ><fa-icon
                  [icon]="['far', 'arrow-circle-right']"
                ></fa-icon></span
            ></a>
            <div
            class="py-2"
            *ngIf="
              currentUser &&
              currentProgramme &&
              currentProgramme?.config?.exerciseProgramme == 1
            "
          >If you have a <strong>smartphone</strong> or any <strong>other activity tracker</strong> device, you can connect this via Google Fit so select this option. For more information on how to connect to Google Fit, <a [routerLink]="environment.connectGoogleFitUrl">click here</a>.</div>
          </div>
          <br />
          <div class="text-center">
            <a
            href="{{ responseData.garminAuthUrl }}"
              role="button"
              class="btn btn-primary"
              >CONNECT TO GARMIN
              <span class="icon-wrap"
                ><fa-icon
                  [icon]="['far', 'arrow-circle-right']"
                ></fa-icon></span
            ></a>
          </div>
        </div>
        <div *ngIf="responseData.status == 'invalidState'">
          <p>We're having problems connecting your FitBit.</p>
          <div class="text-center">
            <a
              href="{{ responseData.authUrl }}"
              role="button"
              class="btn btn-primary"
              >CONNECT YOUR FITBIT
              <span class="icon-wrap"
                ><fa-icon
                  [icon]="['far', 'arrow-circle-right']"
                ></fa-icon></span
            ></a>
          </div>
        </div>
        <div *ngIf="responseData.status == 'success'">
          <div *ngIf="responseData.deviceType == 'fitbit'">
            Your FitBit is connected and we have access to your activity data.
          </div>
          <!--p *ngIf="responseData.deviceType == 'moves'">You are connected to the Moves app and we have access to your activity data.</p-->

          <p *ngIf="responseData.deviceType == 'google'">
            You are connected to Google Fit and we have access to your activity
            data.
          </p>
          <p *ngIf="responseData.deviceType == 'garmin'">
            Your Garmin is connected and we have access to your activity data.
          </p>
          <div class="text-center">
            <a
              [routerLink]="['/dashboard']"
              role="button"
              class="btn btn-primary mr-1"
              >DASHBOARD
              <span class="icon-wrap"
                ><fa-icon
                  [icon]="['far', 'arrow-circle-right']"
                ></fa-icon></span
            ></a>
            <a (click)="disconnect()" role="button" class="btn btn-primary ml-1"
              >DISCONNECT
              <span class="icon-wrap"
                ><fa-icon [icon]="['fas', 'times']"></fa-icon></span
            ></a>
          </div>
        </div>

        <div class="text-center">
          <br />
          <a (click)="setManual()" role="button" class="btn btn-primary"
            >RECORD MANUALLY
            <span class="icon-wrap"
              ><fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon></span
          ></a>
        </div>
      </div>

      <div *ngIf="currentUser.manual_steps">
        <div *ngIf="currentProgramme.config.exerciseProgramme !== 1">
          <p>You are set to record steps manually.</p>
        </div>

        <div class="text-center">
          <a
            [routerLink]="['/dashboard']"
            role="button"
            class="btn btn-primary mr-1"
            >DASHBOARD
            <span class="icon-wrap"
              ><fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon></span
          ></a>
          <a (click)="disconnect()" role="button" class="btn btn-primary ml-1"
            >DISCONNECT
            <span class="icon-wrap"
              ><fa-icon [icon]="['fas', 'times']"></fa-icon></span
          ></a>
        </div>
      </div>
    </div>
  </div>

  <div
    *ngIf="
      currentUser &&
      currentProgramme &&
      currentProgramme?.config?.exerciseProgramme == 1
    "
    class="p-2"
  >
    <p>
      This table shows what data is automatically entered into Steps4Health and what needs to be entered manually based on your activity measurement method.
    </p>
    <table cellspacing="0" cellpadding="0" class="table">
      <thead>
        <tr>
          <th class="text-center">Outcome</th>
          <th class="text-center">Fitbit</th>
          <th class="text-center">Google Fit</th>
          <th class="text-center">Garmin</th>
          <th class="text-center">Manual entry</th>
        </tr>
      </thead>
        

      <tbody>
        <tr>
          <td class="text-center">Daily steps</td>
          <td class="text-center">✓</td>
          <td class="text-center">✓</td>
          <td class="text-center">✓</td>
          <td class="text-center">M</td>
        </tr>
        <tr>
          <td class="text-center">Brisk steps</td>
          <td class="text-center">✓</td>
          <td class="text-center">✓</td>
          <td class="text-center">M</td>
          <td class="text-center">M</td>
        </tr>
        <tr>
          <td class="text-center">Active minutes <sup>*</sup></td>
          <td class="text-center">✓</td>
          <td class="text-center">✓</td>
          <td class="text-center">M</td>
          <td class="text-center"></td>
        </tr>
        <tr>
          <td class="text-center">Daily sitting</td>
          <td class="text-center">✓</td>
          <td class="text-center">M</td>
          <td class="text-center">M</td>
          <td class="text-center">M</td>
        </tr>
        <tr>
          <td class="text-center">Workouts</td>
          <td class="text-center">M</td>
          <td class="text-center">M</td>
          <td class="text-center">M</td>
          <td class="text-center">M</td>
        </tr>
        <tr>
          <td class="text-center">Daily sleeping</td>
          <td class="text-center">✓</td>
          <td class="text-center">M</td>
          <td class="text-center">M</td>
          <td class="text-center">M</td>
        </tr>
      </tbody>
    </table>
    <p>
      <strong>✓</strong> - Automatically populates into
      Steps4Health
    </p>
    <p><strong>M</strong> - Manual entry into Steps4Health required</p>
    <p>
      <sup>*</sup> Active minutes referred to as active zone minutes by Fitbit and heart
      points by Google Fit
    </p>
  </div>

  <app-section-nav
    [section]="'activity'"
    [active]="'device'"
    *ngIf="currentProgramme.config.m3 != 1"
  ></app-section-nav>
</div>
