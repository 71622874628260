import { UserService } from './user.service';
import { AuthService } from './../auth/auth.service';
import { map, catchError, take, publishReplay, refCount } from 'rxjs/operators';
import { HttpParams, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';
import { Subject, throwError } from 'rxjs';
import * as moment from 'moment';
import Swal from 'sweetalert2/src/sweetalert2.js';

@Injectable({
  providedIn: 'root',
})
export class ActivityService {
  userId;
  currentUser;

  userActivity?: any;
  cacheTime = new Date();
  cacheLifetime = 3 * 60 * 1000;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private userService: UserService
  ) {
    this.authService.user.subscribe((user) => {
      if (user) {
        this.userId = user.id;
        this.userService.userData.subscribe((userDataResponse) => {
          if (userDataResponse) {
            this.currentUser = userDataResponse;
          }
        });
      }
    });
  }

  apiTest(){
    return this.http
      .get<any>(environment.apiUrl + '/user_activities', {
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          return responseData._embedded.user_activities;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }
  getActivity(force = 0) {

    let searchParams = new HttpParams();
    if (force !== 0) {
      searchParams = searchParams.append('force', '' + force);
    }
    const now = new Date();
    if (
      force!=0 ||
      !this.userActivity ||
      Math.abs(now.getTime() - this.cacheTime.getTime()) > this.cacheLifetime
    ) {
      this.cacheTime = new Date();
      this.userActivity = this.http.get<any>(environment.apiUrl + '/sync_steps', {
        params: searchParams,
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          if (!responseData) {
            return null;
          }
          if (responseData._embedded?.sync_steps[0]?.status === 'success') {
            // $scope.setCurrentActivity(res.data.activityData);

            // console.log($scope.currentActivity);
            return responseData._embedded?.sync_steps[0]?.activityData;
            /*$scope.currentActivity.steps = parseInt(
              $scope.currentActivity.steps
            );
            $scope.currentActivity.minutes = parseInt(
              $scope.currentActivity.minutes
            );
*/
            // $scope.setDeviceSynced(true);
            /* if (force == 1) {
              $state.reload();
            }*/
          } else {
            return null;
            // $scope.setDeviceSynced(false);
            // if(force==1){$state.reload();}
          }
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        }),
          publishReplay(1),
          refCount()
        );
    }

    return this.userActivity;
  }

  getActivityBetween(startDate, endDate) {
    if (!this.userId) {
      return;
    }
    let searchParams = new HttpParams();
    searchParams = searchParams.append('user_id', this.userId);
    searchParams = searchParams.append('start_date', startDate);
    searchParams = searchParams.append('end_date', endDate);
    searchParams = searchParams.append('sort', 'activity_date');
    searchParams = searchParams.append('order', 'DESC');

    return this.http
      .get<any>(environment.apiUrl + '/user_activities', {
        params: searchParams,
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          return responseData._embedded.user_activities;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  getActivitySummary(startDate, endDate) {
    if (!this.userId) {
      return;
    }
    let searchParams = new HttpParams();
    searchParams = searchParams.append('user_id', this.userId);
    searchParams = searchParams.append('start_date', startDate);
    if (endDate) {
      searchParams = searchParams.append('end_date', endDate);
    }
    return this.http
      .get<any>(environment.apiUrl + '/user_activity_summary', {
        params: searchParams,
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          return responseData._embedded.user_activity_summary;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  getSteps() {
    if (!this.userId) {
      return;
    }

    return this.http
      .get<any>(environment.apiUrl + '/get-user-steps', {
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          return responseData;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }
  saveSteps(steps, date) {
    if(steps>100000){
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Please enter a lower step count',
        showConfirmButton: false,
        timer: 1000,
        timerProgressBar: true,
      });
    }
    else{
    this.clearCache();
    if (moment(date).format('YYYY-MM-DD') > moment().format('YYYY-MM-DD')) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'You cannot choose a date in the future',
        showConfirmButton: false,
        timer: 1000,
        timerProgressBar: true,
      });
    } else {
      const stepData = {
        steps,
        date: moment(date).format('YYYY-MM-DD'),
      };
      return this.http.post<any>(
        environment.apiUrl + '/user_activities',
        stepData,
        {
          observe: 'response',
        }
      );
    }
  }
  }
  saveMinutes(mins, date) {
    if(mins>1440){
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Please enter a realistic number',
        showConfirmButton: false,
        timer: 1000,
        timerProgressBar: true,
      });
    }
    else{
    this.clearCache();
    if (moment(date).format('YYYY-MM-DD') > moment().format('YYYY-MM-DD')) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'You cannot choose a date in the future',
        showConfirmButton: false,
        timer: 1000,
        timerProgressBar: true,
      });
    } else {
      const stepData = {
        mins,
        date: moment(date).format('YYYY-MM-DD'),
      };
      return this.http.post<any>(
        environment.apiUrl + '/user_activities',
        stepData,
        {
          observe: 'response',
        }
      );
    }
  }
  }

  saveSitting(mins, date) {
    this.clearCache();
    if (moment(date).format('YYYY-MM-DD') > moment().format('YYYY-MM-DD')) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'You cannot choose a date in the future',
        showConfirmButton: false,
        timer: 1000,
        timerProgressBar: true,
      });
    } else {
      const stepData = {
        sedentary_minutes: mins,
        date: moment(date).format('YYYY-MM-DD'),
      };
      return this.http.post<any>(
        environment.apiUrl + '/user_activities',
        stepData,
        {
          observe: 'response',
        }
      );
    }
  }

  deleteSitting(id) {
    this.clearCache();
    const stepData = {
      sedentary_minutes:0
    };
    return this.http.patch<any>(
      environment.apiUrl + '/user_activities/' + id,
      stepData,
      {
        observe: 'response',
      }
    );
  }

  deleteSteps(id) {
    this.clearCache();
    const stepData = {
      id: id,
    };
    return this.http.post<any>(
      environment.apiUrl + '/devices/delete-steps',
      stepData,
      {
        observe: 'response',
      }
    );
  }

  update(id, steps, hit_goal) {
    this.clearCache();
    const postData = {
      steps,
      hit_goal,
    };
    return this.http.patch<{ name: string }>(
      environment.apiUrl + '/user_activities/' + id,
      postData,
      {
        observe: 'response',
      }
    );
  }

  getBandActivity(band, programmeId, startDate, endDate) {
    let searchParams = new HttpParams();
    searchParams = searchParams.append('band', '' + band);
    searchParams = searchParams.append('programme_id', '' + programmeId);
    searchParams = searchParams.append('start_date', '' + startDate);
    searchParams = searchParams.append('end_date', '' + endDate);
    return this.http
      .get<any>(environment.apiUrl + '/band_activity', {
        params: searchParams,
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          return responseData._embedded.band_activity;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  getGlobalActivity(programmeId, startDate, endDate, gender, age_range) {
    let searchParams = new HttpParams();
    searchParams = searchParams.append('programme_id', '' + programmeId);
    searchParams = searchParams.append('start_date', '' + startDate);
    searchParams = searchParams.append('end_date', '' + endDate);
    searchParams = searchParams.append('gender', '' + gender);
    searchParams = searchParams.append('age_range', '' + age_range);

    return this.http
      .get<any>(environment.apiUrl + '/global_leaderboard', {
        params: searchParams,
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          return responseData._embedded.global_leaderboard;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  getBuddyActivity(startDate, endDate) {
    let searchParams = new HttpParams();
    searchParams = searchParams.append('user_id', this.userId);
    searchParams = searchParams.append('start_date', '' + startDate);
    searchParams = searchParams.append('end_date', '' + endDate);
    searchParams = searchParams.append('buddy', '' + this.currentUser.buddy);

    return this.http
      .get<any>(environment.apiUrl + '/buddy_activity', {
        params: searchParams,
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          return responseData._embedded.buddy_activity;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  sortActivity(items, sort, reverse) {
    let sorted = true;
    items = items.sort((a, b) => {
      if (!a[sort]) {
        // Change this values if you want to put `null` values at the end of the array
        // return -1;
      }
      if (!b[sort]) {
        // Change this values if you want to put `null` values at the end of the array
        // return +1;
      }

      let result = a[sort] - b[sort];

      if (isNaN(result)) {
        return !reverse
          ? a[sort].toString().localeCompare(b[sort])
          : b[sort].toString().localeCompare(a[sort]);
      } else {
        return !reverse ? result : -result;
      }

      /*
      if (sorted) return a[sort].localeCompare(b[sort]);
      else return b[sort].localeCompare(a[sort]);*/

      // a[sort].localeCompare(b[sort])
    });

    return items;
  }

  getChallengeBandResults(band, type, startDate, endDate) {
    let searchParams = new HttpParams();
    searchParams = searchParams.append('band', '' + band);
    searchParams = searchParams.append('type', '' + type);
    searchParams = searchParams.append('start_date', '' + startDate);
    searchParams = searchParams.append('end_date', '' + endDate);
    searchParams = searchParams.append(
      'ref',
      '' + type.toUpperCase() + ';' + startDate + ';' + endDate
    );
    return this.http
      .get<any>(environment.apiUrl + '/challenge_results', {
        params: searchParams,
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          return responseData._embedded.challenge_results;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  getChallengeGlobalResults(type, startDate, endDate) {
    let searchParams = new HttpParams();
    searchParams = searchParams.append('type', '' + type);
    searchParams = searchParams.append('start_date', '' + startDate);
    searchParams = searchParams.append('end_date', '' + endDate);
    searchParams = searchParams.append(
      'ref',
      'global;' + startDate + ';' + endDate
    );
    return this.http
      .get<any>(environment.apiUrl + '/challenge_results', {
        params: searchParams,
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          return responseData._embedded.challenge_results;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  saveWalkingSpeed(speed) {
    if (!this.userId) {
      return;
    }

    const postData = {
      speed,
    };
    return this.http.post<any>(
      environment.apiUrl + '/devices/save-walking-speed',
      postData,
      {
        observe: 'response',
      }
    );
  }

  getStepsRag(averageSteps: number) {
    let rag = '';

    if (averageSteps < 5000) {
      rag = 'red';
    } else if (averageSteps >= 5000 && averageSteps < 7500) {
      rag = 'amber';
    } else {
      rag = 'green';
    }

    return rag;
  }

  getSittingRag(minutes: number, device: string) {
    let rag = '';

    if (device == 'fitbit') {
      if (minutes >= 10 * 60) {
        rag = 'red';
      } else if (minutes >= 9 * 60 && minutes < 10 * 60) {
        rag = 'amber';
      } else {
        rag = 'green';
      }
    }

    if (device != 'fitbit') {
      if (minutes >= 8 * 60) {
        rag = 'red';
      } else if (minutes >= 6 * 60 && minutes < 8 * 60) {
        rag = 'amber';
      } else {
        rag = 'green';
      }
    }

    return rag;
  }

  getSittingAverage(_allActivities) {
    if (!_allActivities) {
      return 0;
    }
    let totalSits = 0;
    let totalSitMins = 0;
    let latestSittings = [];

    // trim dead weight?
    let allActivities:any = [];
    _allActivities.forEach((activity) => {
      if(activity.sedentary_minutes > 0 && +activity.sedentary_minutes < 960){
        allActivities.push(activity);
      }
    });


    allActivities.forEach((activity) => {
      if (
        moment(activity.activity_date).format('YYYY-MM-DD') >=
          moment().subtract(6, 'days').format('YYYY-MM-DD') &&
        +activity.sedentary_minutes > 0 && +activity.sedentary_minutes < 960
      ) {
        totalSits++;
        totalSitMins += +activity.sedentary_minutes;
        latestSittings.push(activity);
      }
    });
    if (totalSits > 0) {
      return totalSitMins / totalSits;
    } else {
      // get the latest
      totalSits = 0;
      totalSitMins = 0;
      if (allActivities[0]) {

        let startDate = allActivities[0].activity_date;
        allActivities.forEach((activity) => {
          if (
            moment(activity.activity_date).format('YYYY-MM-DD') >=
              moment(startDate).subtract(6, 'days').format('YYYY-MM-DD') &&
            +activity.sedentary_minutes > 0 && +activity.sedentary_minutes < 960
          ) {
            totalSits++;
            totalSitMins += +activity.sedentary_minutes;
            latestSittings.push(activity);
          }
        });
      }
      if (totalSits > 0) {
        return totalSitMins / totalSits;
      }
      else{

      return 0;
      }
    }
  }

  getStepAverage(allActivities) {
    let totalStepsForAvg = 0;
    let totalStepsForAvgCount = 0;
    let averageSteps = 0;
    allActivities.forEach((activity) => {
      if (
        moment(activity.activity_date).format('YYYY-MM-DD') >=
          moment().subtract(6, 'days').format('YYYY-MM-DD') &&
        +activity.steps > 0
      ) {
        totalStepsForAvg += +activity.steps;
        totalStepsForAvgCount++;
      }
    });

    if (totalStepsForAvgCount > 0) {
      averageSteps = Math.round(totalStepsForAvg / totalStepsForAvgCount);
    }
    /*
    else{

      // no total so go to last available week?

      // get the latest
      totalStepsForAvgCount = 0;
      totalStepsForAvg = 0;
      if (allActivities[0]) {
        let startDate = allActivities[0].activity_date;

        allActivities.forEach((activity) => {
          if (
            moment(activity.activity_date).format('YYYY-MM-DD') >=
            moment(startDate).subtract(6, 'days').format('YYYY-MM-DD')
          ) {
            totalStepsForAvgCount++;
            totalStepsForAvg += +activity.steps;
          }
        });
      }
      if (totalStepsForAvgCount > 0) {
        return  averageSteps = Math.round(totalStepsForAvg / totalStepsForAvgCount);
      }

      return 0;
    }*/

    return averageSteps;
  }

  getWeeklySittingAverages(activities) {
    let weeklyData: any = {};

    activities.forEach((activity, index) => {
      if (
        !weeklyData[
          moment(activity.activity_date).startOf('isoWeek').format('YYYY-MM-DD')
        ]
      ) {
        weeklyData[
          moment(activity.activity_date).startOf('isoWeek').format('YYYY-MM-DD')
        ] = [];
      }
      weeklyData[
        moment(activity.activity_date).startOf('isoWeek').format('YYYY-MM-DD')
      ].push(activity);
    });

    let weeklyAverages = [];

    for (var key in weeklyData) {
      if (weeklyData.hasOwnProperty(key)) {
        // data
        weeklyAverages[key] = 0;
        let weekActivity = 0;
        let weekActivitys = 0;
        weeklyData[key].forEach((activity, acIndex) => {
          weekActivity += +activity.sedentary_minutes;
          weekActivitys++;
        });
        weeklyAverages[key] = weekActivity / weekActivitys;
      }
    }

    return weeklyAverages;
  }

  getWeeklyStepAverages(activities, mode = 'total') {
    let weeklyData: any = {};

    activities.forEach((activity, index) => {
      if (
        !weeklyData[
          moment(activity.activity_date).startOf('isoWeek').format('YYYY-MM-DD')
        ]
      ) {
        weeklyData[
          moment(activity.activity_date).startOf('isoWeek').format('YYYY-MM-DD')
        ] = [];
      }
      weeklyData[
        moment(activity.activity_date).startOf('isoWeek').format('YYYY-MM-DD')
      ].push(activity);
    });

    let weeklyAverages = [];

    for (var key in weeklyData) {
      if (weeklyData.hasOwnProperty(key)) {
        // data
        weeklyAverages[key] = 0;
        let weekActivity = 0;
        let weekActivitys = 0;
        weeklyData[key].forEach((activity, acIndex) => {
          if (mode == 'total') {
            if (+activity.steps) {
              weekActivity += +activity.steps;
              weekActivitys++;
            }
          }

          if (mode == 'light') {
            if (+activity.steps) {
              weekActivity += +activity.light_steps;
              weekActivitys++;
            }
          }

          if (mode == 'brisk') {
            if (+activity.steps) {
              weekActivity +=
                +activity.moderate_steps + +activity.vigorous_steps;
              weekActivitys++;
            }
          }
        });
        if (weekActivitys > 0) {
          weeklyAverages[key] = Math.round(weekActivity / weekActivitys);
        } else {
          weeklyAverages[key] = 0;
        }
      }
    }

    return weeklyAverages;
  }

  getChangeInSteps(allSteps) {
    let pastWeekAverage = 0;
    let pastPastWeekAverage = 0;
    let change = 0;

    let pastWeekTotal = 0;
    let pastWeekDays = 0;

    let pastPastWeekTotal = 0;
    let pastPastWeekDays = 0;

    allSteps.forEach((activity) => {
      // past week
      if (
        moment(activity.activity_date).format('YYYY-MM-DD') >=
          moment().subtract(8, 'day').format('YYYY-MM-DD') &&
        moment(activity.activity_date).format('YYYY-MM-DD') <
          moment().format('YYYY-MM-DD')
      ) {
        pastWeekTotal += +activity.steps;
        pastWeekDays++;
      }

      // past past week
      if (
        moment(activity.activity_date).format('YYYY-MM-DD') >=
          moment().subtract(15, 'day').format('YYYY-MM-DD') &&
        moment(activity.activity_date).format('YYYY-MM-DD') <
          moment().subtract(8, 'day').format('YYYY-MM-DD')
      ) {
        pastPastWeekTotal += +activity.steps;
        pastPastWeekDays++;
      }
    });

    if (pastWeekTotal > 0 && pastPastWeekTotal > 0) {
      pastWeekAverage = pastWeekTotal / pastWeekDays;
      pastPastWeekAverage = pastPastWeekTotal / pastPastWeekDays;

      change = Math.round(pastWeekAverage - pastPastWeekAverage);
    }

    return change;
  }

  clearCache() {
    this.userActivity = null;
  }
}
