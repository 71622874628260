import { DeviceService } from './../services/device.service';
import { ProgrammeService } from './../services/programme.service';
import { UserService } from './../services/user.service';
import { AuthService } from './../auth/auth.service';
import { ActivityService } from './../services/activity.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-device',
  templateUrl: './device.component.html',
  styleUrls: ['./device.component.scss'],
})
export class DeviceComponent implements OnInit {
  currentUser;
  currentProgramme;
  isFetching;
  errorCode;
  responseData;
  environment = environment;

  constructor(
    private activityService: ActivityService,
    private authService: AuthService,
    private userService: UserService,
    private programmeService: ProgrammeService,
    private deviceService: DeviceService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    // are we logged in?
    const user = this.authService.user.getValue();
    if (user) {
      this.programmeService.currentProgramme.subscribe((programmeData) => {
        this.currentProgramme = programmeData;
      });
      this.userService.userData.subscribe((userDataResponse) => {
        this.currentUser = userDataResponse;
      });

      this.deviceService.checkConnection().subscribe(
        (responseData) => {
          this.responseData = responseData;
        },
        (error) => {
          console.log(error);
        }
      );
    }

    // any error code returned?
    this.route.params.subscribe((params) => {
      this.errorCode = this.route.snapshot.params.errorCode;
    });

  }

  disconnect() {
    this.isFetching = true;
    this.deviceService.disconnect().subscribe(
      (responseData) => {
        this.userService.reset();
        this.userService.get().subscribe((userData) => {
          this.currentUser = userData;
          this.deviceService.checkConnection().subscribe(
            (deviceResponseData) => {
              this.responseData = deviceResponseData;
              this.isFetching = false;
            },
            (error) => {
              console.log(error);
            }
          );
        });
      },
      (error) => {
        console.log(error);
      }
    );
  }

  setManual() {
    this.deviceService.setManual().subscribe(
      (responseData) => {
        this.userService.reset();
        this.userService.get().subscribe((userData) => {
          this.currentUser = userData;
        });
      },
      (error) => {
        console.log(error);
      }
    );
  }
}
