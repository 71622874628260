// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  title: 'MyDesmond',
  production: false,
  cookieDomain: 'localhost',
  apiUrl: 'https://mydesmondapi.hark2dev.com',
  assetUrl: 'https://mydesmondapi.hark2dev.com',
  applicationUrl : 'https://mydesmond.hark2dev.com',
  apiSubPath: '/api',
  testSite: true,
  country: 'UK',
  timezone: 'Europe/London',
  analyticsCode: '',
  currencySymbol: '£',
  navLabels: {
    askTheExpert: 'Ask a Health Professional',
    chat: 'Forum',
  },
  translations: {
    calorie: 'kilojoule',
  },
  trelloToken: '',
  defaultProgramme: 'MyDesmond',
  primaryColor: '#206ba4',
  chartDefaults: {
    colors: ['#206ba4'],
    fontFamily:  "'Oswald', sans-serif",
    fontSize: 14,
    fontColor: '#333',
    fontStyle: '100'
  },
  googleMapsAPIKey: 'AIzaSyCDMiefbhPiSmEGNn0TN6uf66ixCODqdBU',
  vapidKeys: {
    publicKey:
      'BLVm7PDZO36gFs9Htp0WrY7Rb9vmAycNFzKFdKYciNwbK5pyKP3j0dLZl7tHAiWLYHuFEwnTEdj_kS9pt4zKcbw',
    privateKey: 'Lg1JdtWU9Vz4V0yF41j63fy-Aw3tNiFngmriqD2kYc4',
  },
  walkingSpeedSessionId: 190,
  walkingSpeedStepId: 100,
  
  connectGoogleFitUrl: ['/','educational-reading', 'section', '24'],
  ragColors:{
    red:'#d9534f',
    amber:'#f0ad4e',
    green:'#5cb85c'
  },
  ragColorsDark: {
    red: '#ab302c',
    amber: '#bf8532',
    green: '#3a8a3a',
  },
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
