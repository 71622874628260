import { UserService } from './../services/user.service';
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { Router } from '@angular/router';
import { catchError, tap, take, exhaustMap, map } from 'rxjs/operators';
import { throwError, BehaviorSubject, Subject, of } from 'rxjs';

import { User } from './user.model';
import { CookieService } from 'ngx-cookie-service';
import { environment } from './../../environments/environment';
import * as moment from 'moment';

export interface AuthResponseData {
  kind: string;
  user: {
    email: string;
    first_name: string;
    last_name: string;
    id: string;
    app_token: string;
    user: {};
    api_token: { access_token: string; expires_in: number };
    avatar: string;
    language_id: string;
    week_start: string;
    current_week: string;
    username: string;
    created: string;
  };
  id: string;
}

@Injectable({ providedIn: 'root' })
export class AuthService {
  user = new BehaviorSubject<User>(null);
  public apiToken: any = '';
  private tokenExpirationTimer: any;
  public redirect: string;
  public fetching = new BehaviorSubject<any>(false);

  constructor(
    private http: HttpClient,
    private router: Router,
    private cookieService: CookieService,
    private userService: UserService
  ) {}

  login(email: string, password: string, rememberMe: any) {
    return this.http
      .post<AuthResponseData>(environment.apiUrl + '/auth', {
        email,
        password,
        rememberMe,
      })
      .pipe(
        catchError(this.handleError),
        tap((resData) => {
          this.handleAuthentication(
            resData.user.email,
            resData.user.id,
            resData.user.app_token,
            resData.user,
            resData.user.api_token.access_token,
            resData.user.api_token.expires_in
          );

          // are we remebering it?
          if (rememberMe) {
            // this.autoLogout(expiresIn * 1000);
            const now = new Date();
            now.setMonth(now.getMonth() + 1);
            const exp = new Date(now);

            // store in cookies
            this.cookieService.set(
              'appToken',
              resData.user.app_token,
              exp,
              '/'
            );
            this.cookieService.set('appEmail', resData.user.email, exp, '/');
          }
        })
      );
  }

  forgottenPassword(email: string) {
    // use params as posting to form
    const payload = new HttpParams().set('email', email);

    return this.http
      .post<any>(environment.apiUrl + '/forgotten-password', payload)
      .pipe(catchError(this.handleError));
  }

  resetPassword(
    id: number,
    hash: string,
    password: string,
    passwordConfirm: string
  ) {
    // use params as posting to form
    const payload = new HttpParams()
      .set('password', password)
      .set('password_confirm', passwordConfirm);

    return this.http
      .post<any>(
        environment.apiUrl + '/reset_password/' + id + '/' + hash,
        payload
      )
      .pipe(catchError(this.handleError));
  }

  changePassword(
    current_password: string,
    new_password: string
  ) {
    const payload = {
      current_password: current_password,
      new_password: new_password
    };

    return this.http
      .post<any>(environment.apiUrl + '/change_password', payload)
      .pipe(catchError(this.handleError));
  }

  checkCode(code: string) {
    // use params as posting to form
    const payload = {
      code,
    };

    return this.http
      .post<AuthResponseData>(environment.apiUrl + '/check_code', payload)
      .pipe(tap((resData) => {}));
  }
  checkCodeBuddy(code: string) {
    // use params as posting to form
    const payload = new HttpParams().set('code', code);

    return this.http
      .post<AuthResponseData>(environment.apiUrl + '/check-buddy-code', payload)
      .pipe(
        catchError(this.handleError),
        tap((resData) => {})
      );
  }

  private checkNull(value) {
    if (value == null) {
      return '';
    } else {
      return value;
    }
  }
  register(regData) {
    /* let payload = new HttpParams();
    let payloadStr = '';
    if (!regData['ethnicity_id']) {
      regData['ethnicity_id'] = 0;
    }
    for (let key in regData) {
      let value = regData[key];
      if (key === 'dob') {
        value = moment(value).format('YYYY-MM-DD');
      }
      if (key === 'terms') {
        if (value) {
          value = '1';
        } else {
          value = '0';
        }
      }
      if (key === 'agree_marketing') {
        if (value) {
          value = '1';
        } else {
          value = '0';
        }
      }

      payload = payload.set(key, this.checkNull(value));
      payloadStr += key + '=' + encodeURIComponent(this.checkNull(value)) + '&';
    }

    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });*/

    if (!regData['ethnicity_id']) {
      regData['ethnicity_id'] = 0;
    }
    for (let key in regData) {
      let value = regData[key];
      if (key === 'dob') {
        value = moment(value).format('YYYY-MM-DD');
      }
      if (key === 'terms') {
        if (value) {
          value = '1';
        } else {
          value = '0';
        }
      }
      if (key === 'agree_marketing') {
        if (value) {
          value = '1';
        } else {
          value = '0';
        }
      }
    }

    return this.http
      .post<any>(environment.apiUrl + '/register', regData, {})
      .pipe(
        catchError(this.handleError),
        tap((resData) => {
          //  console.log(resData);
          /*this.handleAuthentication(
            resData.user.email,
            resData.user.id,
            resData.user.app_token,
            resData.user
            // resData.user.api_token.access_token,
            // resData.user.api_token.expires_in
          );*/

          this.handleAuthentication(
            resData.user.email,
            resData.user.id,
            resData.user.app_token,
            resData.user,
            resData.user.api_token.access_token,
            resData.user.api_token.expires_in
          );

          this.userService.get().subscribe((userdata) => {});
        })
      );
  }

  setupProgramme(regData) {
    let payload = new HttpParams();
    let payloadStr = '';

    for (let key in regData) {
      let value = regData[key];
      payload = payload.set(key, this.checkNull(value));
      // payloadStr += key + '=' + encodeURIComponent(this.checkNull(value)) + '&';
    }
    return this.http
      .post<AuthResponseData>(
        environment.apiUrl + '/setup_programme_ep',
        regData,
        {}
      )
      .pipe(
        catchError(this.handleError),
        tap((resData) => {})
      );
  }

  setupProgrammeGu1de(regData) {
    let payload = new HttpParams();
    let payloadStr = '';

    for (let key in regData) {
      let value = regData[key];
      payload = payload.set(key, this.checkNull(value));
      // payloadStr += key + '=' + encodeURIComponent(this.checkNull(value)) + '&';
    }
    return this.http
      .post<AuthResponseData>(
        environment.apiUrl + '/setup_programme_gu1de',
        regData,
        {}
      )
      .pipe(
        catchError(this.handleError),
        tap((resData) => {})
      );
  }
  registerBuddy(regData) {
    let payload = new HttpParams();
    let payloadStr = '';
    if (!regData['ethnicity_id']) {
      regData['ethnicity_id'] = 0;
    }
    for (let key in regData) {
      let value = regData[key];
      if (key === 'dob') {
        value = moment(value).format('YYYY-MM-DD');
      }
      if (key === 'terms') {
        if (value) {
          value = '1';
        } else {
          value = '0';
        }
      }
      payload = payload.set(key, this.checkNull(value));
      payloadStr += key + '=' + encodeURIComponent(this.checkNull(value)) + '&';
    }

    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    return this.http
      .post<AuthResponseData>(
        environment.apiUrl + '/register-buddy',
        payloadStr,
        { headers }
      )
      .pipe(
        catchError(this.handleError),
        tap((resData) => {
          // console.log(resData);
          /*
          this.handleAuthentication(
            resData.user.email,
            resData.user.user_id,
            resData.user.app_token,
            resData.user
            // resData.user.api_token.access_token,
            // resData.user.api_token.expires_in
          );*/
        })
      );
  }

  updateDetails(
    email: string,
    firstName: string,
    lastName: string,
    screenName: string
  ) {
    // use params as posting to form
    const payload = new HttpParams()
      .set('email', email)
      .set('first_name', firstName)
      .set('last_name', lastName)
      .set('screen_name', screenName);

    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    const payloadStr =
      'email=' +
      encodeURIComponent(email) +
      '&first_name=' +
      encodeURIComponent(firstName) +
      '&last_name=' +
      encodeURIComponent(lastName) +
      '&screen_name=' +
      encodeURIComponent(screenName);

    return this.http
      .post<any>(environment.apiUrl + '/auth/update-details', payloadStr, {
        headers,
      })
      .pipe(
        catchError(this.handleError),
        tap((resData) => {
          this.userService.reset();
          this.userService.get().subscribe((userdata) => {
            return userdata;
          });
        })
      );
  }

  updatePersonalDetails(
    first_name,
    last_name,
    mobile,
    dob,
    ethnicity_id,
    post_code,
    gender,
    screen_name,
    height,
    origin,
    birth_country,
    language
  ) {
    // use params as posting to form
    const payload = {
      first_name: first_name,
      last_name: last_name,
      mobile: mobile,
      dob: dob,
      ethnicity_id: ethnicity_id,
      post_code: post_code,
      gender: gender,
      screen_name: screen_name,
      height: height,
      origin: origin,
      birth_country: birth_country,
      language: language,
    };

    return this.http
      .post<any>(environment.apiUrl + '/user_details', payload)
      .pipe(
        catchError(this.handleError),
        tap((resData) => {
          this.userService.reset();
          this.userService.get().subscribe((userdata) => {
            return userdata;
          });
        })
      );
  }

  updateAccountDetails(notifications, notification_settings, email?) {
    // use params as posting to form
    const payload = { notifications, notification_settings, email };

    return this.http
      .post<any>(environment.apiUrl + '/account_details', payload)
      .pipe(
        catchError(this.handleError),
        tap((resData) => {
          this.userService.reset();
          this.userService.get().subscribe((userdata) => {
            return userdata;
          });
        })
      );
  }

  agreeTerms() {
    // use params as posting to form
    const payload = new HttpParams().set('apply', '1');

    return this.http
      .post<any>(environment.apiUrl + '/agree-terms', payload)
      .pipe(
        catchError(this.handleError),
        tap((resData) => {
          this.userService.reset();
          this.userService.get().subscribe((userdata) => {
            return userdata;
          });
        })
      );
  }

  checkScreenName(screenName) {
    // use params as posting to form
    const payload = { screenName };

    return this.http
      .post<any>(environment.apiUrl + '/check_screen_name', payload)
      .pipe(
        tap((resData) => {
          return resData;
        })
      );
  }

  autoLogin() {
    this.fetching.next(true);
    const appEmail = this.cookieService.get('appEmail');
    const appToken = this.cookieService.get('appToken');
    const apiToken = this.cookieService.get('apiToken');

    if (!apiToken) {
      // this.fetching.next(false);
      return;
    }

    // use params as posting to form

    return this.http
      .post<AuthResponseData>(environment.apiUrl + '/restore', {})
      .pipe(
        catchError(this.handleError),
        tap(
          (resData) => {
            // console.log(resData);
            this.handleAuthentication(
              resData.user.email,
              resData.user.id,
              resData.user.app_token,
              resData.user,
              resData.user.api_token.access_token,
              resData.user.api_token.expires_in
            );
            this.fetching.next(false);
            this.userService.get().subscribe((userdata) => {});
          },
          (error) => {
            this.fetching.next(false);
          }
        )
      );
  }
  /*
  getAccessToken() {
    this.user.pipe(
      take(1),
      exhaustMap((user) => {
        if (user) {
          return user;
         // return user.token;
        }
      })
    );
    return null;
  }*/

  refreshAccessToken() {
    const appToken = this.cookieService.get('appToken');
    // use params as posting to form
    const payload = new HttpParams()
      .set('token', appToken)
      .set('returnSecureToken', 'true');

    return this.http.post<any>(environment.apiUrl + '/refresh', payload).pipe(
      catchError(this.handleRefresh401),
      tap((resData) => {
        /* this.handleAuthentication(
          resData.user.email,
          resData.user.user_id,
          resData.user.app_token,
          resData.user
          // resData.user.api_token.access_token,
          // resData.user.api_token.expires_in
        );*/

        this.userService.get().subscribe((userdata) => {});
      })
    );
  }
  handleRefresh401(errorRes: HttpErrorResponse) {
    const errorMessage = 'An unknown error occurred!';
    return throwError(errorMessage);
  }

  logout(): any {
    this.http
      .get<any>(environment.apiUrl + '/logout', {
        responseType: 'json',
      })
      .subscribe(
        (response) => {
          this.cookieService.delete('appToken', '/');
          this.cookieService.delete('apiToken', '/');
          this.cookieService.delete('appEmail', '/');
          this.user.next(null);
          this.userService.userData.next(null);
          this.userService.reset();
          this.router.navigate(['/login']);
          return false;
        },
        (error) => {
          return error;
        }
      );
  }

  autoLogout(expirationDuration: number) {
    this.tokenExpirationTimer = setTimeout(() => {
      this.logout();
    }, expirationDuration);
  }

  checkNdss() {
    return this.http.get<any>(environment.apiUrl + '/check-ndss').pipe(
      tap((resData) => {
        return resData;
      })
    );
  }

  checkExerciseProgrameRegistration(code) {
    return this.http
      .get<any>(environment.apiUrl + '/check_exercise_programme/' + code)
      .pipe(
        tap((resData) => {
          return resData;
        })
      );
  }

  continueAccess() {
    // use params as posting to form
    const payload = new HttpParams().set('apply', '1');

    return this.http
      .post<any>(environment.apiUrl + '/continue-access', payload)
      .pipe(
        catchError(this.handleError),
        tap((resData) => {
          this.userService.reset();
          this.userService.get().subscribe((userdata) => {
            return userdata;
          });
        })
      );
  }

  private handleAuthentication(
    email: string,
    userId: string,
    token: string,
    userData: {
      first_name: any;
      last_name: any;
      avatar: any;
      username: any;
      created: any;
    },
    api_token: string,
    expiresIn: any
  ) {
    const expirationDate = new Date(new Date().getTime() + expiresIn * 1000);

    // api token

    if (api_token) {
      const now = new Date();
      now.setMonth(now.getMonth() + 1);
      const exp = now.setMonth(now.getMonth() + 1);

      this.cookieService.set('apiToken', api_token, exp, '/');

      this.apiToken = api_token;
    }

    /*const user = new User(
      email,
      userId,
      userData.first_name,
      userData.last_name,
      token,
      api_token,
      userData.avatar,
    );*/
    const user = new User(
      email,
      userId,
      userData.first_name,
      userData.last_name,
      userData.created,
      token,
      api_token,
      expiresIn,
      userData.avatar
    );
    if (!api_token) {
    }
    user.setToken(api_token);
    this.user.next(user);
    this.userService.get().subscribe((userdata) => {});
  }

  private handleError(errorRes: HttpErrorResponse) {
    // console.log(errorRes);
    // console.log(errorRes);
    let errorMessage =
      'An unknown error occurred.  If the problem persists please contact us.';
    if (!errorRes.statusText) {
      return throwError(errorMessage);
    }
    switch (errorRes.statusText) {
      case 'EMAIL_EXISTS':
        errorMessage = 'This email exists already';
        break;
      case 'EMAIL_NOT_FOUND':
        errorMessage = 'This email does not exist.';
        break;
      case 'INVALID_PASSWORD':
        errorMessage = 'This password is not correct.';
        break;
      case 'INVALID_CREDENTIALS':
        errorMessage = 'The email or password is incorrect.';
        break;
      case 'INVALID_REGISTRATION_DATA':
        errorMessage = 'Invalid registration data provided.';
        errorMessage += errorRes.error;
        break;
      case 'INVALID_DATA':
        errorMessage = errorRes.error;
        break;
      case 'ACTION_NOT_ALLOWED':
        errorMessage = errorRes.error;
        break;
      case 'ACCOUNT_EXPIRED':
        errorMessage = 'Your account has expired and no longer has access.';
        break;
    }
    return throwError(errorMessage);
  }

  saveWebPush(webPushConfig) {
    const postData = {
      webPushConfig,
    };
    return this.http.post<any>(
      environment.apiUrl + '/save-web-push',
      postData,
      {
        observe: 'response',
      }
    );
  }

  fetchHealthConditions() {
    return this.http
      .get<any>(environment.apiUrl + '/health_conditions', {
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          return responseData._embedded.health_conditions;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }
}
