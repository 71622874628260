import { environment } from './../../environments/environment';
import { AuthService } from './../auth/auth.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserInviteService {
  userId;
  endPoint = '/user_invites';

  constructor(private http: HttpClient, private authService: AuthService) {
    this.authService.user.subscribe((user) => {
      if (user) {
        this.userId = user.id;
      }
    });
  }

  fetchAll() {
    if (!this.userId) {
      return;
    }
    let searchParams = new HttpParams();

    searchParams = searchParams.append('user_id', this.userId);
    return this.http
      .get<any>(environment.apiUrl + this.endPoint, {
        params: searchParams,
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          return responseData._embedded.user_invites;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  fetchAsFriend() {
    if (!this.userId) {
      return;
    }
    let searchParams = new HttpParams();

    searchParams = searchParams.append('friend_id', this.userId);
    return this.http
      .get<any>(environment.apiUrl + this.endPoint, {
        params: searchParams,
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          return responseData._embedded.user_invites;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  fetch(id: number) {
    return this.http
      .get<any>(environment.apiUrl + this.endPoint + '/' + id, {
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          return responseData;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  create(programmeId, email) {
    const postData = {
      programme_id: programmeId,
      email,
    };

    const payload = new HttpParams()
      .set('programme_id', '' + programmeId)
      .set('email', email);

    return this.http
      .post<any>(environment.apiUrl + '/send-invite', payload)
      .pipe(
        map((responseData) => {
          return responseData;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  update(id, diastolic, systolic) {
    const postData = {
      diastolic,
      systolic,
    };
    return this.http.patch<{ name: string }>(
      environment.apiUrl + this.endPoint + '/' + id,
      postData,
      {
        observe: 'response',
      }
    );
  }

  delete(id) {
    return this.http.delete<{ name: string }>(
      environment.apiUrl + this.endPoint + '/' + id
    );
  }
}
