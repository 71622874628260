import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpParams,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import {
  take,
  exhaustMap,
  tap,
  catchError,
  filter,
  switchMap,
} from 'rxjs/operators';

import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { CacheManagerService } from '../services/cache-manager.service';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root',
})
export class AuthRefreshInterceptorService implements HttpInterceptor {
  private refreshTokenInProgress = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  constructor(
    private authService: AuthService,
    private router: Router,
    private cacheManagerService: CacheManagerService,
    private cookieService: CookieService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status == 401) {
          // refresh the token
          this.cookieService.delete('apiToken', '/');

          //TODO CHANGE FROM COOKIES
          // let bearer = user.getToken();

          let modifiedReq = request.clone({
            setHeaders: {
              Authorization: '',
            },
            //headers: new HttpHeaders().set('Authorization', 'Bearer ' + user.token),
          });

          return next.handle(modifiedReq);

          // TODO Use refresh token to get a new api token
        }
        // this is the API's idosyncratic way of telling us a session has expired...
        if (error.url === environment.apiUrl + '/app/login') {
          // if (request.url.includes('login')) {
          this.authService.autoLogin().subscribe(
            (resData) => {
              if (this.router.url.split('?')[0] === '/login') {
                if (this.authService.redirect) {
                  this.router.navigateByUrl(this.authService.redirect);
                } else {
                  this.router.navigate(['/dashboard']);
                }
              }
            },
            (errorMessage) => {
              this.authService.logout();
              this.cacheManagerService.clearAllCache();
            }
          );
          return throwError(error);
        } else {
          return throwError(error);
        }
        // }
      })
    );
  }
}
