<nav class="subnav">
  <a
    [routerLink]="['/friends-and-family']"
    aria-label="Go to buddies"
    class="subnav-prev"
    ><span class="icon-wrap"
      ><fa-icon [icon]="['fas', 'chevron-circle-left']"></fa-icon></span
  ></a>
  <h2>
    <span class="icon-wrap"
      ><fa-icon [icon]="['fas', 'user-friends']"></fa-icon
    ></span>
    {{ challengeType }} CHALLENGE
  </h2>
</nav>
<div *ngIf="isFetching">
  <app-loading-spinner [inline]="1"></app-loading-spinner>
</div>

<div *ngIf="currentProgramme && currentUser">
  <div class="sub-sub-header">
    {{ startDate | dateFormatter: "DD/MM/yyyy" }} - {{ endDate | dateFormatter: "DD/MM/yyyy" }}
  </div>
  <div class="container">
    <!-- Nav tabs -->
    <ul class="nav nav-tabs nav-tabs-duo justify-content-center" role="tablist">
      <li role="presentation" [ngClass]="{ active: showPercent }">
        <a
          aria-controls="percent"
          role="tab"
          data-toggle="tab"
          (click)="$event.preventDefault(); showPercent = true"
          >% OF DAILY TARGET (AVERAGE)
        </a>
      </li>
      <li role="presentation" [ngClass]="{ active: !showPercent }">
        <a
          aria-controls="step-count"
          role="tab"
          data-toggle="tab"
          (click)="$event.preventDefault(); showPercent = false"
          >STEP COUNT</a
        >
      </li>
    </ul>

    <!-- Tab panes -->
    <div class="tab-content">
      <div role="tabpanel" id="percent" *ngIf="showPercent">
        <table class="table table-leaderboard">
          <thead>
            <tr>
              <th colspan="4">LEADERBOARD</th>
            </tr>
          </thead>
          <tbody *ngIf="challengeActivity">
            <ng-container
              *ngFor="
                let teamActivityItem of sortActivity(
                  challengeActivity,
                  'goal_avg',
                  true
                );
                let index = index
              "
            >
              <tr
                [ngClass]="{
                  me: teamActivityItem.user_id == currentUser.user_id
                }"
                *ngIf="
                  (index < 3 || showWeeksFull) &&
                  teamActivityItem.step_count > 0
                "
              >
                <td class="num">{{ index + 1 }}</td>
                <td class="avi">
                  <img
                    [src]="
                      (teamActivityItem.avatar !== null &&
                      environment.assetUrl +  '/assets/user/' + teamActivityItem.avatar) ||
                      'assets/img/default-avatar.png'
                    "
                    class="img-circle img-avatar"
                    width="30"
                    height="30"
                  />
                </td>
                <td width="50%">{{ teamActivityItem.screen_name }}</td>
                <td class="text-right">{{ teamActivityItem.goal_avg }}%</td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>

      <div role="tabpanel" id="step-count" *ngIf="!showPercent">
        <table class="table table-leaderboard">
          <thead>
            <tr>
              <th colspan="4">LEADERBOARD</th>
            </tr>
          </thead>
          <tbody *ngIf="challengeActivity">
            <ng-container
              *ngFor="
                let teamActivityItem of sortActivity(
                  challengeActivity,
                  'step_count',
                  true
                );
                let index = index
              "
            >
              <tr
                [ngClass]="{
                  me: teamActivityItem.user_id == currentUser.user_id
                }"
                *ngIf="
                  (index < 3 || showWeeksFull) &&
                  teamActivityItem.step_count > 0
                "
              >
                <td class="num">{{ index + 1 }}</td>
                <td class="avi">
                  <img
                    [src]="
                      (teamActivityItem.avatar !== null &&
                      environment.assetUrl + '/assets/user/' + teamActivityItem.avatar) ||
                      './assets/img/default-avatar.png'
                    "
                    class="img-circle img-avatar"
                    width="30"
                    height="30"
                  />
                </td>
                <td width="50%">{{ teamActivityItem.screen_name }}</td>
                <td class="text-right">
                  {{ teamActivityItem.step_count}}
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
    <ul class="comments">
      <li *ngFor="let comment of comments">
        <span class="avatar"
          ><img
            [src]="
              (comment.avatar !== null &&  environment.assetUrl +'/assets/user/' + comment.avatar) ||
              './assets/img/default-avatar.png'
            "
            class="img-circle img-avatar"
            width="30"
            height="30"
        /></span>
        <div class="comment">
          <strong> {{ comment.screen_name }}</strong> {{ comment.comment }}
        </div>
        <span class="comment-date"
          ><span timeago [date]="comment.created | dateFormatter : ''" [live]="true"></span> |
          <span class="icon-wrap"
      ><fa-icon [icon]="['fas', 'thumbs-up']"></fa-icon></span
  > {{ comment.likes }}</span
        >
        <span
          class="comment-like"
          *ngIf="commentLikes &&
            !commentLikes[comment.comment_id] &&
            comment.user_id != currentUser.user_id
          "
          ><a (click)="$event.preventDefault();likeComment(comment)" href="#"
            ><span class="icon-wrap"
            ><fa-icon [icon]="['fas', 'thumbs-up']"></fa-icon></span
        > Like</a
          ></span
        >
      </li>
    </ul>
    <form
      class="text-center comment-form"
      name="commentForm"
      method="post"
      (ngSubmit)="onSubmit()"
      #commentForm="ngForm"
    >
      <div class="form-group">
        <textarea
          rows="2"
          autosize
          type="text"
          class="form-control"
          #comment="ngModel"
          placeholder="Write a new message…"
          id="comment"
          name="comment"
          ngModel
          autocomplete="off"
          required
          aria-label="Enter a comment"
          maxlength="5000"
          style="min-height: 90px; resize: none"
        ></textarea>
        <div
          class="alert alert-danger"
          *ngIf="comment.touched && comment.invalid"
        >
          Please enter a comment in the text area above, maximum 5,000
          characters.
        </div>
      </div>
      <button
        class="btn btn-primary"
        [disabled]="!commentForm.valid || isDisabled"
        aria-label="Post comment"
      >
        <i class="fa fa-commenting"></i> POST COMMENT
      </button>
    </form>
  </div>
</div>
