<nav class="subnav">
  <a (click)="onBack()" aria-label="Go to forum" class="subnav-prev"
    ><span class="icon-wrap"
      ><fa-icon [icon]="['fas', 'chevron-circle-left']"></fa-icon></span
  ></a>
  <h2>
    <span class="icon-wrap"
      ><fa-icon [icon]="['fas', 'comment']"></fa-icon
    ></span>
    FORUM
  </h2>
</nav>
<div *ngIf="isFetching">
  <app-loading-spinner [inline]="1"></app-loading-spinner>
</div>

<div *ngIf="currentProgramme && currentUser && topic">
  <div *ngIf="topic.status == 2 || topic.status == 4">
    <div class="alert alert-warning" style="margin-top: 10px">
      <strong>Topic Closed</strong> This topic is now closed. You can view
      comments but you can no longer reply.
    </div>
  </div>
  <h2 class="forum-cat-title">
    {{ getCategory(topic) | uppercase }}
  </h2>

  <div class="container container-pad">
    <div class="forum-topic">
      <div class="text-left mb-10">
        <h3 *ngIf="topic.subject" class="topic-title">{{ topic.subject }}</h3>
        <img
          [src]="
            (topic.avatar !== null &&
              environment.assetUrl + '/assets/user/' + topic.avatar) ||
            'assets/img/default-avatar.png'
          "
          class="img-circle img-avatar"
          width="30"
          height="30"
        />
        {{ topic.screen_name }}
      </div>
      <div
        class="angular-with-newlines"
        [innerHtml]="topic.comment | linky"
      ></div>

      <div class="comments">
        <span class="comment-date">
          <span
            timeago
            [date]="topic.created | dateFormatter : ''"
            [live]="true"
          ></span>
          &nbsp;
          <a
            *ngIf="commentLikes[topic.comment_id]"
            href="#"
            (click)="
              unlikeComment(topic);
              $event.preventDefault();
              $event.stopPropagation()
            "
            ><span class="icon-wrap"
              ><fa-icon [icon]="['fas', 'thumbs-up']"></fa-icon
            ></span>
            {{ topic.likes }}</a
          >

          <span *ngIf="likeLoading.indexOf(topic.comment_id) > -1">
            <span class="icon-wrap"
              ><fa-icon
                [icon]="['fas', 'spinner']"
                [spin]="true"
              ></fa-icon></span
          ></span>
          <span *ngIf="likeLoading.indexOf(topic.comment_id) === -1">
            <a
              href="#"
              style="color: #333"
              (click)="
                likeComment(topic);
                $event.preventDefault();
                $event.stopPropagation()
              "
              *ngIf="
                !commentLikes[topic.comment_id] &&
                topic.user_id != currentUser.user_id
              "
              ><span class="icon-wrap"
                ><fa-icon [icon]="['fas', 'thumbs-up']"></fa-icon
              ></span>
              {{ topic.likes }}</a
            >
            <span
              *ngIf="
                !commentLikes[topic.comment_id] &&
                topic.user_id == currentUser.user_id
              "
              ><span class="icon-wrap"
                ><fa-icon [icon]="['fas', 'thumbs-up']"></fa-icon
              ></span>
              {{ topic.likes }}</span
            ></span
          > </span
        >|
        <a
          tooltip="Report comment"
          *ngIf="topic.status != 1"
          href="#"
          (click)="
            reportComment(topic);
            $event.preventDefault();
            $event.stopPropagation()
          "
          ><span class="icon-wrap"
            ><fa-icon [icon]="['fas', 'flag']"></fa-icon></span
        ></a>
        <span *ngIf="topic.status == 1" tooltip="Comment has been reported">
          <span class="icon-wrap"
            ><fa-icon [icon]="['fas', 'exclamation-triangle']"></fa-icon></span
        ></span>
      </div>
    </div>

    <hr class="divider" />
    <ul class="chat">
      <li
        *ngFor="let comment of comments"
        [ngClass]="{
          viewed:
            commentViews[comment.comment_id] &&
            this.fragment != 'comment-' + comment.comment_id
        }"
        id="comment-{{ comment.comment_id }}"
      >
        <span class="avatar"
          ><img
            [src]="
              (comment.avatar !== null &&
                environment.assetUrl + '/assets/user/' + comment.avatar) ||
              'assets/img/default-avatar.png'
            "
            class="img-circle img-avatar"
            width="30"
            height="30"
          />
          <span class="new-msg" *ngIf="!commentViews[comment.comment_id]"
            >New</span
          >
        </span>
        <div class="comment">
          <strong> {{ comment.screen_name }}</strong
          >&nbsp;
          <span
            *ngIf="comment.comment"
            [innerHtml]="comment.comment | linky"
          ></span>
        </div>
        <span class="comment-date">
          <span
            timeago
            [date]="comment.created | dateFormatter : ''"
            [live]="true"
          ></span>
          |
          <!--i class="fa fa-thumbs-up"></i> {{comment.likes}}</span>  <span class="comment-like" *ngIf="!commentLikes[comment.comment_id] &&  comment.user_id != currentUser.user_id"><a (click)="likeComment(comment)"><span class="icon-wrap"
      ><fa-icon [icon]="['fas', 'thumbs-up']"></fa-icon></span
  > Like</a></span> | <a *ngIf="comment.status!=1" href="#" (click)="reportComment(comment);$event.preventDefault();$event.stopPropagation();"><i class="fa fa-flag"></i></a><span *ngIf="comment.status==1"> <i class="fa fa-warning"></i-->
          <span *ngIf="likeLoading.indexOf(comment.comment_id) > -1">
            <span class="icon-wrap"
              ><fa-icon
                [icon]="['fas', 'spinner']"
                [spin]="true"
              ></fa-icon></span
          ></span>
          <span *ngIf="likeLoading.indexOf(comment.comment_id) === -1">
            <a
              *ngIf="commentLikes[comment.comment_id]"
              href="#"
              (click)="
                unlikeComment(comment);
                $event.preventDefault();
                $event.stopPropagation()
              "
              ><span class="icon-wrap"
                ><fa-icon [icon]="['fas', 'thumbs-up']"></fa-icon
              ></span>
              {{ comment.likes }}</a
            ><a
              href="#"
              style="color: #333"
              (click)="
                likeComment(comment);
                $event.preventDefault();
                $event.stopPropagation()
              "
              *ngIf="
                !commentLikes[comment.comment_id] &&
                comment.user_id != currentUser.user_id
              "
              ><span class="icon-wrap"
                ><fa-icon [icon]="['fas', 'thumbs-up']"></fa-icon
              ></span>
              {{ comment.likes }}</a
            >
            <span
              *ngIf="
                !commentLikes[comment.comment_id] &&
                comment.user_id == currentUser.user_id
              "
              ><span class="icon-wrap"
                ><fa-icon [icon]="['fas', 'thumbs-up']"></fa-icon
              ></span>
              {{ comment.likes }}</span
            ></span
          >
          |
          <a
            tooltip="Report comment"
            *ngIf="comment.status != 1"
            href="#"
            (click)="
              reportComment(comment);
              $event.preventDefault();
              $event.stopPropagation()
            "
            ><span class="icon-wrap"
              ><fa-icon [icon]="['fas', 'flag']"></fa-icon></span
          ></a>
          <span *ngIf="comment.status == 1" tooltip="Comment has been reported">
            <span class="icon-wrap"
              ><fa-icon
                [icon]="['fas', 'exclamation-triangle']"
              ></fa-icon></span
          ></span>
        </span>
      </li>
    </ul>
    <p *ngIf="comments.length == 0">There are no replies.</p>
    <hr class="divider" />
    <div *ngIf="topic.status != 2 && topic.status != 4">
      <form
        class="text-center comment-form"
        name="commentForm"
        method="post"
        (ngSubmit)="onSubmit()"
        #commentForm="ngForm"
      >
        <div class="form-group">
          <label class="sr-only" for="comment">Comment</label>
          <textarea
            rows="2"
            autosize
            type="text"
            class="form-control"
            #comment="ngModel"
            placeholder="Write a new reply..."
            id="comment"
            name="comment"
            ngModel
            autocomplete="off"
            required
            aria-label="Enter a comment"
            maxlength="5000"
            style="min-height: 90px; resize: none"
            noWhitespace
          ></textarea>

          <div
            class="alert alert-danger"
            *ngIf="comment.touched && comment.invalid"
          >
            Please enter a comment in the text area above, maximum 5,000
            characters.
          </div>
        </div>
        <button
          class="btn btn-primary"
          [disabled]="
            !commentForm.valid || isDisabled || checkEmpty(comment.value)
          "
          aria-label="Post comment"
          value="Post comment"
        >
          <span class="icon-wrap"
            ><fa-icon [icon]="['fas', 'comment-dots']"></fa-icon
          ></span>
          POST COMMENT
        </button>
      </form>
    </div>
  </div>
</div>
