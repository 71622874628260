import { AssessmentsService } from './../services/assessments.service';
import { CmsService } from './../services/cms.service';
import { ProgrammeService } from './../services/programme.service';
import { UserService } from './../services/user.service';
import { AuthService } from './../auth/auth.service';

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss'],
})
export class WelcomeComponent implements OnInit {
  currentUser;
  currentProgramme;
  cms;
  key = 'welcome';
  isFetching;

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private userService: UserService,
    private programmeService: ProgrammeService,
    private router: Router,
    private cmsService: CmsService,
    private assessmentsService: AssessmentsService
  ) {}

  ngOnInit(): void {
    // are we logged in?
    this.isFetching = true;
    const user = this.authService.user.getValue();
    if (user) {
      this.userService.userData.subscribe((userDataResponse) => {
        this.currentUser = userDataResponse;
      });
      this.programmeService.currentProgramme.subscribe((programmeData) => {
        this.currentProgramme = programmeData;

        if (this.currentProgramme.config.welcomePageContent) {
          this.cms = {};
          this.cms.item = {};
          this.cms.item.title = 'Welcome';
          this.cms.item.content =
            this.currentProgramme.config.welcomePageContent;

          this.isFetching = false;
        } else {
          // get existing
          this.cmsService.fetchByKey(this.key).subscribe((responseData) => {
            this.cms = {};
            this.cms.item = responseData['welcome'];
            this.isFetching = false;
          });
        }
      });
    }
  }

  goNext() {
    this.isFetching = true;
    console.log(this.currentProgramme.config);
    if (this.currentProgramme.config.prepost) {
      // check for org specific

      let preposts = this.currentProgramme.config.prepost;
      // is there an org specific one?
      if (this.currentProgramme.config.prepostOrg) {
        const prepostOrg = JSON.parse(this.currentProgramme.config.prepostOrg);
        // console.log(prepostOrg);
        // console.log(this.currentUser.client_id);
        // get the current user...
        preposts = [];
        // check for org specific..
        if (prepostOrg[this.currentUser.client_id]) {
          for (const assessmentId of Object.keys(
            prepostOrg[this.currentUser.client_id]
          )) {
            for (const prepost of Object.keys(
              prepostOrg[this.currentUser.client_id][assessmentId]
            )) {
              if (
                prepostOrg[this.currentUser.client_id][assessmentId].pre == true
              ) {
                preposts.push(assessmentId);
              }
            }
          }
        }
      }
      if(preposts.length == 0){
        this.router.navigate(['/dashboard']);
      }
      else{
      // check results
      this.assessmentsService
        .fetchResults(preposts[0], 'pre')
        .subscribe((checkResults) => {
          this.isFetching = false;
          if (checkResults.length === 0) {
            this.router.navigate(['/assessments', 'take', preposts[0], 'pre']);
          } else {
            this.router.navigate(['/dashboard']);
          }
        });
      }
    } else {
      this.isFetching = false;
      this.router.navigate(['/dashboard']);
    }
  }
}
