<div *ngIf="currentUser && currentProgramme" class="d-none d-sm-block">
  <div id="db-sidenav">

    <ng-container *ngIf="currentProgramme.config.m3 != 1">
    <!-- the side nav -->
    <div class="db-sidenav-logo">
      <span class="logo-text" *ngIf="!currentProgramme"
        ><img
          src="./assets/img/logo-my-desmond.png"
          height="60"
          alt="Programme Logo"
      /></span>
      <span class="logo-text" *ngIf="currentProgramme">
        <span *ngIf="currentProgramme.logo"
          ><img
            [src]="environment.assetUrl + '/assets/' + currentProgramme.logo"
            style="max-width:200px;max-height:60px;"
            alt="{{ currentProgramme.title }} logo"
        /></span>
        <span *ngIf="!currentProgramme.logo">{{ currentProgramme.title }}</span>
      </span>
    </div>

    <div class="db-sidenav-avatar">
      <a class="db-sidenav-avatar-link" [routerLink]="['/notifications']"
        ><img
          [src]="
            (currentUser.avatar !== null &&
              environment.assetUrl + '/assets/user/' + currentUser.avatar) ||
            './assets/img/default-avatar.png'
          "
          class="img-circle img-avatar"
          width="75"
          height="75"
          alt="User avatar"
        />
        <span *ngIf="notifications.length > 0" class="notification">{{
          notifications.length
        }}</span>
      </a>
      <div
        class="db-sidenav-avatar-name"
        *ngIf="!currentUser.buddy && currentUser.first_name"
      >
        {{ currentUser.first_name + " " + currentUser.last_name }}
      </div>
      <div
        class="db-sidenav-avatar-name"
        *ngIf="currentUser.buddy || !currentUser.first_name"
      >
        {{ currentUser.screen_name }}
      </div>
    </div>
    <hr />
    </ng-container>

    <ng-container *ngIf="currentProgramme.config.m3 == 1">
      <div class="db-sidenav-logo">
        <span class="logo-text"
          ><img
            src="./assets/img/m3/logo-mha.svg"
            height="80"
            alt="Programme Logo"
        /></span>
      </div>
    </ng-container>

    <nav class="side-menu" id="accordion" role="tablist">

    <ng-container *ngIf="currentProgramme.config.m3 != 1">
      <div class="side-menu-item">
        <form
          class="bs-form tracker-form"
          name="search"
          method="post"
          (ngSubmit)="onSubmitSearch()"
          #searchForm="ngForm"
        >
          <div class="search-group">
            <button
              type="submit"
              class="btn btn-primary btn-search-form"
              aria-label="Submit search"
            >
              <span class="icon-wrap"
                ><fa-icon
                  [icon]="['fas', 'search']"
                  [fixedWidth]="true"
                ></fa-icon
              ></span>
            </button>
            <input
              type="text"
              class="form-control form-control-search"
              placeholder="Search...."
              ngModel
              #searchQuery="ngModel"
              aria-label="Search Query"
              name="query"
            />
          </div>
        </form>
      </div>

      <hr />
      </ng-container>
      <ng-container *ngIf="currentProgramme.config.m3!=1">
      <div class="side-menu-item">
        <a
          [routerLink]="['/dashboard']"
          class="no-sub collapsed"
          data-toggle="collapse"
          data-parent="#accordion"
          href="#"
          aria-expanded="false"
          role="tab"
          id="headingOne"
        >
          <span class="icon-wrap item-icon"
            ><fa-icon [icon]="['fas', 'home']" [fixedWidth]="true"></fa-icon
          ></span>
          <span>HOME</span>
        </a>
      </div>

      <div
        class="side-menu-item"
        *ngIf="!currentUser.buddy && programmeContent"
      >
        <a
          [ngClass]="{ collapsed: !menuToggle.learning }"
          (click)="onToggleMenu($event, 'learning')"
          data-toggle="collapse"
          data-parent="#accordion"
          aria-expanded="false"
          aria-controls="collapseThree"
          role="tab"
          id="headingThree"
          href="#"
        >
          <span class="icon-wrap item-icon nav-learning"
            ><fa-icon
              [icon]="['fas', 'graduation-cap']"
              [fixedWidth]="true"
            ></fa-icon
          ></span>
          <span class="nav-main-label">LEARNING SESSIONS</span>
          <span class="icon-wrap toggle-chevron" *ngIf="!menuToggle.learning"><fa-icon [icon]="['fas', 'chevron-circle-down']"></fa-icon></span>
          <span class="icon-wrap toggle-chevron" *ngIf="menuToggle.learning"><fa-icon [icon]="['fas', 'chevron-circle-up']"></fa-icon></span>
        </a>
        <ul
          *ngIf="menuToggle.learning"
          id="collapseThree"
          class="side-menu-sub-menu booster-session-nav-menu"
          role="tabpanel"
          aria-labelledby="headingThree"
        >
          <ng-container
            *ngFor="
              let boosterSession of getLearningSessions(programmeContent.booster_sessions);
              index as index
            "
          >
            <li routerLinkActive="active" [routerLinkActiveOptions]="{exact:
true}"


tooltip="This session is not available yet"
[isDisabled]="boosterSession.release_date!=='EPL' || stepTarget"
placement="right"
>
              <a
                [ngClass]="
                  { completed: boosterSession.status == 'completed' && stepTarget, 'disabled':(boosterSession.release_date=='EPL' && !stepTarget) }
                "
                [routerLink]="[
                  '/learning-sessions',
                  'index',
                  boosterSession.booster_session_id
                ]"
               
              >
                <span
                  class="icon-wrap"
                  *ngIf="boosterSession.status == 'completed'"
                  ><fa-icon
                    [icon]="['fas', 'check-circle']"
                    [fixedWidth]="true"
                  ></fa-icon
                ></span>
                <span
                  class="icon-wrap"
                  *ngIf="((boosterSession.status == 'available'  || boosterSession.status == 'started'   || boosterSession.status == 'unavailable') && boosterSession.release_date!='EPL') || (boosterSession.release_date=='EPL'  && stepTarget && boosterSession.status != 'completed')"
                  ><fa-icon
                    [icon]="['far', 'arrow-circle-right']"
                    [fixedWidth]="true"
                  ></fa-icon
                ></span>
                <span
                  class="icon-wrap"
                  *ngIf="boosterSession.release_date=='EPL'  && !stepTarget"
                  ><fa-icon
                    [icon]="['fas', 'circle']"
                    [fixedWidth]="true"
                  ></fa-icon
                ></span><span class="nav-label">{{ boosterSession.booster_session_title }}</span></a
              >
            </li>
          </ng-container>

          <ng-container
            *ngFor="let boosterSession of programmeContent.extra_sessions"
          >
            <li
              *ngIf="
                userSessions.indexOf(boosterSession.booster_session_id) != -1
              "
            >
              <a
              
                [ngClass]="{
                  disabled: boosterSession.status === 'unavailable',
                  completed: boosterSession.status === 'completed'
                }"
               
                [routerLink]="[
                  '/learning-sessions',
                  'index',
                  boosterSession.booster_session_id
                ]"
                ><span
                class="icon-wrap"
                *ngIf="boosterSession.status == 'completed'"
                ><fa-icon
                  [icon]="['fas', 'check-circle']"
                  [fixedWidth]="true"
                ></fa-icon
              ></span>
              <span
                class="icon-wrap"
                *ngIf="boosterSession.status == 'available'  || boosterSession.status == 'started'   || boosterSession.status == 'unavailable'"
                ><fa-icon
                  [icon]="['far', 'arrow-circle-right']"
                  [fixedWidth]="true"
                ></fa-icon
              ></span><span class="nav-label">{{ boosterSession.title }}</span></a
              >
            </li>
          </ng-container>

          <!--li
            *ngIf="
              programmeContent.extra_sessions.length > 0 &&
              checkSessionsAvailable(programmeContent.extra_sessions, userSessions)
              
            "
          -->
          <li
            *ngIf="
              programmeContent.extra_sessions.length > 0
              
            "
          >
            <a [routerLink]="['/extra-sessions']"
              ><span class="icon-wrap"
                ><fa-icon
                  [icon]="['fas', 'plus-circle']"
                  [fixedWidth]="true"
                ></fa-icon
              ></span><span class="nav-label">Add Sessions</span></a
            >
          </li>
        </ul>
      </div>

      <div
        class="side-menu-item"
        *ngIf="!currentUser.buddy && programmeContent"
      >
        <a
          [ngClass]="{ collapsed: !menuToggle.booster }"
          (click)="onToggleMenu($event, 'booster')"
          data-toggle="collapse"
          data-parent="#accordion"
          aria-expanded="false"
          aria-controls="collapseThreeB"
          role="tab"
          id="headingThreeB"
          href="#"
        >
          <span class="icon-wrap item-icon nav-booster"
            ><fa-icon [icon]="['fas', 'bolt']" [fixedWidth]="true"></fa-icon
          ></span>
          <span class="nav-main-label">BOOSTER SESSIONS</span>

          <span class="icon-wrap toggle-chevron" *ngIf="!menuToggle.booster"><fa-icon [icon]="['fas', 'chevron-circle-down']"></fa-icon></span>
          <span class="icon-wrap toggle-chevron" *ngIf="menuToggle.booster"><fa-icon [icon]="['fas', 'chevron-circle-up']"></fa-icon></span>
        </a>
        <ul
          *ngIf="menuToggle.booster"
          id="collapseThreeB"
          class="side-menu-sub-menu booster-session-nav-menu"
          role="tabpanel"
          aria-labelledby="headingThreeB"
        >
          <li
            routerLinkActive="active" [routerLinkActiveOptions]="{exact:
true}"
            *ngFor="
              let boosterSession of getBoosterSessions(programmeContent.booster_sessions);
              index as index
            "
            tooltip="This booster session is not available yet"
            [isDisabled]="boosterSession.status !== 'unavailable'"
            placement="right"
          >
            <a
              [ngClass]="
                { unavailable: 'disabled', completed: 'completed' }[
                  boosterSession.status
                ]
              "
              [ngClass]="{
                disabled: boosterSession.status == 'unavailable'
              }"
              ng-click="navPick($event);goTo('boosterSessionsIndex', {'boosterSessionId' :  boosterSession.booster_session_id }, boosterSession)"
              [routerLink]="[
                '/booster-sessions',
                'index',
                boosterSession.booster_session_id
              ]"
              ng-disabled="boosterSession.status=='unavailable'"
            >
              <span
                class="icon-wrap"
                *ngIf="boosterSession.status == 'completed'"
                ><fa-icon
                  [icon]="['fas', 'check-circle']"
                  [fixedWidth]="true"
                ></fa-icon
              ></span>
              <span
                class="icon-wrap"
                *ngIf="boosterSession.status == 'available' || boosterSession.status == 'started'"
                ><fa-icon
                  [icon]="['far', 'arrow-circle-right']"
                  [fixedWidth]="true"
                ></fa-icon
              ></span>
              <span
                class="icon-wrap"
                *ngIf="boosterSession.status == 'unavailable'"
                ><fa-icon
                  [icon]="['fas', 'circle']"
                  [fixedWidth]="true"
                ></fa-icon
              ></span><span class="nav-label">{{ boosterSession.booster_session_title }}</span></a
            >
          </li>
          <ng-container
            *ngFor="
              let boosterSession of programmeContent.adhoc_booster_sessions
            "
          >
            <li
              routerLinkActive="active" [routerLinkActiveOptions]="{exact:
true}"
              *ngIf="
                boosterSession.release_date <= sToday &&
                boosterSession.close_date >= sToday
              "
            >
              <a
                ng-click="navPick($event);goTo('boosterSessionsIndex', {'boosterSessionId' :  boosterSession.booster_session_id }, boosterSession)"
                [routerLink]="[
                  '/booster-sessions',
                  'index',
                  boosterSession.booster_session_id
                ]"
              >
                <span
                  class="icon-wrap"
                  *ngIf="boosterSession.status == 'completed'"
                  ><fa-icon
                    [icon]="['fas', 'check-circle']"
                    [fixedWidth]="true"
                  ></fa-icon
                ></span>
                <span
                  class="icon-wrap"
                  *ngIf="boosterSession.status !== 'completed'"
                  ><fa-icon
                    [icon]="['far', 'arrow-circle-right']"
                    [fixedWidth]="true"
                  ></fa-icon
                ></span>
                {{ boosterSession.booster_session_title }}</a
              >
            </li>
          </ng-container>
        </ul>
      </div>

      <div class="side-menu-item">
        <a
          [ngClass]="{ collapsed: !menuToggle.steps }"
          (click)="onToggleMenu($event, 'steps')"
          data-toggle="collapse"
          data-parent="#accordion"
          aria-expanded="false"
          aria-controls="collapseTwo"
          role="tab"
          id="headingTwo"
          href="#"
        >
          <span class="icon-wrap item-icon nav-activity"
            ><fa-icon
              [icon]="['fas', 'walking']"
              [fixedWidth]="true"
            ></fa-icon
          ></span>
          <span class="nav-main-label">ACTIVITY</span>
          <span class="icon-wrap toggle-chevron" *ngIf="!menuToggle.steps"><fa-icon [icon]="['fas', 'chevron-circle-down']"></fa-icon></span>
          <span class="icon-wrap toggle-chevron" *ngIf="menuToggle.steps"><fa-icon [icon]="['fas', 'chevron-circle-up']"></fa-icon></span>
        </a>
        <ul
          *ngIf="menuToggle.steps"
          id="collapseTwo"
          class="side-menu-sub-menu"
          role="tabpanel"
          aria-labelledby="headingTwo"
        >
          <li routerLinkActive="active" [routerLinkActiveOptions]="{exact:
true}" *ngIf="currentUser.mobility3!='0' && currentUser.mobility1!='f'">
            <a [routerLink]="['/steps']"
              ><span class="icon-wrap"
                ><fa-icon [icon]="['fas', 'shoe-prints']" [fixedWidth]="true"></fa-icon
              ></span><span class="nav-label">My Steps</span></a
            >
          </li>
          <li
            routerLinkActive="active" [routerLinkActiveOptions]="{exact:
true}"
            *ngIf="!currentUser.buddy && currentProgramme.config.teamBased == 1 && currentUser.mobility3!='0'"
          >
            <a [routerLink]="['/challenges']"
              ><span class="icon-wrap"
                ><fa-icon
                  [icon]="['fas', 'trophy']"
                  [fixedWidth]="true"
                ></fa-icon
              ></span><span class="nav-label">Challenges</span></a
            >
          </li>
          <li
            routerLinkActive="active" [routerLinkActiveOptions]="{exact:
true}"
            *ngIf="!currentUser.buddy && currentProgramme.config.teamBased != 1 && currentUser.mobility3!='0' && currentUser.mobility1!='f'"
          >
            <a [routerLink]="['/challenges-individual']"
              ><span class="icon-wrap"
                ><fa-icon
                  [icon]="['fas', 'trophy']"
                  [fixedWidth]="true"
                ></fa-icon
              ></span><span class="nav-label">Challenges</span></a
            >
          </li>
          <li routerLinkActive="active" [routerLinkActiveOptions]="{exact:
true}" *ngIf="currentUser.buddy">
            <a [routerLink]="['/friends-and-family']"
              ><span class="icon-wrap"
                ><fa-icon
                  [icon]="['fas', 'trophy']"
                  [fixedWidth]="true"
                ></fa-icon
              ></span><span class="nav-label">Challenges</span></a
            >
          </li>

          <li routerLinkActive="active" *ngIf="currentProgramme.config.trackers.indexOf('strength') > -1" [routerLinkActiveOptions]="{exact:
            true}">
                        <a [routerLink]="['/activity', 'strength']"
                          ><span class="icon-wrap"
                            ><fa-icon [icon]="['far', 'dumbbell']" [fixedWidth]="true"></fa-icon
                          ></span><span class="nav-label">Strength</span></a
                        >
                      </li>
                      
          <li
            routerLinkActive="active" [routerLinkActiveOptions]="{exact:
true}"
            *ngIf="currentProgramme.config.buddies == 1 && !currentUser.buddy"
          >
            <a [routerLink]="['/friends-and-family']"
              ><span class="icon-wrap"
                ><fa-icon
                  [icon]="['fas', 'user-friends']"
                  [fixedWidth]="true"
                ></fa-icon
              ></span><span class="nav-label">Buddies</span></a
            >
          </li>
          <li routerLinkActive="active" [routerLinkActiveOptions]="{exact:
true}" *ngIf="currentUser.mobility3!='0' && currentUser.mobility1!='f'">
            <a [routerLink]="['/target']"
              ><span class="icon-wrap"
                ><fa-icon
                  [icon]="['fas', 'bullseye']"
                  [fixedWidth]="true"
                ></fa-icon
              ></span><span class="nav-label" *ngIf="currentProgramme.config.exerciseProgramme!=1">Set Step Target</span> <span class="nav-label" *ngIf="currentProgramme.config.exerciseProgramme==1">My Step Target</span></a
            >
          </li>
          <li routerLinkActive="active" [routerLinkActiveOptions]="{exact:
true}"
*ngIf="currentProgramme.config.trackers.indexOf('sitting') > -1"
>
            <a [routerLink]="['/activity', 'sitting']"
              ><span class="icon-wrap"
                ><fa-icon
                  [icon]="['far', 'couch']"
                  [fixedWidth]="true"
                ></fa-icon
              ></span><span class="nav-label"><span *ngIf="!(currentUser.mobility1 == 'e' || currentUser.mobility1 == 'f')">My Sitting</span><span *ngIf="currentUser.mobility1 == 'e' || currentUser.mobility1 == 'f'">My sedentary behaviour</span></span></a
            >
          </li>
          <li
            routerLinkActive="active" [routerLinkActiveOptions]="{exact:
true}"
            *ngIf="currentProgramme.config.trackers.indexOf('minutes') > -1"
          >
            <a [routerLink]="['/activity', 'minutes']"
              ><span class="icon-wrap"
                ><fa-icon
                  [icon]="['far', 'stopwatch']"
                  [fixedWidth]="true"
                ></fa-icon
              ></span><span class="nav-label">My Active Minutes</span></a
            >
          </li>
          <li
            routerLinkActive="active" [routerLinkActiveOptions]="{exact:
true}"
            *ngIf="currentProgramme.config.trackers.indexOf('workouts') > -1"
          >
            <a [routerLink]="['/activity', 'workouts']"
              ><span class="icon-wrap"
                ><fa-icon
                  [icon]="['fas', 'running']"
                  [fixedWidth]="true"
                ></fa-icon
              ></span><span class="nav-label">My Workouts</span></a
            >
          </li>
          <li
            routerLinkActive="active" [routerLinkActiveOptions]="{exact:
true}"
            *ngIf="currentProgramme.config.trackers.indexOf('sleep') > -1"
          >
            <a [routerLink]="['/activity', 'sleep']"
              ><span class="icon-wrap"
                ><fa-icon
                  [icon]="['far', 'bed']"
                  [fixedWidth]="true"
                ></fa-icon
              ></span><span class="nav-label">My Sleep</span></a
            >
          </li>
          <li routerLinkActive="active" [routerLinkActiveOptions]="{exact:
true}">
            <a [routerLink]="['/achievements']"
              ><span class="icon-wrap"
                ><fa-icon
                  [icon]="['fas', 'medal']"
                  [fixedWidth]="true"
                ></fa-icon
              ></span><span class="nav-label">Achievements</span></a
            >
          </li>
          <li routerLinkActive="active" [routerLinkActiveOptions]="{exact:
true}">
            <a [routerLink]="['/setup-device']"
              ><span class="icon-wrap"
                ><fa-icon
                  [icon]="['fas', 'watch-fitness']"
                  [fixedWidth]="true"
                ></fa-icon
              ></span><span class="nav-label">Setup Device</span></a
            >
          </li>
        </ul>
      </div>

      <div class="side-menu-item" *ngIf="!currentUser.buddy">
        <a
          [ngClass]="{ collapsed: !menuToggle.chat }"
          (click)="onToggleMenu($event, 'chat')"
          data-toggle="collapse"
          data-parent="#accordion"
          aria-expanded="false"
          aria-controls="collapseFour"
          role="tab"
          id="headingFour"
          href="#"
        >
          <span class="icon-wrap item-icon nav-chat"
            ><fa-icon [icon]="['fas', 'comments']" [fixedWidth]="true"></fa-icon
          ></span>
          <span class="nav-main-label">CHAT</span>
          <span class="icon-wrap toggle-chevron" *ngIf="!menuToggle.chat"><fa-icon [icon]="['fas', 'chevron-circle-down']"></fa-icon></span>
          <span class="icon-wrap toggle-chevron" *ngIf="menuToggle.chat"><fa-icon [icon]="['fas', 'chevron-circle-up']"></fa-icon></span>
        </a>
        <ul
          *ngIf="menuToggle.chat"
          id="collapseFour"
          class="side-menu-sub-menu"
          role="tabpanel"
          aria-labelledby="headingFour"
        >
          <li
            routerLinkActive="active" [routerLinkActiveOptions]="{exact:
true}"
            *ngIf="currentProgramme.config.askTheExpert == 1"
          >
            <a [routerLink]="['/chat', 'ask-the-expert']"
              ><span class="icon-wrap"
                ><fa-icon
                  [icon]="['fas', 'user-md-chat']"
                  [fixedWidth]="true"
                ></fa-icon
              ></span><span class="nav-label">{{ environment.navLabels["askTheExpert"] }}</span></a
            >
          </li>

          <li routerLinkActive="active" [routerLinkActiveOptions]="{exact:
true}">
            <a [routerLink]="['/chat']"
              ><span class="icon-wrap"
                ><fa-icon
                  [icon]="['fas', 'users']"
                  [fixedWidth]="true"
                ></fa-icon
              ></span><span class="nav-label">{{ environment.navLabels["chat"] }}</span></a
            >
          </li>
          <li  routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
            <a [routerLink]="['/chat', 'technical-support']"
              ><span class="icon-wrap"
                ><fa-icon
                  [icon]="['fas', 'user-headset']"
                  [fixedWidth]="true"
                ></fa-icon
              ></span><span class="nav-label">Technical Support</span></a
            >
          </li>
          <!--li routerLinkActive="active" [routerLinkActiveOptions]="{exact:
true}" *ngIf="currentProgramme.config.teamBased == 1"><a  ng-click="navPick($event)" ui-sref="chatTeam"><span class="icon-wrap"><fa-icon [icon]="['fas', 'comment']" [fixedWidth]="true"></fa-icon></span> Chat with my team only</a></li-->
        </ul>
      </div>

      <div
        class="side-menu-item"
        *ngIf="
          currentProgramme.config.trackers.length > 0 && !currentUser.buddy
        "
      >
        <a
          [ngClass]="{ collapsed: !menuToggle.trackers }"
          (click)="onToggleMenu($event, 'trackers')"
          class=""
          data-toggle="collapse"
          data-parent="#accordion"
          aria-expanded="false"
          aria-controls="collapseSix"
          role="tab"
          id="headingFive"
          href="#"
        >
          <span class="icon-wrap item-icon nav-trackers"
            ><fa-icon
              [icon]="['fas', 'chart-line']"
              [fixedWidth]="true"
            ></fa-icon
          ></span>
          <span class="nav-main-label">HEALTH TRACKERS</span>
          <span class="icon-wrap toggle-chevron" *ngIf="!menuToggle.trackers"><fa-icon [icon]="['fas', 'chevron-circle-down']"></fa-icon></span>
          <span class="icon-wrap toggle-chevron" *ngIf="menuToggle.trackers"><fa-icon [icon]="['fas', 'chevron-circle-up']"></fa-icon></span>
        </a>
        <ul
          *ngIf="menuToggle.trackers"
          id="collapseSix"
          class="side-menu-sub-menu"
          role="tabpanel"
          aria-labelledby="headingFive"
        >
          <li
            routerLinkActive="active" [routerLinkActiveOptions]="{exact:
true}"
            *ngIf="
              currentProgramme.config.trackers.indexOf('shape') > -1 &&
              currentUser
            "
          >
            <a
              ng-click="navPick($event)"
              [routerLink]="['/health-trackers', 'shape']"
              
            >
              <span
                class="icon-wrap"
                *ngIf="
                  currentUser.gender == 'f' ||
                  currentUser.gender == 'x' ||
                  !currentUser.gender
                "
                ><fa-icon
                  [icon]="['fas', 'female']"
                  [fixedWidth]="true"
                ></fa-icon
              ></span>
              <span class="icon-wrap" *ngIf="currentUser.gender == 'm'"
                ><fa-icon [icon]="['fas', 'male']" [fixedWidth]="true"></fa-icon
              ></span><span class="nav-label">Shape</span></a
            >
          </li>
          <li
            routerLinkActive="active" [routerLinkActiveOptions]="{exact:
true}"
            *ngIf="
              currentProgramme.config.trackers.indexOf('healthyEating') > -1
            "
          >
            <a [routerLink]="['/health-trackers', 'healthy-eating']"
              ><span class="icon-wrap"
                ><fa-icon
                  [icon]="['fas', 'utensils']"
                  [fixedWidth]="true"
                ></fa-icon
              ></span><span class="nav-label">Healthy Eating</span></a
            >
          </li>
          <li
            routerLinkActive="active" [routerLinkActiveOptions]="{exact:
true}"
            *ngIf="currentProgramme.config.trackers.indexOf('hba1c') > -1"
          >
            <a [routerLink]="['/health-trackers', 'hba1c']"
              ><span class="icon-wrap"
                ><fa-icon [icon]="['fas', 'tint']" [fixedWidth]="true"></fa-icon
              ></span><span class="nav-label">HbA1c</span></a
            >
          </li>
          <li
            routerLinkActive="active" [routerLinkActiveOptions]="{exact:
true}"
            *ngIf="currentProgramme.config.trackers.indexOf('bp') > -1"
          >
            <a [routerLink]="['/health-trackers', 'blood-pressure']"
              ><span class="icon-wrap"
                ><fa-icon
                  [icon]="['fas', 'tachometer-alt']"
                  [fixedWidth]="true"
                ></fa-icon
              ></span><span class="nav-label">Blood Pressure</span></a
            >
          </li>
          <li
            routerLinkActive="active" [routerLinkActiveOptions]="{exact:
true}"
            *ngIf="currentProgramme.config.trackers.indexOf('cholesterol') > -1"
          >
            <a
              ng-click="navPick($event)"
              [routerLink]="['/health-trackers', 'cholesterol']"
              ><span class="icon-wrap"
                ><fa-icon
                  [icon]="['fas', 'heartbeat']"
                  [fixedWidth]="true"
                ></fa-icon
              ></span><span class="nav-label">Cholesterol</span></a
            >
          </li>
          <li
            routerLinkActive="active" [routerLinkActiveOptions]="{exact:
true}"
            *ngIf="currentProgramme.config.trackers.indexOf('smoking') > -1"
          >
            <a [routerLink]="['/health-trackers', 'smoking']"
              ><span class="icon-wrap"
                ><fa-icon [icon]="['fas', 'smoking-ban']" [fixedWidth]="true"></fa-icon
              ></span><span class="nav-label">Smoking</span></a
            >
          </li>
        </ul>
      </div>


      <!-- symptom tracker -->
      <div
      class="side-menu-item"
      *ngIf="
      currentProgramme.config.trackers.indexOf('symptoms') > -1
      "
    >
      <a
        [ngClass]="{ collapsed: !menuToggle.symptoms }"
        (click)="onToggleMenu($event, 'symptoms')"
        class=""
        data-toggle="collapse"
        data-parent="#accordion"
        aria-expanded="false"
        aria-controls="collapseTen"
        role="tab"
        id="headingTen"
        href="#"
      >
        <span class="icon-wrap item-icon nav-symptoms"
          ><fa-icon
            [icon]="['fas', 'kidneys']"
            [fixedWidth]="true"
          ></fa-icon
        ></span>
        <span class="nav-main-label">SYMPTOM TRACKERS</span>
        <span class="icon-wrap toggle-chevron" *ngIf="!menuToggle.symptoms"><fa-icon [icon]="['fas', 'chevron-circle-down']"></fa-icon></span>
        <span class="icon-wrap toggle-chevron" *ngIf="menuToggle.symptoms"><fa-icon [icon]="['fas', 'chevron-circle-up']"></fa-icon></span>
      </a>
      <ul
        *ngIf="menuToggle.symptoms"
        id="collapseSix"
        class="side-menu-sub-menu"
        role="tabpanel"
        aria-labelledby="headingFive"
      >
        <li
          routerLinkActive="active" [routerLinkActiveOptions]="{exact:
true}"
          
*ngFor="let symptom of symptoms"
        >
          <a
            [routerLink]="['/symptom-trackers', symptom.slug]"
            
          >
            <span
              class="icon-wrap"
             
              ><fa-icon
                [icon]="[symptom.iconWeight, symptom.icon]"
                [fixedWidth]="true"
              ></fa-icon
            ></span>
            <span class="nav-label">{{symptom.name | titlecase}}</span></a
          >
        </li>
      </ul>
    </div>
      <!-- end symptom tracker -->
      <div class="side-menu-item" *ngIf="!currentUser.buddy">
        <a
          [routerLink]="['/decision-maker-saved']"
          class="no-sub collapsed"
          data-toggle="collapse"
          data-parent="#accordion"
          href="#collapseSix"
          aria-expanded="false"
          aria-controls="collapseSix"
          role="tab"
          id="headingSix"
          href="#"
        >
          <span class="icon-wrap item-icon nav-decision"
            ><fa-icon
              [icon]="['fas', 'map-signs']"
              [fixedWidth]="true"
            ></fa-icon
          ></span>
          <span class="nav-main-label">DECISION MAKER</span>
        </a>
      </div>

      <div class="side-menu-item" *ngIf="programmeContent">
        <a
          [ngClass]="{ collapsed: !menuToggle.education }"
          (click)="onToggleMenu($event, 'education')"
          data-toggle="collapse"
          data-parent="#accordion"
          aria-expanded="false"
          aria-controls="collapseSeven"
          role="tab"
          id="headingSeven"
          href="#"
        >
          <span class="icon-wrap item-icon nav-useful"
            ><fa-icon [icon]="['fas', 'book']" [fixedWidth]="true"></fa-icon
          ></span>
          <span class="nav-main-label">USEFUL INFORMATION</span>
          <span class="icon-wrap toggle-chevron" *ngIf="!menuToggle.education"><fa-icon [icon]="['fas', 'chevron-circle-down']"></fa-icon></span>
          <span class="icon-wrap toggle-chevron" *ngIf="menuToggle.education"><fa-icon [icon]="['fas', 'chevron-circle-up']"></fa-icon></span>
        </a>
        <ul
          *ngIf="menuToggle.education"
          id="collapseSeven"
          class="side-menu-sub-menu"
          role="tabpanel"
          aria-labelledby="headingSeven"
        >
          <li
            *ngFor="
              let contentSection of programmeContent.content_sections;
              let index = index
            "
            routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"
          >
            <a
              [routerLink]="[
                '/educational-reading',
                'section',
                contentSection.content_section_id
              ]"
            >
              <span class="icon-wrap"
                ><fa-icon
                  [icon]="['far', 'arrow-circle-right']"
                  [fixedWidth]="true"
                ></fa-icon
              ></span><span class="nav-label">{{ contentSection.content_section_title }}</span></a
            >
          </li>
        </ul>
      </div>
    </ng-container>
    <ng-container *ngIf="currentProgramme.config.m3 == 1">
      
      <div class="side-menu-item">
        <a
          [routerLink]="['/m3/dashboard']"
          class="no-sub collapsed"
        >
          <span class="icon-wrap"
            ><app-m3-icon [icon]="'overview'" [style]="'thin'"></app-m3-icon></span>
          <span>Overview</span>
        </a>
      </div>

      <div class="side-menu-item">
        <a
          [routerLink]="['/m3/walking']"
          class="no-sub collapsed"
        >
          <span class="icon-wrap"
            ><app-m3-icon [icon]="'walking'" [style]="'thin'"></app-m3-icon></span>
            
          <span>Daily Stepping</span>
        </a>
      </div>
      <div class="side-menu-item">
        <a
          [routerLink]="['/m3/fitness']"
          class="no-sub collapsed"
        >
          <span class="icon-wrap"
            ><app-m3-icon [icon]="'fitness'" [style]="'thin'"></app-m3-icon></span>
          <span>Fitness</span>
        </a>
      </div>


      <div class="side-menu-item">
        <a
          [routerLink]="['/m3/non-stepping']"
          class="no-sub collapsed"
        >
          <span class="icon-wrap"
            ><app-m3-icon [icon]="'non-stepping'" [style]="'thin'"></app-m3-icon></span>
          <span>Non Stepping Activity</span>
        </a>
      </div>

      <div class="side-menu-item">
        <a
          [routerLink]="['/m3/physical-ability']"
          class="no-sub collapsed"
        >
          <span class="icon-wrap"
            ><app-m3-icon [icon]="'physical-ability'" [style]="'thin'"></app-m3-icon></span>
          <span>Physical function &amp; strength</span>
        </a>
      </div>

      <div class="side-menu-item">
        <a
          [routerLink]="['/m3/sitting']"
          class="no-sub collapsed"
        >
          <span class="icon-wrap"
            ><app-m3-icon [icon]="'sitting'" [style]="'thin'"></app-m3-icon></span>
          <span>Sitting</span>
        </a>
      </div>

      <div class="side-menu-item">
        <a
          [routerLink]="['/m3/sleep']"
          class="no-sub collapsed"
        >
          <span class="icon-wrap"
            ><app-m3-icon [icon]="'sleep'" [style]="'thin'"></app-m3-icon></span>
          <span>Sleep</span>
        </a>
      </div>

      <div class="side-menu-item">
        <a
          [routerLink]="['/m3/health-data']"
          class="no-sub collapsed"
        >
          <span class="icon-wrap"
            ><app-m3-icon [icon]="'health-data'" [style]="'thin'"></app-m3-icon></span>
          <span>Health Data</span>
        </a>
      </div>


    </ng-container>
    </nav>

    <hr />
    <div class="side-menu-item" *ngIf="programmeContent">
      <a
        [ngClass]="{ collapsed: !menuToggle.help }"
        (click)="onToggleMenu($event, 'help')"
        data-toggle="collapse"
        data-parent="#accordion"
        aria-expanded="false"
        aria-controls="collapseSeven"
        role="tab"
        id="headingSeven"
        href="#"
      >
        <span class="icon-wrap nav-help"
        [ngClass]="{'item-icon': currentProgramme.config.m3!=1}"
          >
          <fa-icon [icon]="['fas', 'question']" [fixedWidth]="true" *ngIf="currentProgramme.config.m3!=1"></fa-icon>
          <fa-icon [icon]="['fal', 'question']" [fixedWidth]="true" *ngIf="currentProgramme.config.m3==1"></fa-icon>
      
      </span>
        <span class="nav-main-label">HELP</span>
        <span class="icon-wrap toggle-chevron" *ngIf="!menuToggle.help">
          <fa-icon [icon]="['fas', 'chevron-circle-down']" *ngIf="currentProgramme.config.m3!=1"></fa-icon>
          <fa-icon [icon]="['fal', 'chevron-circle-down']" *ngIf="currentProgramme.config.m3==1"></fa-icon>
        </span>
        <span class="icon-wrap toggle-chevron" *ngIf="menuToggle.help">
          <fa-icon [icon]="['fas', 'chevron-circle-up']" *ngIf="currentProgramme.config.m3!=1"></fa-icon>
          <fa-icon [icon]="['fal', 'chevron-circle-up']" *ngIf="currentProgramme.config.m3==1"></fa-icon>
        </span>
      </a>
      <ul
        *ngIf="menuToggle.help && programmeContent.help_sections"
        id="collapseSeven"
        class="side-menu-sub-menu"
        role="tabpanel"
        aria-labelledby="headingSeven"
      >
        <li
          routerLinkActive="active" [routerLinkActiveOptions]="{exact:
true}"
          *ngFor="
            let contentSection of programmeContent.help_sections;
            let index = index
          "
        >
          <a
            [routerLink]="[
              '/help',
              'section',
              contentSection.content_section_id
            ]"
          >
            <span class="icon-wrap" *ngIf="currentProgramme.config.m3!=1"
              ><fa-icon
                [icon]="['far', 'arrow-circle-right']"
                [fixedWidth]="true"
              ></fa-icon
            ></span><span class="nav-label">{{ contentSection.content_section_title }}</span></a
          >
        </li>
      </ul>
    </div>
    <div class="side-menu-item" *ngIf="currentProgrammes">
      <ng-container *ngIf="currentProgrammes.length > 1">
        <a
          [ngClass]="{ collapsed: !menuToggle.programmes }"
          (click)="onToggleMenu($event, 'programmes')"
          data-toggle="collapse"
          data-parent="#accordion"
          aria-expanded="true"
          aria-controls="collapseTwo"
          role="tab"
          id="headingOne"
          href="#"
        >
          <span class="icon-wrap"
          [ngClass]="{'item-icon': currentProgramme.config.m3!=1}"
            ><fa-icon
            *ngIf="currentProgramme.config.m3!=1"
              [icon]="['fas', 'graduation-cap']"
              [fixedWidth]="true"
            ></fa-icon>
            <fa-icon
            *ngIf="currentProgramme.config.m3==1"
              [icon]="['fal', 'graduation-cap']"
              [fixedWidth]="true"
            ></fa-icon>
          </span>
          <span class="nav-main-label">MY PROGRAMMES</span>
          <span class="icon-wrap toggle-chevron" *ngIf="!menuToggle.programmes">
            
          <fa-icon [icon]="['fas', 'chevron-circle-down']" *ngIf="currentProgramme.config.m3!=1"></fa-icon>
          <fa-icon [icon]="['fal', 'chevron-circle-down']" *ngIf="currentProgramme.config.m3==1"></fa-icon>
          </span>
          <span class="icon-wrap toggle-chevron" *ngIf="menuToggle.programmes">

          <fa-icon [icon]="['fas', 'chevron-circle-up']" *ngIf="currentProgramme.config.m3!=1"></fa-icon>
          <fa-icon [icon]="['fal', 'chevron-circle-up']" *ngIf="currentProgramme.config.m3==1"></fa-icon>
          </span>
        </a>
        <ul
          *ngIf="menuToggle.programmes"
          id="collapseTwo"
          class="side-menu-sub-menu"
          role="tabpanel"
          aria-labelledby="headingOne"
        >
          <li *ngFor="let programme of currentProgrammes">
            <a (click)="onSetCurrentProgramme(programme)">
              <span class="icon-wrap" *ngIf="currentProgramme.config.m3!=1"
                ><fa-icon
                  [icon]="['fas', 'arrow-right']"
                  [fixedWidth]="true"
                ></fa-icon
              ></span><span class="nav-label">{{ programme.title }}</span></a
            >
          </li>
        </ul>
      </ng-container>
    </div>

    <div class="side-menu-item">
      <a
        [ngClass]="{ collapsed: !menuToggle.user }"
        (click)="onToggleMenu($event, 'user')"
        data-toggle="collapse"
        data-parent="#accordion"
        aria-expanded="false"
        aria-controls="collapseTen"
        role="tab"
        id="headingFour"
        href="#"
      >
        <span class="icon-wrap nav-more"
        [ngClass]="{'item-icon': currentProgramme.config.m3!=1}"
          ><fa-icon [icon]="['fas', 'ellipsis-h']" [fixedWidth]="true" *ngIf="currentProgramme.config.m3!=1"></fa-icon
        >
        <app-m3-icon [icon]="'settings'" [style]="'thin'" *ngIf="currentProgramme.config.m3==1"></app-m3-icon>
      </span>
        <span class="nav-main-label">MORE</span>
        <span class="icon-wrap toggle-chevron" *ngIf="!menuToggle.user">
          <fa-icon [icon]="['fas', 'chevron-circle-down']" *ngIf="currentProgramme.config.m3!=1"></fa-icon>
          <fa-icon [icon]="['fal', 'chevron-circle-down']" *ngIf="currentProgramme.config.m3==1"></fa-icon>
        </span>
        <span class="icon-wrap toggle-chevron" *ngIf="menuToggle.user">
          <fa-icon [icon]="['fas', 'chevron-circle-up']" *ngIf="currentProgramme.config.m3!=1"></fa-icon>
          <fa-icon [icon]="['fal', 'chevron-circle-up']" *ngIf="currentProgramme.config.m3==1"></fa-icon>
        </span>
      </a>
      <ul
        *ngIf="menuToggle.user"
        id="collapseTen"
        class="side-menu-sub-menu"
        role="tabpanel"
        aria-labelledby="collapseTen"
      >
      <li routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}" *ngIf="currentProgramme.config.m3==1">
        <a [routerLink]="['/setup-device']"
          ><span class="nav-label">Setup Device</span></a
        >
      </li>
        <li routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}" *ngIf="currentProgramme.config.m3!=1">
          <a [routerLink]="['/notifications']"
            ><span class="icon-wrap" *ngIf="currentProgramme.config.m3!=1"
              ><fa-icon [icon]="['fas', 'bell']" [fixedWidth]="true"></fa-icon
            ></span><span class="nav-label">Notifications</span></a
          >
        </li>
        <li routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}" *ngIf="currentProgramme.config.m3!=1">
          <a [routerLink]="['/profile']"
            ><span class="icon-wrap" *ngIf="currentProgramme.config.m3!=1"
              ><fa-icon
                [icon]="['fas', 'user']"
                [fixedWidth]="true"
              ></fa-icon
            ></span><span class="nav-label">My Profile</span></a
          >
        </li>
        <li *ngIf="currentTeam" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
          <a [routerLink]="['/team']"
            ><span class="icon-wrap" *ngIf="currentProgramme.config.m3!=1"
              ><fa-icon
                [icon]="['fas', 'user-friends']"
                [fixedWidth]="true"
              ></fa-icon
            ></span><span class="nav-label">My Team</span></a
          >
        </li>
        <li routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
          <a [routerLink]="['/settings']"
            ><span class="icon-wrap" *ngIf="currentProgramme.config.m3!=1"
              ><fa-icon [icon]="['fas', 'cog']" [fixedWidth]="true"></fa-icon
            ></span><span class="nav-label">Settings</span></a
          >
        </li>
        <li routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
          <a [routerLink]="['/about']"
            ><span class="icon-wrap" *ngIf="currentProgramme.config.m3!=1"
              ><fa-icon [icon]="['fas', 'info']" [fixedWidth]="true"></fa-icon
            ></span><span class="nav-label">About</span></a
          >
        </li>
        <li routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
          <a [routerLink]="['/legal']"
            ><span class="icon-wrap" *ngIf="currentProgramme.config.m3!=1"
              ><fa-icon [icon]="['fas', 'gavel']" [fixedWidth]="true"></fa-icon
            ></span><span class="nav-label">Legal</span></a
          >
        </li>
        <li routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
          <a (click)="onLogout()"
            ><span class="icon-wrap" *ngIf="currentProgramme.config.m3!=1"
              ><fa-icon
                [icon]="['fas', 'sign-out']"
                [fixedWidth]="true"
              ></fa-icon
            ></span><span class="nav-label">Logout</span></a
          >
        </li>
      </ul>
    </div>
  </div>
  <!-- end the side nav -->
</div>