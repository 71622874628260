import { ProgrammeService } from './../../services/programme.service';
import { UserService } from './../../services/user.service';
import { AuthService } from './../../auth/auth.service';
import { environment } from './../../../environments/environment';
import { Component, OnInit, ViewChild } from '@angular/core';
import Swal from 'sweetalert2/src/sweetalert2.js';
import * as moment from 'moment';
import { NgForm, Validators } from '@angular/forms';
import { SwPush } from '@angular/service-worker';

@Component({
  selector: 'app-account-details',
  templateUrl: './account-details.component.html',
  styleUrls: ['./account-details.component.scss'],
})
export class AccountDetailsComponent implements OnInit {
  isFetching;
  currentUser;
  currentProgramme;
  environment = environment;
  stepSubmitted;
  regForm;
  regDetails;
  notification_settings;
  notifications;
  passwords = {
    old_password: null,
    password: null,
    password_confirm: null,
  };
  passwordPattern =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&#])[A-Za-z\d$@$!%*?&#]{10,}$/;

  paused = 1;

  // temp

  notifications1 = { setting: 'a', days: [], time: '' };
  notifications2 = { setting: 'a', days: [], time: '' };
  notifications3 = { setting: 'a', days: [], time: '' };

  //

  @ViewChild('profileForm', { static: false }) profileForm: NgForm;
  @ViewChild('passwordForm', { static: false }) passwordForm: NgForm;

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private programmeService: ProgrammeService,
    readonly swPush: SwPush
  ) {}

  ngOnInit(): void {
    // are we logged in?
    const user = this.authService.user.getValue();
    if (user) {
      this.userService.userData.subscribe((userDataResponse) => {
        this.currentUser = userDataResponse;
        if (this.currentUser) {
          this.notification_settings = JSON.parse(
            this.currentUser.notification_settings
          );
          this.notifications = this.currentUser.notifications;

          if (!this.notification_settings) {
            this.notification_settings = {};
          }
          // sort it
          const areas = ['login', 'challenges', 'awards', 'comments', 'stats'];
          areas.forEach((area) => {
            if (
              +this.notification_settings[area] !== 1 &&
              +this.notification_settings[area] !== 0
            ) {
              if (+this.currentUser.notifications === 1) {
                this.notification_settings[area] = 1;
              } else {
                this.notification_settings[area] = 0;
              }
            }
          });
          const epAreas = ['stepping', 'workouts', 'sitting'];
          epAreas.forEach((area) => {
            if (!this.notification_settings[area]) {
              this.notification_settings[area] = {
                setting: 'a',
                days: [],
                time: '',
              };
            }
          });

          if (this.notification_settings.enabled == undefined) {
            this.notification_settings.enabled = 1;
          }
        }
      });
    }

    this.isFetching = true;

    this.programmeService.currentProgramme.subscribe((programmeData) => {
      this.currentProgramme = programmeData;
      if (this.currentProgramme) {
      }
    });
  }

  onSubmit() {
    if (!this.profileForm.valid) {
      // const invalid = this.findInvalidControls(this.profileForm);
      Swal.fire({
        title: "We notice you've missed something",
        text: 'Please check the form for information that has been missed',
        icon: 'error',
      });
    } else {
      if (!this.notifications) {
        this.notifications = 0;
      }
      let email = null;
      if (this.environment.country === 'UK') {
        email = this.profileForm.value.email;
      }
      if (this.notification_settings.enabled != 1) {
        this.notification_settings.enabled = 0;
      }
      this.authService
        .updateAccountDetails(
          this.notifications,
          JSON.stringify(this.notification_settings),
          email
        )
        .subscribe(
          (responseData) => {
            Swal.fire({
              title: 'Account updated',
              text: 'Your data was successfully updated',
              icon: 'success',
              timer: 1000,
              showConfirmButton: false,
            });
            this.currentUser.notification_settings = JSON.stringify(
              this.notification_settings
            );
          },
          (error) => {
            Swal.fire({
              title: 'Error',
              text: error.error.detail,
              icon: 'error',
            });
          }
        );
    }
  }

  onChangePassword() {
    if (!this.passwordForm.valid) {
      Swal.fire({
        title: "We notice you've missed something",
        text: 'Please check the form for information that has been missed',
        icon: 'error',
      });
    } else {
      this.authService
        .changePassword(this.passwords.old_password, this.passwords.password)
        .subscribe(
          (responseData) => {
            //$scope.userHbA1cs = response.data._embedded.user_shapes;
            //console.log(response.data);
            Swal.fire({
              title: 'Password updated',
              text: 'Your data was successfully updated',
              icon: 'success',
              timer: 1500,
              showConfirmButton: false,
            });
          },
          (error) => {
            console.log(error);
            let msg = 'There was an error updating your password';

            msg += ' ' + error.detail;
            Swal.fire({
              title: 'Password not updated',
              text: msg,
              icon: 'error',
              showConfirmButton: true,
            });
          }
        );
    }
  }

  // push test
  requestPush() {
    this.swPush
      .requestSubscription({
        serverPublicKey: environment.vapidKeys.publicKey,
      })
      .then((sub) => {});
  }
}
