import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AvatarService {
  error = new Subject<string>();

  constructor(private http: HttpClient) {}

  upload(formData) {
    return this.http.post(
      environment.apiUrl + '/profile/update-avatar',
      formData,
      {
        reportProgress: true,
        observe: 'events',
      }
    );
  }

  resetAvatar() {
    return this.http.post(
      environment.apiUrl + '/profile/reset-avatar',
      { avatar: [] },
      {
        observe: 'response',
      }
    );
  }
}
